@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800|Roboto:300,400,500,700&display=swap);
.market-menu {
    background-color: #FFFFFF;
    color: #000000;
    width: 100%;
}

.market-menu ul {
    display: flex;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    justify-content: center;
}

.market-menu ul li {
    padding: 1.5rem 1rem;
}

.market-menu ul li a {
    color: #000000;
}

.market-menu ul li a.active, .market-menu ul li a:hover {
    color: #B18F4F;
}

#circleG{
width:60.666666666666664px;
}

.circleG{
background-color:#FFFFFF;
float:left;
height:13px;
margin-left:7px;
width:13px;
-moz-animation-name:bounce_circleG;
-moz-animation-duration:1.9500000000000002s;
-moz-animation-iteration-count:infinite;
-moz-animation-direction:normal;
-moz-border-radius:9px;
-webkit-animation-name:bounce_circleG;
-webkit-animation-duration:1.9500000000000002s;
-webkit-animation-iteration-count:infinite;
-webkit-animation-direction:normal;
-webkit-border-radius:9px;
-ms-animation-name:bounce_circleG;
-ms-animation-duration:1.9500000000000002s;
-ms-animation-iteration-count:infinite;
-ms-animation-direction:normal;
-ms-border-radius:9px;
-o-animation-name:bounce_circleG;
-o-animation-duration:1.9500000000000002s;
-o-animation-iteration-count:infinite;
-o-animation-direction:normal;
-o-border-radius:9px;
animation-name:bounce_circleG;
animation-duration:1.9500000000000002s;
animation-iteration-count:infinite;
animation-direction:normal;
border-radius:9px;
}

#circleG_1{
-webkit-animation-delay:0.39s;
animation-delay:0.39s;
}

#circleG_2{
-webkit-animation-delay:0.9099999999999999s;
animation-delay:0.9099999999999999s;
}

#circleG_3{
-webkit-animation-delay:1.1700000000000002s;
animation-delay:1.1700000000000002s;
}

@-webkit-keyframes bounce_circleG{
0%{
}

50%{
background-color:#000000}

100%{
}

}

@keyframes bounce_circleG{
0%{
}

50%{
background-color:#000000}

100%{
}

}

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent
}

@-ms-viewport {
    width: device-width
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0!important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

dfn {
    font-style: italic
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}


a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none!important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit
}

.h1,
h1 {
    font-size: 2.5rem
}

.h2,
h2 {
    font-size: 2rem
}

.h3,
h3 {
    font-size: 1.75rem
}

.h4,
h4 {
    font-size: 1.5rem
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d
}

.blockquote-footer::before {
    content: "\2014   \A0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #6c757d
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none
}

.col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%
}

.col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%
}

.col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.333333%
}

.offset-2 {
    margin-left: 16.666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.333333%
}

.offset-5 {
    margin-left: 41.666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.333333%
}

.offset-8 {
    margin-left: 66.666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.333333%
}

.offset-11 {
    margin-left: 91.666667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }
    .col-sm-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-sm-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-sm-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-sm-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-sm-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-sm-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-sm-first {
        order: -1
    }
    .order-sm-last {
        order: 13
    }
    .order-sm-0 {
        order: 0
    }
    .order-sm-1 {
        order: 1
    }
    .order-sm-2 {
        order: 2
    }
    .order-sm-3 {
        order: 3
    }
    .order-sm-4 {
        order: 4
    }
    .order-sm-5 {
        order: 5
    }
    .order-sm-6 {
        order: 6
    }
    .order-sm-7 {
        order: 7
    }
    .order-sm-8 {
        order: 8
    }
    .order-sm-9 {
        order: 9
    }
    .order-sm-10 {
        order: 10
    }
    .order-sm-11 {
        order: 11
    }
    .order-sm-12 {
        order: 12
    }
    .offset-sm-0 {
        margin-left: 0
    }
    .offset-sm-1 {
        margin-left: 8.333333%
    }
    .offset-sm-2 {
        margin-left: 16.666667%
    }
    .offset-sm-3 {
        margin-left: 25%
    }
    .offset-sm-4 {
        margin-left: 33.333333%
    }
    .offset-sm-5 {
        margin-left: 41.666667%
    }
    .offset-sm-6 {
        margin-left: 50%
    }
    .offset-sm-7 {
        margin-left: 58.333333%
    }
    .offset-sm-8 {
        margin-left: 66.666667%
    }
    .offset-sm-9 {
        margin-left: 75%
    }
    .offset-sm-10 {
        margin-left: 83.333333%
    }
    .offset-sm-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }
    .col-md-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-md-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-md-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-md-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-md-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-md-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-md-first {
        order: -1
    }
    .order-md-last {
        order: 13
    }
    .order-md-0 {
        order: 0
    }
    .order-md-1 {
        order: 1
    }
    .order-md-2 {
        order: 2
    }
    .order-md-3 {
        order: 3
    }
    .order-md-4 {
        order: 4
    }
    .order-md-5 {
        order: 5
    }
    .order-md-6 {
        order: 6
    }
    .order-md-7 {
        order: 7
    }
    .order-md-8 {
        order: 8
    }
    .order-md-9 {
        order: 9
    }
    .order-md-10 {
        order: 10
    }
    .order-md-11 {
        order: 11
    }
    .order-md-12 {
        order: 12
    }
    .offset-md-0 {
        margin-left: 0
    }
    .offset-md-1 {
        margin-left: 8.333333%
    }
    .offset-md-2 {
        margin-left: 16.666667%
    }
    .offset-md-3 {
        margin-left: 25%
    }
    .offset-md-4 {
        margin-left: 33.333333%
    }
    .offset-md-5 {
        margin-left: 41.666667%
    }
    .offset-md-6 {
        margin-left: 50%
    }
    .offset-md-7 {
        margin-left: 58.333333%
    }
    .offset-md-8 {
        margin-left: 66.666667%
    }
    .offset-md-9 {
        margin-left: 75%
    }
    .offset-md-10 {
        margin-left: 83.333333%
    }
    .offset-md-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }
    .col-lg-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-lg-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-lg-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-lg-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-lg-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-lg-first {
        order: -1
    }
    .order-lg-last {
        order: 13
    }
    .order-lg-0 {
        order: 0
    }
    .order-lg-1 {
        order: 1
    }
    .order-lg-2 {
        order: 2
    }
    .order-lg-3 {
        order: 3
    }
    .order-lg-4 {
        order: 4
    }
    .order-lg-5 {
        order: 5
    }
    .order-lg-6 {
        order: 6
    }
    .order-lg-7 {
        order: 7
    }
    .order-lg-8 {
        order: 8
    }
    .order-lg-9 {
        order: 9
    }
    .order-lg-10 {
        order: 10
    }
    .order-lg-11 {
        order: 11
    }
    .order-lg-12 {
        order: 12
    }
    .offset-lg-0 {
        margin-left: 0
    }
    .offset-lg-1 {
        margin-left: 8.333333%
    }
    .offset-lg-2 {
        margin-left: 16.666667%
    }
    .offset-lg-3 {
        margin-left: 25%
    }
    .offset-lg-4 {
        margin-left: 33.333333%
    }
    .offset-lg-5 {
        margin-left: 41.666667%
    }
    .offset-lg-6 {
        margin-left: 50%
    }
    .offset-lg-7 {
        margin-left: 58.333333%
    }
    .offset-lg-8 {
        margin-left: 66.666667%
    }
    .offset-lg-9 {
        margin-left: 75%
    }
    .offset-lg-10 {
        margin-left: 83.333333%
    }
    .offset-lg-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }
    .col-xl-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-xl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-xl-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-xl-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-xl-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-xl-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-xl-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-xl-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-xl-first {
        order: -1
    }
    .order-xl-last {
        order: 13
    }
    .order-xl-0 {
        order: 0
    }
    .order-xl-1 {
        order: 1
    }
    .order-xl-2 {
        order: 2
    }
    .order-xl-3 {
        order: 3
    }
    .order-xl-4 {
        order: 4
    }
    .order-xl-5 {
        order: 5
    }
    .order-xl-6 {
        order: 6
    }
    .order-xl-7 {
        order: 7
    }
    .order-xl-8 {
        order: 8
    }
    .order-xl-9 {
        order: 9
    }
    .order-xl-10 {
        order: 10
    }
    .order-xl-11 {
        order: 11
    }
    .order-xl-12 {
        order: 12
    }
    .offset-xl-0 {
        margin-left: 0
    }
    .offset-xl-1 {
        margin-left: 8.333333%
    }
    .offset-xl-2 {
        margin-left: 16.666667%
    }
    .offset-xl-3 {
        margin-left: 25%
    }
    .offset-xl-4 {
        margin-left: 33.333333%
    }
    .offset-xl-5 {
        margin-left: 41.666667%
    }
    .offset-xl-6 {
        margin-left: 50%
    }
    .offset-xl-7 {
        margin-left: 58.333333%
    }
    .offset-xl-8 {
        margin-left: 66.666667%
    }
    .offset-xl-9 {
        margin-left: 75%
    }
    .offset-xl-10 {
        margin-left: 83.333333%
    }
    .offset-xl-11 {
        margin-left: 91.666667%
    }
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent
}

.table td,
.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6
}

.table .table {
    background-color: #fff
}

.table-sm td,
.table-sm th {
    padding: .3rem
}

.table-bordered {
    border: 1px solid #dee2e6
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #b8daff
}

.table-hover .table-primary:hover {
    background-color: #9fcdff
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #d6d8db
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #c3e6cb
}

.table-hover .table-success:hover {
    background-color: #b1dfbb
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #bee5eb
}

.table-hover .table-info:hover {
    background-color: #abdde5
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #ffeeba
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #f5c6cb
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fdfdfe
}

.table-hover .table-light:hover {
    background-color: #ececf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #c6c8ca
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.table-dark {
    color: #fff;
    background-color: #212529
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #32383e
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, .075)
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }
    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }
    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }
    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }
    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px)
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: .375rem;
    padding-bottom: .375rem;
    margin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-append>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px)
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
    height: calc(2.875rem + 2px)
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label {
    color: #6c757d
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28a745
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, .8);
    border-radius: .2rem
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
    border-color: #28a745
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
    display: block
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #28a745
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #28a745
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
    background-color: #71dd8a
}

.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    background-color: #34ce57
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #28a745
}

.custom-file-input.is-valid~.custom-file-label::before,
.was-validated .custom-file-input:valid~.custom-file-label::before {
    border-color: inherit
}

.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
    display: block
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(220, 53, 69, .8);
    border-radius: .2rem
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
    display: block
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #dc3545
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
    background-color: #efa2a9
}

.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
    background-color: #e4606d
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #dc3545
}

.custom-file-input.is-invalid~.custom-file-label::before,
.was-validated .custom-file-input:invalid~.custom-file-label::before {
    border-color: inherit
}

.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
    display: block
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0
    }
    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }
    .form-inline .form-control-plaintext {
        display: inline-block
    }
    .form-inline .input-group {
        width: auto
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }
    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }
    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn:focus,
.btn:hover {
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.btn.disabled,
.btn:disabled {
    opacity: .65
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
    background-image: none
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5
}

.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124
}

.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d
}

.btn-group-lg>.btn,
.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    opacity: 0;
    transition: opacity .15s linear
}

.fade.show {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.show {
    display: block
}

tr.collapse.show {
    display: table-row
}

tbody.collapse.show {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

.dropdown,
.dropup {
    position: relative
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropleft .dropdown-toggle::after {
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #6c757d;
    white-space: nowrap
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 0 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
    z-index: 1
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
    margin-left: -1px
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:first-child {
    margin-left: 0
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after {
    margin-left: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
    width: 100%
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.input-group>.custom-file:focus,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control {
    margin-left: -1px
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label,
.input-group>.custom-file:not(:first-child) .custom-file-label::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #007bff
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef
}

.custom-control-label {
    margin-bottom: 0
}

.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6
}

.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%
}

.custom-checkbox .custom-control-label::before {
    border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: #007bff
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(128, 189, 255, .5)
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: .75rem;
    background-image: none
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef
}

.custom-select::-ms-expand {
    opacity: 0
}

.custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 75%
}

.custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 125%
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-file-input:focus~.custom-file-control::before {
    border-color: #80bdff
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 .25rem .25rem 0
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #6c757d
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

@media (max-width:575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-sm .navbar-toggler {
        display: none
    }
    .navbar-expand-sm .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

@media (max-width:767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-md .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-md .navbar-toggler {
        display: none
    }
    .navbar-expand-md .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-lg .navbar-toggler {
        display: none
    }
    .navbar-expand-lg .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

@media (max-width:1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        flex-wrap: nowrap
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }
    .navbar-expand-xl .navbar-toggler {
        display: none
    }
    .navbar-expand-xl .dropup .dropdown-menu {
        top: auto;
        bottom: 100%
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex!important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}


.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #6c757d;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: #6c757d
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6
}

.page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #007bff
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #545b62
}

.badge-success {
    color: #fff;
    background-color: #28a745
}

.badge-success[href]:focus,
.badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34
}

.badge-info {
    color: #fff;
    background-color: #17a2b8
}

.badge-info[href]:focus,
.badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b
}

.badge-warning {
    color: #212529;
    background-color: #ffc107
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00
}

.badge-danger {
    color: #fff;
    background-color: #dc3545
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa
}

.badge-light[href]:focus,
.badge-light[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5
}

.badge-dark {
    color: #fff;
    background-color: #343a40
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem
}

@media (min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 4rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff
}

.alert-primary hr {
    border-top-color: #9fcdff
}

.alert-primary .alert-link {
    color: #002752
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db
}

.alert-secondary hr {
    border-top-color: #c8cbcf
}

.alert-secondary .alert-link {
    color: #202326
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb
}

.alert-success hr {
    border-top-color: #b1dfbb
}

.alert-success .alert-link {
    color: #0b2e13
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb
}

.alert-info hr {
    border-top-color: #abdde5
}

.alert-info .alert-link {
    color: #062c33
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba
}

.alert-warning hr {
    border-top-color: #ffe8a1
}

.alert-warning .alert-link {
    color: #533f03
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}

.alert-danger hr {
    border-top-color: #f1b0b7
}

.alert-danger .alert-link {
    color: #491217
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light hr {
    border-top-color: #ececf6
}

.alert-light .alert-link {
    color: #686868
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca
}

.alert-dark hr {
    border-top-color: #b9bbbe
}

.alert-dark .alert-link {
    color: #040505
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    background-color: #007bff;
    transition: width .6s ease
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
}

.media {
    display: flex;
    align-items: flex-start
}

.media-body {
    flex: 1 1
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.list-group-item:focus,
.list-group-item:hover {
    z-index: 1;
    text-decoration: none
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0
}

.list-group-item-primary {
    color: #004085;
    background-color: #b8daff
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #004085;
    background-color: #9fcdff
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085
}

.list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #383d41;
    background-color: #c8cbcf
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41
}

.list-group-item-success {
    color: #155724;
    background-color: #c3e6cb
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #155724;
    background-color: #b1dfbb
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724
}

.list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #0c5460;
    background-color: #abdde5
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460
}

.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #856404;
    background-color: #ffe8a1
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #721c24;
    background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #818182;
    background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182
}

.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #1b1e21;
    background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none
}

.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -25%)
}

.modal.show .modal-dialog {
    transform: translate(0, 0)
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (.5rem * 2))
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef
}

.modal-footer>:not(:first-child) {
    margin-left: .25rem
}

.modal-footer>:not(:last-child) {
    margin-right: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }
    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2))
    }
    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {
    .modal-lg {
        max-width: 800px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem
}

.popover .arrow::after,
.popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top] .arrow,
.bs-popover-top .arrow {
    bottom: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
    border-width: .5rem .5rem 0
}

.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-top .arrow::after {
    bottom: 1px;
    border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right] .arrow,
.bs-popover-right .arrow {
    left: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
    border-width: .5rem .5rem .5rem 0
}

.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-right .arrow::after {
    left: 1px;
    border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom] .arrow,
.bs-popover-bottom .arrow {
    top: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
    border-width: 0 .5rem .5rem .5rem
}

.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
    top: 1px;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left] .arrow,
.bs-popover-left .arrow {
    right: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
    border-width: .5rem 0 .5rem .5rem
}

.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-left .arrow::after {
    right: 1px;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .5rem .75rem;
    color: #212529
}

.carousel {
    position: relative
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    transition: transform .6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translateX(0)
}

@supports (transform-style:preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        transform: translate3d(0, 0, 0)
    }
}

.active.carousel-item-right,
.carousel-item-next {
    transform: translateX(100%)
}

@supports (transform-style:preserve-3d) {
    .active.carousel-item-right,
    .carousel-item-next {
        transform: translate3d(100%, 0, 0)
    }
}

.active.carousel-item-left,
.carousel-item-prev {
    transform: translateX(-100%)
}

@supports (transform-style:preserve-3d) {
    .active.carousel-item-left,
    .carousel-item-prev {
        transform: translate3d(-100%, 0, 0)
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, .5)
}

.carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""
}

.carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""
}

.carousel-indicators .active {
    background-color: #fff
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.bg-primary {
    background-color: #007bff!important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0062cc!important
}

.bg-secondary {
    background-color: #6c757d!important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #545b62!important
}

.bg-success {
    background-color: #28a745!important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #1e7e34!important
}

.bg-info {
    background-color: #17a2b8!important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #117a8b!important
}

.bg-warning {
    background-color: #ffc107!important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #d39e00!important
}

.bg-danger {
    background-color: #dc3545!important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #bd2130!important
}

.bg-light {
    background-color: #f8f9fa!important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5!important
}

.bg-dark {
    background-color: #343a40!important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124!important
}

.bg-white {
    background-color: #fff!important
}

.bg-transparent {
    background-color: transparent!important
}

.border {
    border: 1px solid #dee2e6!important
}

.border-top {
    border-top: 1px solid #dee2e6!important
}

.border-right {
    border-right: 1px solid #dee2e6!important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6!important
}

.border-left {
    border-left: 1px solid #dee2e6!important
}

.border-0 {
    border: 0!important
}

.border-top-0 {
    border-top: 0!important
}

.border-right-0 {
    border-right: 0!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-left-0 {
    border-left: 0!important
}

.border-primary {
    border-color: #007bff!important
}

.border-secondary {
    border-color: #6c757d!important
}

.border-success {
    border-color: #28a745!important
}

.border-info {
    border-color: #17a2b8!important
}

.border-warning {
    border-color: #ffc107!important
}

.border-danger {
    border-color: #dc3545!important
}

.border-light {
    border-color: #f8f9fa!important
}

.border-dark {
    border-color: #343a40!important
}

.border-white {
    border-color: #fff!important
}

.rounded {
    border-radius: .25rem!important
}

.rounded-top {
    border-top-left-radius: .25rem!important;
    border-top-right-radius: .25rem!important
}

.rounded-right {
    border-top-right-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-left {
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-0 {
    border-radius: 0!important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: flex!important
}

.d-inline-flex {
    display: inline-flex!important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none!important
    }
    .d-sm-inline {
        display: inline!important
    }
    .d-sm-inline-block {
        display: inline-block!important
    }
    .d-sm-block {
        display: block!important
    }
    .d-sm-table {
        display: table!important
    }
    .d-sm-table-row {
        display: table-row!important
    }
    .d-sm-table-cell {
        display: table-cell!important
    }
    .d-sm-flex {
        display: flex!important
    }
    .d-sm-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none!important
    }
    .d-md-inline {
        display: inline!important
    }
    .d-md-inline-block {
        display: inline-block!important
    }
    .d-md-block {
        display: block!important
    }
    .d-md-table {
        display: table!important
    }
    .d-md-table-row {
        display: table-row!important
    }
    .d-md-table-cell {
        display: table-cell!important
    }
    .d-md-flex {
        display: flex!important
    }
    .d-md-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none!important
    }
    .d-lg-inline {
        display: inline!important
    }
    .d-lg-inline-block {
        display: inline-block!important
    }
    .d-lg-block {
        display: block!important
    }
    .d-lg-table {
        display: table!important
    }
    .d-lg-table-row {
        display: table-row!important
    }
    .d-lg-table-cell {
        display: table-cell!important
    }
    .d-lg-flex {
        display: flex!important
    }
    .d-lg-inline-flex {
        display: inline-flex!important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none!important
    }
    .d-xl-inline {
        display: inline!important
    }
    .d-xl-inline-block {
        display: inline-block!important
    }
    .d-xl-block {
        display: block!important
    }
    .d-xl-table {
        display: table!important
    }
    .d-xl-table-row {
        display: table-row!important
    }
    .d-xl-table-cell {
        display: table-cell!important
    }
    .d-xl-flex {
        display: flex!important
    }
    .d-xl-inline-flex {
        display: inline-flex!important
    }
}

@media print {
    .d-print-none {
        display: none!important
    }
    .d-print-inline {
        display: inline!important
    }
    .d-print-inline-block {
        display: inline-block!important
    }
    .d-print-block {
        display: block!important
    }
    .d-print-table {
        display: table!important
    }
    .d-print-table-row {
        display: table-row!important
    }
    .d-print-table-cell {
        display: table-cell!important
    }
    .d-print-flex {
        display: flex!important
    }
    .d-print-inline-flex {
        display: inline-flex!important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.857143%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row!important
}

.flex-column {
    flex-direction: column!important
}

.flex-row-reverse {
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    flex-direction: column-reverse!important
}

.flex-wrap {
    flex-wrap: wrap!important
}

.flex-nowrap {
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse!important
}

.justify-content-start {
    justify-content: flex-start!important
}

.justify-content-end {
    justify-content: flex-end!important
}

.justify-content-center {
    justify-content: center!important
}

.justify-content-between {
    justify-content: space-between!important
}

.justify-content-around {
    justify-content: space-around!important
}

.align-items-start {
    align-items: flex-start!important
}

.align-items-end {
    align-items: flex-end!important
}

.align-items-center {
    align-items: center!important
}

.align-items-baseline {
    align-items: baseline!important
}

.align-items-stretch {
    align-items: stretch!important
}

.align-content-start {
    align-content: flex-start!important
}

.align-content-end {
    align-content: flex-end!important
}

.align-content-center {
    align-content: center!important
}

.align-content-between {
    align-content: space-between!important
}

.align-content-around {
    align-content: space-around!important
}

.align-content-stretch {
    align-content: stretch!important
}

.align-self-auto {
    align-self: auto!important
}

.align-self-start {
    align-self: flex-start!important
}

.align-self-end {
    align-self: flex-end!important
}

.align-self-center {
    align-self: center!important
}

.align-self-baseline {
    align-self: baseline!important
}

.align-self-stretch {
    align-self: stretch!important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row!important
    }
    .flex-sm-column {
        flex-direction: column!important
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-sm-wrap {
        flex-wrap: wrap!important
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .justify-content-sm-start {
        justify-content: flex-start!important
    }
    .justify-content-sm-end {
        justify-content: flex-end!important
    }
    .justify-content-sm-center {
        justify-content: center!important
    }
    .justify-content-sm-between {
        justify-content: space-between!important
    }
    .justify-content-sm-around {
        justify-content: space-around!important
    }
    .align-items-sm-start {
        align-items: flex-start!important
    }
    .align-items-sm-end {
        align-items: flex-end!important
    }
    .align-items-sm-center {
        align-items: center!important
    }
    .align-items-sm-baseline {
        align-items: baseline!important
    }
    .align-items-sm-stretch {
        align-items: stretch!important
    }
    .align-content-sm-start {
        align-content: flex-start!important
    }
    .align-content-sm-end {
        align-content: flex-end!important
    }
    .align-content-sm-center {
        align-content: center!important
    }
    .align-content-sm-between {
        align-content: space-between!important
    }
    .align-content-sm-around {
        align-content: space-around!important
    }
    .align-content-sm-stretch {
        align-content: stretch!important
    }
    .align-self-sm-auto {
        align-self: auto!important
    }
    .align-self-sm-start {
        align-self: flex-start!important
    }
    .align-self-sm-end {
        align-self: flex-end!important
    }
    .align-self-sm-center {
        align-self: center!important
    }
    .align-self-sm-baseline {
        align-self: baseline!important
    }
    .align-self-sm-stretch {
        align-self: stretch!important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row!important
    }
    .flex-md-column {
        flex-direction: column!important
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-md-wrap {
        flex-wrap: wrap!important
    }
    .flex-md-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .justify-content-md-start {
        justify-content: flex-start!important
    }
    .justify-content-md-end {
        justify-content: flex-end!important
    }
    .justify-content-md-center {
        justify-content: center!important
    }
    .justify-content-md-between {
        justify-content: space-between!important
    }
    .justify-content-md-around {
        justify-content: space-around!important
    }
    .align-items-md-start {
        align-items: flex-start!important
    }
    .align-items-md-end {
        align-items: flex-end!important
    }
    .align-items-md-center {
        align-items: center!important
    }
    .align-items-md-baseline {
        align-items: baseline!important
    }
    .align-items-md-stretch {
        align-items: stretch!important
    }
    .align-content-md-start {
        align-content: flex-start!important
    }
    .align-content-md-end {
        align-content: flex-end!important
    }
    .align-content-md-center {
        align-content: center!important
    }
    .align-content-md-between {
        align-content: space-between!important
    }
    .align-content-md-around {
        align-content: space-around!important
    }
    .align-content-md-stretch {
        align-content: stretch!important
    }
    .align-self-md-auto {
        align-self: auto!important
    }
    .align-self-md-start {
        align-self: flex-start!important
    }
    .align-self-md-end {
        align-self: flex-end!important
    }
    .align-self-md-center {
        align-self: center!important
    }
    .align-self-md-baseline {
        align-self: baseline!important
    }
    .align-self-md-stretch {
        align-self: stretch!important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row!important
    }
    .flex-lg-column {
        flex-direction: column!important
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-lg-wrap {
        flex-wrap: wrap!important
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .justify-content-lg-start {
        justify-content: flex-start!important
    }
    .justify-content-lg-end {
        justify-content: flex-end!important
    }
    .justify-content-lg-center {
        justify-content: center!important
    }
    .justify-content-lg-between {
        justify-content: space-between!important
    }
    .justify-content-lg-around {
        justify-content: space-around!important
    }
    .align-items-lg-start {
        align-items: flex-start!important
    }
    .align-items-lg-end {
        align-items: flex-end!important
    }
    .align-items-lg-center {
        align-items: center!important
    }
    .align-items-lg-baseline {
        align-items: baseline!important
    }
    .align-items-lg-stretch {
        align-items: stretch!important
    }
    .align-content-lg-start {
        align-content: flex-start!important
    }
    .align-content-lg-end {
        align-content: flex-end!important
    }
    .align-content-lg-center {
        align-content: center!important
    }
    .align-content-lg-between {
        align-content: space-between!important
    }
    .align-content-lg-around {
        align-content: space-around!important
    }
    .align-content-lg-stretch {
        align-content: stretch!important
    }
    .align-self-lg-auto {
        align-self: auto!important
    }
    .align-self-lg-start {
        align-self: flex-start!important
    }
    .align-self-lg-end {
        align-self: flex-end!important
    }
    .align-self-lg-center {
        align-self: center!important
    }
    .align-self-lg-baseline {
        align-self: baseline!important
    }
    .align-self-lg-stretch {
        align-self: stretch!important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        flex-direction: row!important
    }
    .flex-xl-column {
        flex-direction: column!important
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-xl-wrap {
        flex-wrap: wrap!important
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .justify-content-xl-start {
        justify-content: flex-start!important
    }
    .justify-content-xl-end {
        justify-content: flex-end!important
    }
    .justify-content-xl-center {
        justify-content: center!important
    }
    .justify-content-xl-between {
        justify-content: space-between!important
    }
    .justify-content-xl-around {
        justify-content: space-around!important
    }
    .align-items-xl-start {
        align-items: flex-start!important
    }
    .align-items-xl-end {
        align-items: flex-end!important
    }
    .align-items-xl-center {
        align-items: center!important
    }
    .align-items-xl-baseline {
        align-items: baseline!important
    }
    .align-items-xl-stretch {
        align-items: stretch!important
    }
    .align-content-xl-start {
        align-content: flex-start!important
    }
    .align-content-xl-end {
        align-content: flex-end!important
    }
    .align-content-xl-center {
        align-content: center!important
    }
    .align-content-xl-between {
        align-content: space-between!important
    }
    .align-content-xl-around {
        align-content: space-around!important
    }
    .align-content-xl-stretch {
        align-content: stretch!important
    }
    .align-self-xl-auto {
        align-self: auto!important
    }
    .align-self-xl-start {
        align-self: flex-start!important
    }
    .align-self-xl-end {
        align-self: flex-end!important
    }
    .align-self-xl-center {
        align-self: center!important
    }
    .align-self-xl-baseline {
        align-self: baseline!important
    }
    .align-self-xl-stretch {
        align-self: stretch!important
    }
}

.float-left {
    float: left!important
}

.float-right {
    float: right!important
}

.float-none {
    float: none!important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left!important
    }
    .float-sm-right {
        float: right!important
    }
    .float-sm-none {
        float: none!important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left!important
    }
    .float-md-right {
        float: right!important
    }
    .float-md-none {
        float: none!important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left!important
    }
    .float-lg-right {
        float: right!important
    }
    .float-lg-none {
        float: none!important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left!important
    }
    .float-xl-right {
        float: right!important
    }
    .float-xl-none {
        float: none!important
    }
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: -webkit-sticky!important;
    position: sticky!important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.mw-100 {
    max-width: 100%!important
}

.mh-100 {
    max-height: 100%!important
}

.m-0 {
    margin: 0!important
}

.mt-0,
.my-0 {
    margin-top: 0!important
}

.mr-0,
.mx-0 {
    margin-right: 0!important
}

.mb-0,
.my-0 {
    margin-bottom: 0!important
}

.ml-0,
.mx-0 {
    margin-left: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1,
.my-1 {
    margin-top: .25rem!important
}

.mr-1,
.mx-1 {
    margin-right: .25rem!important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem!important
}

.ml-1,
.mx-1 {
    margin-left: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2,
.my-2 {
    margin-top: .5rem!important
}

.mr-2,
.mx-2 {
    margin-right: .5rem!important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem!important
}

.ml-2,
.mx-2 {
    margin-left: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.mt-3,
.my-3 {
    margin-top: 1rem!important
}

.mr-3,
.mx-3 {
    margin-right: 1rem!important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem!important
}

.ml-3,
.mx-3 {
    margin-left: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem!important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem!important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem!important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5,
.my-5 {
    margin-top: 3rem!important
}

.mr-5,
.mx-5 {
    margin-right: 3rem!important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem!important
}

.ml-5,
.mx-5 {
    margin-left: 3rem!important
}

.p-0 {
    padding: 0!important
}

.pt-0,
.py-0 {
    padding-top: 0!important
}

.pr-0,
.px-0 {
    padding-right: 0!important
}

.pb-0,
.py-0 {
    padding-bottom: 0!important
}

.pl-0,
.px-0 {
    padding-left: 0!important
}

.p-1 {
    padding: .25rem!important
}

.pt-1,
.py-1 {
    padding-top: .25rem!important
}

.pr-1,
.px-1 {
    padding-right: .25rem!important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem!important
}

.pl-1,
.px-1 {
    padding-left: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.pt-2,
.py-2 {
    padding-top: .5rem!important
}

.pr-2,
.px-2 {
    padding-right: .5rem!important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem!important
}

.pl-2,
.px-2 {
    padding-left: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.pt-3,
.py-3 {
    padding-top: 1rem!important
}

.pr-3,
.px-3 {
    padding-right: 1rem!important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem!important
}

.pl-3,
.px-3 {
    padding-left: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem!important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem!important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem!important
}

.pl-4,
.px-4 {
    padding-left: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.pt-5,
.py-5 {
    padding-top: 3rem!important
}

.pr-5,
.px-5 {
    padding-right: 3rem!important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem!important
}

.pl-5,
.px-5 {
    padding-left: 3rem!important
}

.m-auto {
    margin: auto!important
}

.mt-auto,
.my-auto {
    margin-top: auto!important
}

.mr-auto,
.mx-auto {
    margin-right: auto!important
}

.mb-auto,
.my-auto {
    margin-bottom: auto!important
}

.ml-auto,
.mx-auto {
    margin-left: auto!important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0!important
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0!important
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0!important
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0!important
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0!important
    }
    .m-sm-1 {
        margin: .25rem!important
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem!important
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem!important
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem!important
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem!important
    }
    .m-sm-2 {
        margin: .5rem!important
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem!important
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem!important
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem!important
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem!important
    }
    .m-sm-3 {
        margin: 1rem!important
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem!important
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem!important
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem!important
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem!important
    }
    .m-sm-4 {
        margin: 1.5rem!important
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem!important
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem!important
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem!important
    }
    .m-sm-5 {
        margin: 3rem!important
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem!important
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem!important
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem!important
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem!important
    }
    .p-sm-0 {
        padding: 0!important
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0!important
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0!important
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0!important
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0!important
    }
    .p-sm-1 {
        padding: .25rem!important
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem!important
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem!important
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem!important
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem!important
    }
    .p-sm-2 {
        padding: .5rem!important
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem!important
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem!important
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem!important
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem!important
    }
    .p-sm-3 {
        padding: 1rem!important
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem!important
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem!important
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem!important
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem!important
    }
    .p-sm-4 {
        padding: 1.5rem!important
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem!important
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem!important
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem!important
    }
    .p-sm-5 {
        padding: 3rem!important
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem!important
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem!important
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem!important
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem!important
    }
    .m-sm-auto {
        margin: auto!important
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto!important
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto!important
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto!important
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto!important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0!important
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0!important
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0!important
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0!important
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0!important
    }
    .m-md-1 {
        margin: .25rem!important
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem!important
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem!important
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem!important
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem!important
    }
    .m-md-2 {
        margin: .5rem!important
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem!important
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem!important
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem!important
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem!important
    }
    .m-md-3 {
        margin: 1rem!important
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem!important
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem!important
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem!important
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem!important
    }
    .m-md-4 {
        margin: 1.5rem!important
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem!important
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem!important
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem!important
    }
    .m-md-5 {
        margin: 3rem!important
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem!important
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem!important
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem!important
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem!important
    }
    .p-md-0 {
        padding: 0!important
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0!important
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0!important
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0!important
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0!important
    }
    .p-md-1 {
        padding: .25rem!important
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem!important
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem!important
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem!important
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem!important
    }
    .p-md-2 {
        padding: .5rem!important
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem!important
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem!important
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem!important
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem!important
    }
    .p-md-3 {
        padding: 1rem!important
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem!important
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem!important
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem!important
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem!important
    }
    .p-md-4 {
        padding: 1.5rem!important
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem!important
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem!important
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem!important
    }
    .p-md-5 {
        padding: 3rem!important
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem!important
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem!important
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem!important
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem!important
    }
    .m-md-auto {
        margin: auto!important
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto!important
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto!important
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto!important
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto!important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0!important
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0!important
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0!important
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0!important
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0!important
    }
    .m-lg-1 {
        margin: .25rem!important
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem!important
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem!important
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem!important
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem!important
    }
    .m-lg-2 {
        margin: .5rem!important
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem!important
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem!important
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem!important
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem!important
    }
    .m-lg-3 {
        margin: 1rem!important
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem!important
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem!important
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem!important
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem!important
    }
    .m-lg-4 {
        margin: 1.5rem!important
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem!important
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem!important
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem!important
    }
    .m-lg-5 {
        margin: 3rem!important
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem!important
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem!important
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem!important
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem!important
    }
    .p-lg-0 {
        padding: 0!important
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0!important
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0!important
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0!important
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0!important
    }
    .p-lg-1 {
        padding: .25rem!important
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem!important
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem!important
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem!important
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem!important
    }
    .p-lg-2 {
        padding: .5rem!important
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem!important
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem!important
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem!important
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem!important
    }
    .p-lg-3 {
        padding: 1rem!important
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem!important
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem!important
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem!important
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem!important
    }
    .p-lg-4 {
        padding: 1.5rem!important
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem!important
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem!important
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem!important
    }
    .p-lg-5 {
        padding: 3rem!important
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem!important
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem!important
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem!important
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem!important
    }
    .m-lg-auto {
        margin: auto!important
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto!important
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto!important
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto!important
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto!important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0!important
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0!important
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0!important
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0!important
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0!important
    }
    .m-xl-1 {
        margin: .25rem!important
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem!important
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem!important
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem!important
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem!important
    }
    .m-xl-2 {
        margin: .5rem!important
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem!important
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem!important
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem!important
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem!important
    }
    .m-xl-3 {
        margin: 1rem!important
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem!important
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem!important
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem!important
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem!important
    }
    .m-xl-4 {
        margin: 1.5rem!important
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem!important
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem!important
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem!important
    }
    .m-xl-5 {
        margin: 3rem!important
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem!important
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem!important
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem!important
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem!important
    }
    .p-xl-0 {
        padding: 0!important
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0!important
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0!important
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0!important
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0!important
    }
    .p-xl-1 {
        padding: .25rem!important
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem!important
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem!important
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem!important
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem!important
    }
    .p-xl-2 {
        padding: .5rem!important
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem!important
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem!important
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem!important
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem!important
    }
    .p-xl-3 {
        padding: 1rem!important
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem!important
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem!important
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem!important
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem!important
    }
    .p-xl-4 {
        padding: 1.5rem!important
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem!important
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem!important
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem!important
    }
    .p-xl-5 {
        padding: 3rem!important
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem!important
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem!important
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem!important
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem!important
    }
    .m-xl-auto {
        margin: auto!important
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto!important
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto!important
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto!important
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto!important
    }
}

.text-justify {
    text-align: justify!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left!important
}

.text-right {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left!important
    }
    .text-sm-right {
        text-align: right!important
    }
    .text-sm-center {
        text-align: center!important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left!important
    }
    .text-md-right {
        text-align: right!important
    }
    .text-md-center {
        text-align: center!important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left!important
    }
    .text-lg-right {
        text-align: right!important
    }
    .text-lg-center {
        text-align: center!important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left!important
    }
    .text-xl-right {
        text-align: right!important
    }
    .text-xl-center {
        text-align: center!important
    }
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.font-weight-light {
    font-weight: 300!important
}

.font-weight-normal {
    font-weight: 400!important
}

.font-weight-bold {
    font-weight: 700!important
}

.font-italic {
    font-style: italic!important
}

.text-white {
    color: #fff!important
}

.text-primary {
    color: #007bff!important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0062cc!important
}

.text-secondary {
    color: #6c757d!important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #545b62!important
}

.text-success {
    color: #28a745!important
}

a.text-success:focus,
a.text-success:hover {
    color: #1e7e34!important
}

.text-info {
    color: #17a2b8!important
}

a.text-info:focus,
a.text-info:hover {
    color: #117a8b!important
}

.text-warning {
    color: #ffc107!important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #d39e00!important
}

.text-danger {
    color: #dc3545!important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #bd2130!important
}

.text-light {
    color: #f8f9fa!important
}

a.text-light:focus,
a.text-light:hover {
    color: #dae0e5!important
}

.text-dark {
    color: #343a40!important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #1d2124!important
}

.text-muted {
    color: #6c757d!important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

@media print {
    *,
    ::after,
    ::before {
        text-shadow: none!important;
        box-shadow: none!important
    }
    a:not(.btn) {
        text-decoration: underline
    }
    abbr[title]::after {
        content: " (" attr(title) ")"
    }
    pre {
        white-space: pre-wrap!important
    }
    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }
    thead {
        display: table-header-group
    }
    img,
    tr {
        page-break-inside: avoid
    }
    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }
    h2,
    h3 {
        page-break-after: avoid
    }
    @page {
        size: a3
    }
    body {
        min-width: 992px!important
    }
    .container {
        min-width: 992px!important
    }
    .navbar {
        display: none
    }
    .badge {
        border: 1px solid #000
    }
    .table {
        border-collapse: collapse!important
    }
    .table td,
    .table th {
        background-color: #fff!important
    }
    .table-bordered td,
    .table-bordered th {
        border: 1px solid #ddd!important
    }
}


/* # sourceMappingURL=bootstrap.min.css.map */

.garage .no-cars {
    margin: 0;
    color: #000000;
    text-align: center;
    height: 45.9vh;
}

.garage .no-cars h2 {
    margin: 0 0 3rem 0;
    font-weight: 400;
    font-size: 1.8rem;
}

.garage .no-cars p {
    font-size: 1rem;
    padding-bottom: 3rem;
    max-width: 21rem;
    margin: 0 auto;
}

.garage .no-cars a.button.primary {
    padding: 1rem 1.6rem;
}

.garage .header {
    color: #000000;
    text-align: left;
    font-size: 1.625rem;
    padding: 2rem 0;
    max-width: 60rem;
    margin: 0 auto;
}

.garage .header i {
    font-size: 1rem;
    padding: 0 1rem;
}

.garage .cards {
    background-color: #F0EFEF;
}

.garage .cards > div {
    max-width: 60rem;
    margin: 0 auto;
    padding: 5rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.garage .cards .borrow-mark {
    position: absolute;
    display: flex;
    top: 0.75rem;
    left: 1rem;
}

.garage .cards .card .borrow-mark > span{
    margin-left: 1rem;
    color:  #B18F4F;
    font-family: roboto, sans-serif;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.garage .cards .overlay .borrow-mark-overlay-container {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
}

.garage .cards .overlay .borrow-mark-overlay-container .borrow-mark-overlay {
    display: flex;
}

.garage .cards .overlay .borrow-mark-overlay-container .borrow-mark-overlay > span{
    margin-left: 1rem;
    color: #FFFFFF;
    font-family: roboto, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
}

.garage .cards .card {
    position: relative;
    background-color: #FFFFFF;
    color: #000000;
    max-width: 18.75rem;
    text-align: left;
    margin: 0 .5rem 1.85rem .5rem;
    padding-bottom: 2.5rem;
    overflow: hidden;
}

.garage .cards .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(177, 143, 79, 0.90);
    z-index: 100;
    text-align: center;
    opacity: 0;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
}

.garage .cards .overlay:hover {
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
    opacity: 100;
}

.garage .cards .overlay > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.garage .cards .overlay > div .button.primary {
    width: 83%;
    margin: .5rem 0;
    background-color: transparent;
    border-color: #FFFFFF;
}

.garage .cards .overlay > div .borrow {
    letter-spacing: .1rem;
    border-radius: .2rem;
    text-transform: uppercase;
    border: 0.05rem solid #B18F4F;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    font-size: .8rem;
    outline: none;
}

.garage .modal-back {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}
.garage .modal {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    position:fixed;
    z-index: 1001;    
    display: block !important; 
}

.garage .modal {
    color: #000000;
    font-family: roboto, sans-serif;
}

.garage .modal .modal-dialog {
    max-width: 700px;
    z-index: 1002;  
}

.garage .modal .modal-header {
    padding: 1rem 1rem 1rem 3rem;
}

.garage .modal .modal-body {
    display: flex;
    padding: 1rem 3rem;
}

.garage .modal .modal-body .modal-body-container {
    float: left;
    width: 60%;
    padding-right: 2rem;
}

.garage .modal .modal-body .modal-body-container > input{
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 1rem;
}

.garage .modal .modal-body .modal-body-text {
    text-align: left;
    align-self: center;
}

.garage .modal .modal-body .modal-body-img {
    float: left;
    width: 40%;
    display: grid;
}

.garage .modal .modal-body .modal-body-img .car-name {
    font-size: 18px;
    font-weight: bold;
    font-family: roboto, sans-serif;
}

.garage .modal-footer .modal-footer {
    display: flex;
}

.garage .modal .modal-footer {
    justify-content: space-between;
    padding: 1.2rem 3rem;
}

.garage .modal .modal-footer .button-container {
    width: 60%;
    text-align: left;
}

.garage .modal .modal-footer .button-container > button {
    background-color: #B18F4F;
    border-color: #B18F4F;
    font-size: 14px;
    padding-left: 3rem;
    padding-right: 3rem;
}

.garage .modal .modal-footer .model-number-container {
    width: 40%;
    text-align: center;
}

.garage .modal .modal-footer .model-number-container .model-number {
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;
    background-color: #F0EFEF;
    max-width: 9.7rem;
    padding: .2rem .4rem;
    border-radius: .5rem;
    letter-spacing: .1rem;
    margin: 0 auto;
}

.required {
    border: 1px solid red !important;
}

.my-node-enter {
    opacity: 0;    
}
.my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;    
}
.my-node-exit {
    opacity: 1;    
}
.my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;    
}

.garage .cards .overlay > div .button.primary:hover {
    background-color: #FFFFFF;
    color: #000000;
}

.garage .cards .card img {
    max-width: 120%;
    position: relative;
    right: 10%;
}

.garage .cards .card .model-name,
.garage .cards .card .model-number {
    margin: 0 1.6rem 1rem 1.6rem;
}

.garage .cards .card .model-name {
    font-size: 2.3125rem;
    font-weight: bold;
}

.garage .cards .card .model-number {
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;
    background-color: #F0EFEF;
    max-width: 9.7rem;
    padding: .2rem 0;
    border-radius: .5rem;
    letter-spacing: .1rem;
}

.garage .cards .card .stat {
    padding: 1.5rem 1.5rem 0 1.5rem;
    display: flex;
    flex-wrap: wrap;
}

.garage .cards .card .stat-bar {
    height: 2px;
    background-color: #F0EFEF;
    flex: 1 0 85%;
    margin-top: .8rem;
}

.garage .cards .card .stat-value {
    flex: 1 0 10%;
    text-align: right;
    font-size: .9rem;
}

.garage .cards .card .stat-bar > .colored {
    height: 2px;
    background-color: #000000;
}

.garage .cards .card .stat-bar > .colored > .cover {
    height: 2px;
    background-color: #F0EFEF;
}

.garage .cards .card .stat-name {
    flex: 1 0 100%;
    text-transform: uppercase;
    color: #ABABAB;
    font-size: .6rem;
    letter-spacing: .12rem;
    font-weight: 500;
}

.garage .cards .card .stat-bar > .stat-circle {
    width: 6px;
    height: 6px;
    background-color: #000000;
    border-radius: 50rem;
    position: relative;
    margin-top: -0.25rem;
}

.garage .cards .card .mobile-buttons {
    text-align: center;
    margin-top: 2.5rem;
    display: none;
}

.garage .cards .card .mobile-buttons a {
    width: 90%;
    margin: .5rem 0;
    padding: 1rem 0;
}

@media screen and (max-width: 960px) {
    .garage .cards .card {
        max-width: 44%;
    }
}

@media screen and (max-width: 680px) {
    .garage .cards .card {
        max-width: none;
        max-width: initial;
        margin: 0 1.3rem 1.85rem 1.3rem;
    }

    .garage .cards .overlay {
        display: none;
    }

    .garage .cards .card .mobile-buttons {
        display: block;
    }
}

.headline {
    background-color: #242427;
    background-image: url('/images/headline/background.png');
    background-repeat:   no-repeat;
    background-position: center center;
    background-size: auto 80%;
}

.headline a {
    margin-bottom: 12rem;
}

.headline h1 {
    padding-top: 9.2rem;
    padding-bottom: 2.5rem;
    font-size: 6rem;
    font-weight: 700;
    margin: 0;
}

.headline i {
    padding-bottom: 2rem;
}

@media screen and (max-width: 1920px) {
    .headline {
        background-image: url('/images/headline/background-1920.png');
    }
}

@media screen and (max-width: 1180px) {
    .headline {
        background-size: 80% auto;
    }
}

@media screen and (max-width: 988px) {
    .headline h1 {
        font-size: 5rem;
    }
}

@media screen and (max-width: 826px) {
    .headline h1 {
        font-size: 4.5rem;
    }
}

@media screen and (max-width: 786px) {
    .headline {
        background-image: url('/images/headline/background-786.png');
        padding: 5rem 0;
    }

    .headline h1 {
        padding-top: 4rem;
    }

    .headline a {
        margin-bottom: 4rem;
    }
}

@media screen and (max-width: 583px) {
    .headline {
        padding: 0;
        padding: initial;
    }
}

div.car-section {
    background-color: #1C1C1C;
    padding-bottom: 10rem;
}

div.car-section div.description-block {
    max-width: 33rem;
    margin: 0 auto;
}

div.car-section p {
    margin: 0;
}

div.car-section p.title {
    font-size: 2.25rem;
    padding-bottom: .5rem;
}

div.car-section p.description {
    font-weight: 300;
    line-height: 1.5rem;
    padding-bottom: 1.2rem;
}

div.car-section hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
}

div.car-section img {
    width: 58rem;
    margin: 11rem 0 3rem 0;
}

@media screen and (max-width: 786px) {
    div.car-section {
        text-align: left;
        padding-bottom: 0;
    }

    div.car-section img {
        margin: 3rem 0;
        padding: 0 1.5rem;
    }

    div.car-section div.description-block {
        padding: 0 3rem;
        max-width: none;
        max-width: initial;
    }

    div.car-section p.title {
        font-size: 1.6rem;
        font-weight: bold;
        padding-bottom: 0;
    }

    div.car-section p.description {
        padding: 1.2rem 0;
        color: #CCCCCC;
    }

    div.car-section hr {
        display: inline-block;
    }
}

main div.split div.description-block {
    max-width: 33rem;
    margin: 0 auto;
}

main div.split p {
    margin: 0;
}

main div.split p.title {
    padding-bottom: .5rem;
    font-size: 1.6rem;
}

main div.split p.description {
    font-weight: 300;
    line-height: 1.5rem;
    padding-bottom: 1.2rem;
}

main div.split hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
}

main div.split {
    background-color: #1C1C1C;
}

main div.split > div {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
}

main div.split div.description-block {
    margin: 0;
    max-width: 27rem;
}

main div.split div.image-container {
    flex: 0 0 40%;
    text-align: center;
}

main div.split.left div.description-block {
    max-width: 22rem;
}

main div.split hr {
    margin: .5rem 0;
}

main div.split.white {
    color: #000000;
    background-color: #FFFFFF;
}

main div.split.light-gray {
    color: #000000;
    background-color: #F0EFEF;
}

main div.split.light-gray img {
    margin-top: 4rem;
}

main div.split.dark-gray {
    background-color: #161719;
}

main div.split.light-gray .title {
    font-size: 1rem;
    font-weight: 100;
}

main div.split.light-gray .description {
    font-size: 1rem;
    font-weight: 400;
}

main div.split.light-gray a.button.secondary {
    border: none;
    padding: 0;
    color: #B18F4F;
}

main div.split.light-gray a.button.secondary:hover {
    color: #FFFFFF;
}

main div .description {
    color: #CCCCCC;
}

main div.split:not(.inverted):not(.light-gray) .description {
    margin-right: 5rem;
}

main div.split.inverted > div {
    flex-direction: row-reverse;
}

main div.split.inverted .image-container {
    margin-right: 8rem
}

main div.split.inverted div.description-block {
    max-width: 22rem;
}

main div.split.white p.description,
main div.split.light-gray p.description {
    color: #000000;
}

main .market div .description {
    color: #000000;
}

@media screen and (max-width: 786px) {
    main div.split {
        padding: 3rem 0;
    }

    main div.split > div {
        flex-direction: column-reverse;
        text-align: left;
    }

    main div.split p.title {
        text-align: left;
        font-weight: bold;
        padding-bottom: 0;
    }

    main div.split:not(.inverted):not(.light-gray) .description {
        margin-right: 0;
        margin-right: initial;
    }

    main div.split div.image-container {
        margin: 3rem 0 5rem 0;
        padding: 0 1.5rem;
    }

    main div.split.light-gray div.image-container {
        margin: 1rem 0 0 0;
        padding: 0 1.5rem;
    }

    main div.split.white div.image-container {
        padding: 0;
    }

    main div.light-gray div.description-block {
        margin-top: 0;
        margin-top: initial;
    }

    main div.split.inverted > div {
        flex-direction: column-reverse;
    }

    main div.split.inverted .image-container {
        margin-right: 0;
        margin-right: initial;
        margin-top: 1rem;
        margin-bottom: 1.8rem;
    }

    main div.split div.description-block,
    main div.split.inverted div.description-block {
        max-width: none;
        max-width: initial;
        padding: 0 3rem;
    }

    main p.description {
        text-align: left;
        padding:  1.2rem 0;
    }

    main div.split.light-gray img {
        margin-top: 0;
        margin-top: initial;
    }

    main div.split.white img {
        padding: 0 2rem;
    }
}

#videoDiv {
  width: 100%;
  position: relative;
  max-height: 40rem;
  overflow: hidden;
}

#videoBlock {
  background-color: #1a1a1c;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#videoBlock div {
  width: 100%;
}

#videoMessage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#videoMessage h1 {
  padding-bottom: 0;
}

#videoMessage h3 {
  padding-bottom: 5rem;
}

.video video {
  background-color: #0a0908;
  opacity: 0.5;
}

.video a {
  margin-bottom: 12rem;
}

.video h1 {
  padding-top: 3%;
  padding-bottom: 2.5rem;
  font-size: 7.5rem;
  font-weight: 700;
  margin: 0;
}

.video i {
  padding-bottom: 2rem;
}

.join-the-drop-button {
  height: 10rem;
}

@media screen and (max-width: 1920px) {
  #videoDiv {
    width: 100%;
    position: relative;
    max-height: 40rem;
    overflow: hidden;
  }

  #videoBlock {
    background-color: #1a1a1c;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #videoBlock div {
    width: 100%;
  }

  #videoMessage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  #videoTitle {
    font-size: 100px;
  }

  .video video {
    background-color: #0a0908;
    opacity: 0.5;
  }

  .video a {
    margin-bottom: 12rem;
  }

  .video h1 {
    padding-top: 10%;
    padding-bottom: 2.5rem;
    font-size: 7.5rem;
    font-weight: 700;
    margin: 0;
  }

  .video i {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 1440px) {
  .video a {
    margin-bottom: 10rem;
  }

  .video h1 {
    padding-top: 14%;
    padding-bottom: 5rem;
    font-size: 6rem;
    font-weight: 700;
    margin: 0;
  }
}

@media screen and (max-width: 1200px) {
  .video a {
    margin-bottom: 9rem;
  }

  .video h1 {
    padding-top: 14%;
    padding-bottom: 5rem;
    font-size: 5rem;
    font-weight: 700;
    margin: 0;
  }
}

@media screen and (max-width: 1004px) {
  .video a {
    margin-bottom: 9rem;
  }

  h1 > .event-aby {
    word-spacing: 0;
  }

  .video h1 {
    padding-top: 10%;
    padding-bottom: 5rem;
    font-size: 5rem;
    font-weight: 700;
    margin: 0;
  }
}

@media screen and (max-width: 900px) {
  #videoMessage .desktop {
    display: none;
  }

  #videoMessage .mobile h1 {
    font-size: 2rem;
    letter-spacing: 0.5rem;
  }

  #videoMessage .mobile h2 {
    margin-top: 2rem;
    font-size: 2rem;
    letter-spacing: 0.4rem;
  }

  #videoMessage .mobile h3 {
    margin-top: 3rem;
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
  }
}

@media screen and (max-width: 786px) {
  .headline h1 {
    font-size: 3.5rem;
  }

  #videoDiv {
    width: 100%;
    position: relative;
    height: auto;
    overflow: hidden;
  }

  .video h1 {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
  }

  .video a {
    margin-bottom: 8rem;
  }

  .video a.button.primary.big {
    padding: 1rem 2.3rem;
    font-weight: normal;
  }

  h1 > .separator {
    display: none;
  }

  h1 > .event-date {
    word-spacing: 0;
  }

  .video {
    background-color: #000000;
  }
}

@media screen and (max-width: 662px) {
  .video h1 {
    padding-top: 7.2rem;
    font-size: 4rem;
    text-transform: capitalize;
    padding-bottom: 5rem;
  }

  .video h1 > span:nth-child(2) {
    display: none;
  }

  #videoDiv {
    height: 47rem;
  }

  #videoBlock {
    background-color: #000000;
    background-image: url("/images/drop-events/drop_event_img5.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video video {
    display: none;
  }

  #videoMessage .mobile h3 {
    margin-bottom: 6rem;
  }
}

@media screen and (max-width: 450px) {
  .video h1 {
    padding-top: 4rem;
    font-size: 3.68rem;
    padding-bottom: 5rem; /*padding-bottom: 12.5rem;*/
  }

  #videoDiv {
    height: 37rem;
  }

  .video a {
    margin-bottom: 8rem;
  }
}

.home .split.white.inverted img {
    box-shadow: 0 55px 40px -50px #a9a9a9;
}

.home .partners {
    padding: 4rem 0 10rem 0;
    margin: 0 auto;
    max-width: 70rem;
}

.home .partners h2 {
    color: #000000;
    font-weight: normal;
    font-size: 2.6rem;
    margin-bottom: 0;
}

.home .partners hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
    margin-bottom: 2rem;
}

.home .partners .logos {
    display: flex;
    flex-wrap: wrap;
}

.home .partners .logos > div {
    flex: 0 0 33%;
    margin: auto;
    padding: 1rem 0;
}

.home .partners .logos .gravity-sketch {
    text-align: left;
    margin-left: 1.5rem;
}

.home .partners .logos .neoworld {
    text-align: right;
    margin-right: 1.5rem;
}

/* Mobile */

.home .partners-mobile {
    max-width: 22rem;
    margin: 0 auto;
    display: none;
    padding: 2rem 0;
}

.home .partners-mobile h2 {
    color: #000000;
    font-weight: normal;
    font-size: 2.6rem;
    margin-bottom: 0;
}

.home .partners-mobile hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
    margin-bottom: 2rem;
}

.home .partners-mobile .logos {
    display: flex;
    flex-wrap: wrap;
}

.home .partners-mobile .logos > div {
    flex: 0 0 50%;
    margin: auto;
    padding: 1rem 0;
}

@media screen and (max-width: 1200px) {
    .home .partners {
        display: none;
    }

    .home .partners-mobile {
        display: block;
    }
}

@media screen and (max-width: 786px) {
    .home div.car-section {
        text-align: left;
        padding-bottom: 3rem;
    }
}


.car-card div.description-block {
    max-width: 33rem;
    margin: 0 auto;
    color: #000000;
}

.car-card p {
    margin: 0;
}

.car-card p.title {
    font-size: 2.25rem;
    font-weight: bold;
    padding-bottom: .5rem;
}

.car-card p.description {
    font-weight: 300;
    line-height: 1.5rem;
    padding-bottom: 1.2rem;
}

.car-card hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
}

.car-card {
    background-color: #1C1C1C;
}

.car-card > div {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
}

.car-card div.description-block {
    margin: 0;
    flex: 0 0 50%;
    padding: 0 5.9rem;
}

.car-card div.image-container {
    flex: 0 0 50%;
    text-align: center;
}

.car-card hr {
    margin: .5rem 0;
}

.car-card.gray {
    background-color: #F0EFEF;
}

.car-card.white {
    background-color: #FFFFFF;
}

.car-card.inverted > div {
    flex-direction: row-reverse;
}

.car-card a.button.secondary {
    padding: .7rem 2.57rem;
    margin-left: 1.25rem;
}

@media screen and (max-width: 786px) {
    .car-card.inverted > div,
    .car-card > div {
        flex-direction: column-reverse;
    }

    .car-card div.description-block {
        padding: 0 1rem;
    }

    .car-card p.description {
        padding: 1rem 0;
    }

    .car-card.white,
    .car-card.gray {
        padding: 3rem 0 5rem 0
    }
}

.carousel .control-dots {
    margin: 10px 0 23px 0;
}

.market-car {
  background-color: #ffffff;
}

.market-car-menu {
  background-color: #f0efef;
}

.market-car-menu a {
  color: #202124;
}

.market-car-menu a.active,
.market-car-menu a:hover {
  color: #b18f4f;
}

.market-car-menu ul {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  justify-content: center;
}

.market-car-menu ul li {
  padding: 3.5rem 4rem 1rem 4rem;
}

.market-car-menu hr {
  margin: 0;
  border: none;
  height: 1px;
  background-color: #ffffff;
}

.market-car-main-image {
  background: linear-gradient(
    to bottom,
    #f0efef 0%,
    #f0efef 75%,
    #f0efef 75%,
    white 75%,
    white 100%
  );
}

.market-car-main-image img {
  max-width: 63rem;
}

.market-car-header {
  color: #000000;
  background: #ffffff;
  padding-bottom: 7rem;
}

.market-car-header h1 {
  font-size: 11rem;
  padding: 0;
  margin: 0;
}

.sportcoupe .market-car-header h1 {
  font-size: 12rem;
}

.market-car-header h2 {
  font-size: 2.25rem;
  padding: 0;
  margin: 0;
  font-weight: 400;
}

.market-car-header hr {
  border: none;
  height: 1px;
  width: 2.5rem;
  background-color: #999999;
  margin: 1rem auto 1.7rem auto;
}

main .market-car div.split.white {
  padding: 1.9rem 0;
}

.statistics {
  color: #000000;
  background-color: #ffffff;
  padding-bottom: 11rem;
}

.statistics h3 {
  margin: 0;
  padding: 0;
  font-size: 4.5rem;
  font-weight: 400;
}

.statistics > div > div {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
}

.statistics > div p.title {
  padding-bottom: 0;
}

.statistics > div p.title .nominator {
  font-size: 9rem;
}

.statistics > div p.title sup {
  font-size: 6rem;
  vertical-align: 2rem;
}

.statistics > div p.title span.stat {
  position: relative;
  right: 5.6rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.statistics > div div.image-container {
  flex: 0 0 40%;
  text-align: center;
}

.statistics > div .margin-right {
  margin-right: 8rem;
}

.statistics > div hr {
  margin: 0.5rem 0;
  border: none;
  height: 1px;
  width: 2.5rem;
  background-color: #b18f4f;
}

.statistics > div p.description {
  font-weight: 300;
  line-height: 1.5rem;
  padding-bottom: 1.2rem;
  margin: 0;
  color: #000000;
}

.statistics .description-block {
  max-width: 22rem;
  margin-left: 5rem;
}

.market-car > .gallery {
  background-color: #363636;
}

.carousel .slide img {
  width: auto;
  max-width: 100%;
}

.market-car .stats {
  display: flex;
  text-align: center;
}

.market-car .stats .stats-image {
  color: #dddddd;
}

.market-car .stats hr {
  background-color: #acacac;
  border: none;
  height: 2px;
}

.market-car .stats .stats-image hr {
  background-color: #dddddd;
}

.market-car .stats .stats-chart hr {
  margin: 0.8rem auto 3rem auto;
}

.market-car .stats .title {
  text-transform: uppercase;
  font-size: 0.6rem;
  letter-spacing: 0.12rem;
  font-weight: 500;
}

.market-car .stats > div {
  padding: 0 1rem;
}

.market-car .stats hr {
  margin: 0.8rem auto;
  width: 15rem;
}

.market-car .stats .stats-chart .title {
  color: #acacac;
}

.market-car .stats img {
  margin-top: 1.5rem;
}

.market-car .stats .stats-chart .stat-component {
  margin-bottom: 2rem;
}

@media screen and (max-width: 980px) {
  .market-car .statistics .description-block {
    display: none;
  }

  .market-car-main-image img {
    max-width: 100%;
  }
}

@media screen and (max-width: 786px) {
  .market-car-menu ul {
    background-color: white;
    justify-content: space-evenly;
    align-items: center;
  }

  .market-car-menu ul li {
    padding: 0.75rem 1rem;
  }

  .market-car-menu ul li a {
    font-size: 10px;
  }

  .market-car-main-image {
    background: linear-gradient(
      to bottom,
      #f0efef 0%,
      #f0efef 72%,
      #f0efef 72%,
      white 72%,
      white 100%
    );
  }

  .market-car-header {
    padding-bottom: 3rem;
  }
  .sportcoupe .market-car-header h1,
  .market-car-header h1 {
    font-size: 3.2rem;
  }

  .market-car-header h2 {
    font-size: 1.3rem;
  }

  .market-car-header hr {
    width: 2rem;
    margin: 0.5rem auto 1.7rem auto;
  }

  .market-car-header a.button.primary.big {
    font-weight: normal;
    padding: 0.9rem 1rem;
    letter-spacing: 0.17rem;
    font-size: 15px;
  }

  .market-car > .gallery {
    margin-top: 4rem;
  }

  /* SPLIT */
  main .market-car div.split.white {
    padding: 0;
  }

  main .market-car div.split div.image-container {
    margin: 3rem 0 2rem 0;
  }

  main .market-car div.split p.title {
    font-size: 1.4rem;
  }

  main .market-car div.split div.description-block,
  main div.split.inverted div.description-block {
    padding: 0 2.2rem;
  }

  main .market-car div.split hr {
    width: 1.7rem;
  }

  main .market-car .statistics h3 {
    margin-top: 2rem;
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 568px) {
  .market-car .stats {
    flex-direction: column;
  }

  .market-car .stats {
    flex: 1 0 100%;
  }

  .market-car .stats hr {
    width: 70%;
  }

  .market-car .stats > div.stats-chart {
    margin-top: 2rem;
  }

  .market-car .stats .stats-chart .stat-component {
    padding: 0 2rem;
  }

  .market-car .statistics {
    padding-bottom: 3rem;
  }
}

.stat-component {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}

.stat-component .stat-bar {
    height: 2px;
    background-color: #F0EFEF;
    flex: 1 0 85%;
    margin-top: .8rem;
}

.stat-component .stat-value {
    flex: 1 0 10%;
    text-align: right;
    font-size: .9rem;
}

.stat-component .stat-bar > .colored {
    height: 2px;
    background-color: #000000;
}

.stat-component .stat-bar > .colored > .cover {
    height: 2px;
    background-color: #F0EFEF;
}

.stat-component .stat-name {
    flex: 1 0 100%;
    text-transform: uppercase;
    color: #ABABAB;
    font-size: .6rem;
    letter-spacing: .12rem;
    font-weight: 500;
}

.stat-component .stat-bar > .stat-circle {
    width: 6px;
    height: 6px;
    background-color: #000000;
    border-radius: 50rem;
    position: relative;
    margin-top: -0.25rem;
}

.not-found {
    color: #000000;
}

.settings {
    color: #333333;
    background-color: #FFFFFF;
    text-align: left;
    padding: 4.3rem 0;
}

.settings .center {
    max-width: 35rem;
}

.settings h1 {
    color: #000000;
    font-size: 1.62rem;
    font-weight: normal;
}

.settings .eu-resident {
    margin: 1.3rem 0 .5rem 0;
}

.settings input[type="radio"] {
    vertical-align: baseline;
    width: 1rem;
    height: 1rem;
}

.settings .username-email {
    margin: 1.3rem 0;
}

.settings .three-section {
    display: flex;
    margin: .7rem 0;
}

.settings .three-section div {
    flex: 1 1;
}

.settings .three-section .address {
    flex: 0 0 30%;
}

.settings .three-section .bold {
    flex: 0 1 55%;
}

.settings .three-section div.edit {
    flex: 0 1 15%;
    text-align: right;
}

.settings .saving,
.settings-modal .saving {
    margin: 1rem 0;
    font-size: .8rem;
}

.settings-modal h1 {
    color: #000000;
    font-size: 1.62rem;
    font-weight: normal;
}

.settings-modal button.secondary {
    color: #000000;
}

.settings-modal .buttons {
    text-align: right;
}

.settings-modal .buttons button {
    margin-top: .5rem;
    margin-left: .5rem;
}

@media screen and (max-width: 786px) {
    .settings {
        padding: 2rem 0;
    }

    .settings h1,
    .settings .eu-resident-option,
    .settings .logout-link,
    .settings .marketing-updates,
    .settings .username-email,
    .settings .saving,
    .settings .wallets {
        padding: 0 1rem;
    }
}

.sign-in {
    color: #333333;
    background-color: #FFFFFF;
    text-align: left;
    padding: 4.3rem 0;
}

.sign-in .center {
    max-width: 35rem;
    padding: 0 1rem;
}

.sign-in h1 {
    color: #000000;
    font-size: 1.62rem;
    font-weight: normal;
}

.sign-in input[type="text"],
.sign-in input[type="password"] {
    outline: none;
    max-width: 35rem;
    width: 100%;
    padding: 1.3rem .5rem;
    border-radius: .4rem;
    border: 1px solid #dddddd;
    font-size: 1rem;
    margin: .4rem 0;
}

.sign-in input[type="text"]:focus,
.sign-in input[type="password"]:focus {
    border: 1px solid #B18F4F;
}

.sign-in .eu-resident {
    margin: 2.1rem 0 .5rem 0;
}

.sign-in input[type="radio"] {
    vertical-align: baseline;
    width: 1rem;
    height: 1rem;
}

.sign-in hr {
    margin: 1rem 0;
    border: none;
    height: 1px;
    background-color: #DDDDDD;
}

.sign-in .sign-in-div {
    text-align: right;
    padding-top: 1.93rem;
}

.sign-in .sign-in-div button {
    width: 7.3rem;
    margin-left: .5rem;
}

.sign-in .already-have-account {
    padding: 1rem 0;
}

.sign-in .legal a {
    color: #B18F4F;
    font-size: medium;
    font-size: initial;
    text-transform: none;
    text-transform: initial;
    letter-spacing: normal;
    letter-spacing: initial;
}

@media screen and (max-width: 786px) {
    .sign-in {
        padding: 2rem 0;
    }
}

.success-message {
    text-align: center;
    color: #000000;  
    margin-top: 5rem;  
}

.success-message .fa-check-circle {
    font-size: 6rem;
    margin-bottom: 2rem;
}

.success-message p {
    margin-top: 2rem;
}

.success-message a {
    margin: 2rem 0;
}

.about {
    color: #000000;
}

.about .leandro {
    background-color: #000000;
    color: #FFFFFF;
    padding: 5rem 0;
}

.about .whitepaper {
    padding: 5rem 0;
}

.about .leandro hr,
.about .whitepaper hr {
    width: 2rem;
    margin: 0;
    border: none;
    height: 1px;
    background-color: #B18F4F;
}

.about .leandro .image ,
.about .whitepaper .image {
    margin: auto;
}

.about .whitepaper .image {
    margin-right: 4rem;
}

.about .whitepaper .image img {
    box-shadow: 0 55px 40px -50px #a9a9a9;
}
.about .leandro > div,
.about .whitepaper > div {
    margin: 0 auto;
    max-width: 60rem;
    display: flex;
}

.about .leandro > div > div,
.about .whitepaper > div > div {
    flex: 1 0 50%;
    margin: auto;
}

.about .whitepaper > div > div {
    text-align: left;
}

.about .whitepaper a {
    color: #000000;
}

.about .leandro h1 {
    font-size: 1rem;
    font-weight: lighter;
}

.about .leandro p {
    text-align: left;
    font-size: 1.625rem;
    line-height: 2.5rem;
}

.about .whitepaper h1 {
    font-size: 1.625rem;
    font-weight: normal;
}

.about .whitepaper p {
    text-align: left;
    font-size: 1rem;
    font-weight: lighter;
    line-height: 1.5rem;
}

.about div.top-section,
.about div.team-title {
    max-width: 35rem;
    margin: 0 auto;
}

.about div.top-section p {
    text-align: left;
    line-height: 1.5rem;
}

.about h1 {
    font-size: 2.25rem;
    text-align: left;
}

/* OUR TEAM */
.about .our-team {
    background-color: #F0EFEF;
    color: #000000;
}

.about .our-team h2 {
    margin: 0;
    font-weight: normal;
    font-size: 2.25rem;
    padding: 4rem 0 0 0;
}

.about .our-team .members {
    max-width: 35rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-bottom: 4rem;
}

.about .our-team .members .member {
    flex: 1 0 33.33%;
    padding-bottom: 2.5rem;
}

.about .our-team .members .member .title {
    padding: .6rem;
}

.about .our-team .members .member img {
    width: 11rem;
}

/* CONTACT */
.about .contact {
    color: #000000;
    padding: 8rem 0;
}

.about .contact h2 {
    margin: 0;
    font-weight: normal;
    font-size: 4.5rem;
}

.about .contact hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #B18F4F;
    width: 3rem;
    color: #B18F4F;
    padding-bottom: 2rem;
}

.about .contact textarea {
    margin: 1.5rem 0 2.37rem 0;
}

@media screen and (max-width: 786px) {
    .about .contact {
        padding: 4rem 1rem;
    }

    .about .our-team .members {
        padding-bottom: 4rem;
    }

    .about .our-team .members .member {
        flex: 1 0 50%;
    }

    .about .our-team h2 {
        padding: 4rem 0;
    }
}

@media screen and (max-width: 1035px) {
    .about .leandro,
    .about .whitepaper {
        padding: 4rem 0 2rem 0;
    }

    .about .leandro > div {
        flex-direction: column-reverse;
        max-width: 35rem;
        padding: 0 1rem;
    }

    .about .whitepaper > div {
        flex-direction: column;
        max-width: 35rem;
        padding: 0 1rem;
    }
    .about .leandro > div h1,
    .about .whitepaper > div h1 {
        padding-top: 1.5rem;
        font-weight: bold;
        font-size: 1rem;
    }

    .about .leandro > div p,
    .about .whitepaper > div p {
        font-size: 1rem;
        font-weight: lighter;
        line-height: 1.5rem;
    }

    .about .whitepaper .image {
        margin-right: 0;
        margin-right: initial;
    }
}

@media screen and (max-width: 450px) {
    .about .our-team .members .member {
        flex: 1 0 100%;
        padding-bottom: 0;
        padding-bottom: initial;
    }

    .about .our-team h2 {
        padding: 2rem 0 0 0;
        font-weight: bold;
    }

    .about .our-team .members .member img {
        width: 13rem;
    }

    .about .contact h2 {
        text-align: left;
        font-weight: bold;
        font-size: 2rem;
    }

    .about .contact hr {
        width: 1.3rem;
        display: table-caption;
        padding-bottom: .7rem;
        margin-top: 0.2rem;
    }

    .about .contact textarea {
        margin: 1.5rem 0 .7rem 0
    }

    .about .contact .send {
        text-align: left;
    }
}

html {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  height: 100%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

img {
  max-width: 100%;
}

main {
  background-color: #ffffff;
  flex: 1 1;
  color: #ffffff;
  text-align: center;
}

a {
  color: #ffffff;
  text-decoration: none;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: 0.85rem;
}

a.active,
a:hover {
  color: #b18f4f;
}

a.secondary {
  color: #b18f4f;
}

a.secondary.active,
a.secondary:hover {
  color: #b18f4f;
}

a.tertiary {
  color: #b18f4f;
  text-transform: none;
  letter-spacing: normal;
  font-size: 1rem;
}

.center {
  margin: 0 auto;
  max-width: 90rem;
}

button,
a.button {
  white-space: nowrap;
  display: inline-block;
  outline: none;
  letter-spacing: 0.1rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  border: 0.05rem solid #b18f4f;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.8rem;
}

button.primary,
a.button.primary {
  background-color: #b18f4f;
  padding: 0.7rem 1.6rem;
}

button.primary.big,
a.button.primary.big {
  background-color: #b18f4f;
  padding: 1.4rem 4.1rem;
  font-size: 1.1rem;
}

button.secondary,
a.button.secondary {
  background-color: transparent;
  padding: 0.7rem 1.6rem;
}

button.secondary.big,
a.button.secondary.big {
  background-color: transparent;
  padding: 1.4rem 4.1rem;
  font-size: 1.1rem;
}

button.text-black,
a.button.text-black {
  color: #000000;
}

button.facebook-btn,
a.button.facebook-btn {
  background-color: #3b5998;
  border: 0.05rem solid #3b5998;
}

button.link {
  color: #b18f4f;
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.padding-vertical-small {
  padding: 2rem 0;
}

.padding-vertical-large {
  padding: 11rem 0;
}

video {
  width: 100%;
}

.error-message {
  color: #b71c1c;
}

.radio---input-radio---2yM_b:checked
  + .radio---control-indicator---dcwXW::before {
  border: 1px solid #b18f4f;
  background-color: #b18f4f;
}

.radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover
  > .radio---control-indicator---dcwXW::before {
  border: 1px solid #b18f4f;
}
.radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover
  > .radio---input-radio---2yM_b:checked
  + .radio---control-indicator---dcwXW::before {
  border: 1px solid #b18f4f;
  background-color: #b18f4f;
}

.standalone-loading {
  color: #000000;
  font-size: 3rem;
  margin: 5rem;
}

.standalone-loading-white {
  color: #ffffff;
  font-size: 3rem;
  margin: 5rem;
}

.Checkbox {
  outline: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
textarea {
  outline: none;
  max-width: 35rem;
  width: 100%;
  padding: 1.3rem 0.5rem;
  border-radius: 0.4rem;
  border: 1px solid #dddddd;
  font-size: 1rem;
  margin: 0.4rem 0;
  font-family: "Roboto", sans-serif;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="url"]:focus,
textarea:focus {
  border: 1px solid #b18f4f;
}

hr.gray {
  margin: 1rem 0;
  border: none;
  height: 1px;
  background-color: #dddddd;
}

hr.double {
  height: 2px;
}

ol.ol-alpha {
  counter-reset: list;
}
ol.ol-alpha > li {
  list-style: none;
}
ol.ol-alpha > li:before {
  content: counter(list, lower-alpha) ") ";
  counter-increment: list;
}

.shadow {
  box-shadow: 0 55px 40px -50px #a9a9a9;
}

.text-left {
  text-align: left;
}

@media screen and (max-width: 786px) {
  .padding-vertical-large {
    padding: 8rem 0;
  }

  .hide-mobile {
    display: none;
  }
}

/* MENU */
header {
    background-color: #1A1A1C;
}

footer {
    background-color: #000000;
    padding-top: 3.5rem;
    padding-bottom: 10rem;
    color: #FFFFFF;
}

.log-in {
    color: #333333;
    background-color: #FFFFFF;
    text-align: left;
    padding: 4.3rem 0;
}

.log-in .center {
    max-width: 35rem;
    padding: 0 1rem;
}

.log-in h1 {
    color: #000000;
    font-size: 1.62rem;
    font-weight: normal;
}

.log-in hr {
    margin: 1rem 0;
    border: none;
    height: 1px;
    background-color: #DDDDDD;
}

.log-in .log-in-div {
    text-align: right;
    padding-top: 1.93rem;
}

.log-in .log-in-div button {
    width: 7rem;
    margin-left: .5rem;
}

.log-in .create-an-account {
    padding: 1rem 0;
}

@media screen and (max-width: 786px) {
    .log-in {
        padding: 2rem 0;
    }
}

nav.desktop-menu {
    display: flex;
    padding: 1rem 3rem .5rem 3rem;
}

nav.desktop-menu > div {
    flex: 1 0;
    align-self: center;
    display: flex;
    padding: .3rem 0;
}

nav.desktop-menu img {
    min-width: 15rem;
}

nav.desktop-menu ul {
    display: flex;
    list-style: none;
    margin: 0 auto;
    padding: 0;
}

nav.desktop-menu ul li {
    padding: 0 .9rem;
}

nav.desktop-menu > div.logo {
    flex: 0 1 15%;
}

nav.desktop-menu > div.menu {
    flex: 0 1 65%;
}

nav.desktop-menu > div.sign-in-header {
    flex: 1 1;
    justify-content: flex-end;
    padding-right: 2rem;
    white-space:nowrap;    
    align-items: center;
}

nav.desktop-menu div.avatar {
    flex: 0 1 5%;
}

nav.desktop-menu div.avatar img {
    width: 3rem;
    min-width: 3rem;
    border-radius: 50%;
}

.game_playpause_container {    
    cursor: pointer;
    bottom: 0px;
    margin-right: 2rem;
    color: #FFF;
}

@media screen and (max-width: 1080px) {
    nav.desktop-menu {
        display: none;
    }
}

.link-wallet {
    color: #000000;
    text-align: left;
    padding: 4.3rem 0;
}

.link-wallet .center {
    max-width: 35rem;
}

.link-wallet h1 {
    font-size: 1.62rem;
    font-weight: normal;
}

.link-wallet p {
    margin: 2rem 0;
}

.wallet-linked {
    margin-top: 4rem;
}

.wallet-linked p {
    margin: 0 auto;
    max-width: 30rem;
}

nav.mobile-menu {
    display: none;
}

nav.mobile-menu .header {
    display: flex;
}

nav.mobile-menu .header > div {
    flex: 1 1;
    padding: 1rem;
    margin: auto;
}

nav.mobile-menu .header > div.bars {
    text-align: right;
    font-size: 2.5rem;
    margin: auto 0;
    padding-right: 1.5rem;
}

nav.mobile-menu .header > div.bars .fa-bars {
    cursor: pointer;
}

nav.mobile-menu .logo img {
    width: 3.3rem;
}

nav.mobile-menu .bars {
    color: #FFFFFF;
}

nav.mobile-menu .menu-dropdown {
    background-color: white;
}

nav.mobile-menu .menu-dropdown.open {    
    max-height: auto;
    transition: max-height 0.45s ease-in;
}

nav.mobile-menu .menu-dropdown.closed {
    max-height: 0;
    transition: max-height 0.45s ease-out;
}

nav.mobile-menu ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    border-bottom: 1px solid #FFFFFF;
}

nav.mobile-menu ul li {
    background-color: #1A1A1C;
    list-style-position:inside;
    border-top: 1px solid #FFFFFF;
    padding: .8rem;
}

@media screen and (max-width: 1080px) {
    nav.mobile-menu {
        display: inline;
        display: initial;
    }   
}

footer > div.footer-desktop {
    display: flex;
    padding: 0 3rem;
}

footer > div.footer-desktop > div {
    flex: 1 1;
}

footer .footer-desktop img {
    width: 15rem;
}

footer .footer-desktop ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer .footer-desktop ul li {
    padding: .5rem 0;
}

footer .footer-desktop div.isologotype {
    flex: 0 0 29%;
}

footer .footer-desktop div.links-left {
    flex: 0 0 15%;
}

footer .footer-desktop div.links-right {
    flex: 0 0 19%;
}

footer .footer-desktop div.copyright {
    font-size: .73rem;
    line-height: 1.3rem;
    color: #666666;
    padding-top: .5rem;
}

footer .footer-desktop div.social {
    display: flex;
    flex: 0 0 15%;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding-top: .5rem;
}

footer .footer-desktop div.social a {
    font-size: 1.15rem;
}

footer .footer-desktop div.social i {
    flex: 0 1;
}

footer .game_stat_music_container {
    display: none;
}

@media screen and (max-width: 786px) {
    footer > div.footer-desktop {
        display: none !important;
    }
}

footer {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

footer > div.footer-mobile {
    display: none;
    padding: 0 2rem;
}

footer > div.footer-mobile > div {
    flex: 1 1;
}

footer .footer-mobile img {
    width: 15rem;
}

footer .footer-mobile ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer .footer-mobile ul li {
    padding: .05rem 0;
}

footer .footer-mobile div.links-left {
    flex: 0 0 34%;
}

footer .footer-mobile div.links-right {
    flex: 0 0 48%;
}

footer .footer-mobile div.links-left a,
footer .footer-mobile div.links-right a {
    font-size: .6rem;
}

footer .footer-mobile div.isologotype {
    margin-top: .2rem;
    flex: 0 0 18%;
    text-align: center;
}

footer .footer-mobile div.isologotype img {
    width: 3.1rem;
}

footer .footer-mobile div.copyright {
    font-size: .5rem;
    line-height: .8rem;
    letter-spacing: .05rem;
    color: #FFFFFF;
    padding-top: .7rem;
    text-align: center;
}

footer .footer-mobile div.social {
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    padding-top: 2rem;
}

footer .footer-mobile div.social a {
    font-size: 1.15rem;
    padding: 0 .5rem;
}

footer .footer-mobile div.social i {
    flex: 0 1;
}

@media screen and (max-width: 786px) {
    footer > div.footer-mobile {
        display: flex;
        flex-wrap: wrap;;
    }
}

.impressum {
    color: #000000;
    max-width: 35rem;
    text-align: left;
    padding: 3rem 0;
}

.impressum h1 {
    font-size: 2.25rem;
}

.impressum a {
    letter-spacing: normal;
    letter-spacing: initial;
    text-transform: none;
    text-transform: initial;
    font-size: medium;
    font-size: initial;
}

@media screen and (max-width: 786px) {
    .impressum {
        padding: 1rem;
    }
}

.terms-of-use {
    color: #000000;
    max-width: 35rem;
    text-align: left;
    padding: 3rem 0;
}

.terms-of-use h1 {
    font-size: 2.25rem;
}

.terms-of-use a {
    letter-spacing: normal;
    letter-spacing: initial;
    text-transform: none;
    text-transform: initial;
    font-size: medium;
    font-size: initial;
}

.terms-of-use ol {
    padding-left: 1rem;
}

.terms-of-use li {
    padding: .7rem 0;
}

@media screen and (max-width: 786px) {
    .terms-of-use {
        padding: 1rem;
    }
}

.privacy-policy {
    color: #000000;
    max-width: 35rem;
    text-align: left;
    padding: 3rem 0;
}

.privacy-policy h1 {
    font-size: 2.25rem;
}

.privacy-policy a {
    letter-spacing: normal;
    letter-spacing: initial;
    text-transform: none;
    text-transform: initial;
    font-size: medium;
    font-size: initial;
}

@media screen and (max-width: 786px) {
    .privacy-policy {
        padding: 1rem;
    }
}

.faq {
    color: #000000;
    max-width: 35rem;
    text-align: left;
    padding: 3rem 0;
}

.faq h1 {
    font-size: 2.25rem;
}

.faq a {
    letter-spacing: normal;
    letter-spacing: initial;
    text-transform: none;
    text-transform: initial;
    font-size: medium;
    font-size: initial;
}

.faq details {
    border-top: 1px solid #cdcdcd;
    padding: .7rem 0;
}

.faq details.last {
    border-bottom: 1px solid #cdcdcd;
}

.faq details,
.faq details summary {
    padding-left:0;
    background-image:none;
    outline:none;
    cursor: pointer;
}
.faq summary::-moz-list-bullet {
    list-style-type: none;
}

.faq details summary::-webkit-details-marker {
    display:none;
}

.faq summary {
    font-weight: 500;
}

.faq details summary i {
    float: right;
    color: grey;
}

.faq details[open] summary i {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

@media screen and (max-width: 580px) {
    .faq {
        padding: 1rem 0;
        margin: 0;
    }

    .faq h1,
    .faq h2 {
        padding: 0.5rem .5rem 0 .5rem;
    }

    .faq details {
        font-size: 1rem;
        padding: .7rem .5rem;
    }
}

.vision-wrapper {
    background-color: #FFFFFF;
    height: 100%;
    padding-bottom: 5rem;
}

.vision {
    color: #000000;
    text-align: left;
    margin: 0 auto;
}

.vision .center {
    max-width: 60rem;
}

.vision .header-image {
    background-image: url('/images/vision/1.jp2');
    background-size: cover;
}

.vision .header-image img {
    width: 100%;
}

.vision h1 {
    margin-top: 12rem;
    font-size: 2.25rem;
}

.vision p {
    font-size: 1.625rem;
    line-height: 2.5rem;
}

.vision .second-image {
    background-image: url('/images/vision/2.jp2');
    background-repeat:   no-repeat;
    background-position: center center;
    background-size: 100%;
    color: #FFFFFF;
    margin-top: 12rem;
    margin-bottom: 14rem;
}

.vision .second-image p {
    padding: 20rem 0;
}

.vision-wrapper hr {
    width: 2rem;
    margin: 0;
    border: none;
    height: 1px;
    background-color: #B18F4F;
}

.vision-wrapper .creation-hub,
.vision-wrapper .professional-work {
    text-align: left;
    margin: 10rem 0;
}

.vision-wrapper .creation-hub p,
.vision-wrapper .professional-work p {
    font-size: 1rem;
    font-weight: lighter;
    line-height: 1.5rem;
    padding-right: 4rem;
}

.vision-wrapper .creation-hub h2,
.vision-wrapper .professional-work h2 {
    font-size: 1.625rem;
    font-weight: normal;
}

.vision-wrapper .creation-hub > div,
.vision-wrapper .professional-work > div {
    color: #000000;
    margin: 0 auto;
    max-width: 60rem;
    display: flex;
}

.vision-wrapper .professional-work > div {
    flex-direction: row-reverse;
}

.vision-wrapper .creation-hub > div > div,
.vision-wrapper .professional-work > div > div {
    flex: 1 0 50%;
    padding-left: 4rem;
}

.vision-wrapper .professional-work > div > div {
    padding-left: 8rem;
}

.vision-wrapper .professional-work > div > div.image {
    padding-left: 3rem;
}

.vision-wrapper .professional-work p {
    padding-right: 1rem;
}

@media screen and (max-width: 1920px) {
    .vision .header-image {
        background-image: url('/images/vision/1-1920.jp2');
    }

    .vision .second-image {
        background-image: url('/images/vision/2-1920.jp2');
    }
}

@media screen and (max-width: 1600px) {
    .vision .second-image {
        background-image: url('/images/vision/2-1600.jp2');
    }
}

@media screen and (max-width: 1280px) {
    .vision .second-image {
        background-image: url('/images/vision/2-1280.jp2');
    }
}

@media screen and (max-width: 1035px) {
    .vision h1,
    .vision p,
    .vision .second-image p {
        padding: 0 2rem;
        line-height: 1.8rem;
    }

    .vision h1 {
        margin: 2rem 0 1rem 0;
    }

    .vision p {
        font-size: 1rem;
        font-weight: lighter;
        line-height: 1.8rem;
    }

    .vision-wrapper .creation-hub,
    .vision-wrapper .professional-work {
        padding: 2rem 2rem 0 2rem;
        margin: 0;
        margin: initial;
    }

    .vision-wrapper .creation-hub > div {
        flex-direction: column-reverse;
        max-width: 35rem;
    }

    .vision-wrapper .professional-work > div {
        flex-direction: column-reverse;
        max-width: 35rem;
    }
    .vision-wrapper .creation-hub > div h1,
    .vision-wrapper .professional-work > div h1 {
        padding-top: 1.5rem;
        font-weight: bold;
        font-size: 1rem;
    }

    .vision-wrapper .creation-hub > div p,
    .vision-wrapper .professional-work > div p {
        font-size: 1rem;
        font-weight: lighter;
        line-height: 1.5rem;
        margin-bottom: 0;
    }

    .vision-wrapper .creation-hub p,
    .vision-wrapper .professional-work p {
        padding-right: 0;
        padding-right: initial;
    }

    .vision-wrapper .creation-hub > div > div,
    .vision-wrapper .professional-work > div > div {
        margin-bottom: 1rem;
        padding-left: 0;
        padding-left: initial;
    }

    .vision-wrapper .professional-work > div > div.image {
        padding-left: 0;
        padding-left: initial;
    }

    .vision .second-image {
        margin: 2rem 0 2rem 0;
        background: none;
        color: #000000;
    }

    .vision-wrapper {
        padding-bottom: 2rem;
    }
}

@media screen and (max-width: 1024px) {
    .vision .header-image {
        background-image: url('/images/vision/1-1024.jp2');
    }
}

@media screen and (max-width: 960px) {
    .vision .header-image {
        background-image: url('/images/vision/1-960.jp2');
    }
}

.view-car {
    background-color: #FFFFFF;
}

.view-car .header {
    color: #000000;
    text-align: left;
    font-size: 1.625rem;
    padding: 2rem 0;
    max-width: 60rem;
    margin: 0 auto;
    display: flex;
}

.view-car .header > * {
    flex: 1 1 50%;
}

.view-car .header > .certificate {
    text-align: right;
    padding-right: 1rem;
}

.view-car .header img {
    height: 2rem;
    cursor: pointer;
}

.view-car .header i {
    font-size: 1rem;
    padding: 0 1rem;
}

.view-car-main-image {
    background: linear-gradient(to bottom, #F0EFEF 0%,#F0EFEF 75%,#F0EFEF 75%,white 75%,white 100%);
}

.view-car-header {
    color: #000000;
    background: #FFFFFF;
    padding-bottom: 7rem;
}

.view-car-header h1 {
    font-size: 10rem;
    padding: 0;
    margin: 0;
}

.sportcoupe .view-car-header h1 {
    font-size: 12rem;
}

.vr2 .view-car-header h1 {
    font-size: 10.5rem;
}

.view-car-header h2 {
    font-size: 2.25rem;
    padding: 3rem 0 4rem 0;
    margin: 0;
    font-weight: 400;
}

.view-car-header hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #999999;
    margin: 1rem auto 3rem auto;
}

.view-car .view-car-header h2 span {
    background-color: #F0EFEF;
    border-radius: 2rem;
    padding: .3rem 2rem;
    font-size: 1.625rem;
    text-transform: uppercase;
    letter-spacing: .23rem;
}

.view-car .button.primary.big {
    margin: 0 1rem;
    width: 20rem;
}

.view-car .button.primary.big.gift {
    background-color: #FFFFFF;
    color: #000000;
}

main .view-car div.split.white {
    padding: 1.9rem 0;
}

.statistics {
    color: #000000;
    background-color: #FFFFFF;
    padding-bottom: 11rem;
}

.statistics h3 {
    margin: 0;
    padding: 0;
    font-size: 4.5rem;
    font-weight: 400;
}

.statistics > div > div {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
}

.statistics > div p.title {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}

.statistics > div p.title .nominator {
    font-size: 9rem;
    line-height: 1 !important;
}

.statistics > div p.title span.view-car {
    position: relative;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
    right: 0 !important;
    color: #B18F4F;
    font-family: "Roboto";
    font-weight: bold;
    padding-left: 5px;
}

.statistics > div div.image-container {
    flex: 0 0 40%;
    text-align: center;
}

.statistics > div .margin-right {
    margin-right: 8rem
}

.statistics > div hr {
    margin: .5rem 0;
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
}

.statistics > div p.description {
    font-weight: 300;
    line-height: 1.5rem;
    padding-bottom: 1.2rem;
    margin: 0;
    color: #000000;
}

.statistics .description-block {
    max-width: 22rem;
    margin-left: 5rem;
}

.view-car > .gallery {
    background-color: #363636;
}

.carousel .slide img {
    width: auto;
    max-width: 100%;
}

.view-car .stats {
    display: flex;
    text-align: center;
}

.view-car .stats .stats-image {
    color: #DDDDDD;
}

.view-car .stats hr {
    background-color: #ACACAC;
    border: none;
    height: 2px;
}

.view-car .stats .stats-image hr {
    background-color: #DDDDDD;
}

.view-car .stats .stats-chart hr {
    margin: .8rem auto 3rem auto;
}

.view-car .stats .title {
    text-transform: uppercase;
    font-size: .6rem;
    letter-spacing: .12rem;
    font-weight: 500;
}

.view-car .stats > div {
    padding: 0 1rem;
}

.view-car .stats hr {
    margin: .8rem auto;
    width: 15rem
}

.view-car .stats .stats-chart .title {
    color: #ACACAC;
}

.view-car .stats img {
    margin-top: 1.5rem;
}

.abyssus .view-car-main-image img {
    max-width: 85%;
}

.view-car .stats .stats-chart .stat-component {
    margin-bottom: 2rem;
}

.lend-container {
    display: none;
}

.badge-container .badge-mobile {
    display: none;
}

/* Lend Modal */

.view-car .modal-back {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}
.view-car .modal {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    position:fixed;
    z-index: 1001;    
    display: block !important; 
}

.view-car .modal {
    color: #000000;
    font-family: roboto, sans-serif;
}

.view-car .modal .modal-dialog {
    max-width: 700px;
    z-index: 1002;  
}

.view-car .modal .modal-header {
    padding: 1rem 1rem 1rem 3rem;
}

.view-car .modal .modal-body {
    display: flex;
    padding: 1rem 3rem;
}

.view-car .modal .modal-body .modal-body-container {
    float: left;
    width: 60%;
    padding-right: 2rem;
}

.view-car .modal .modal-body .modal-body-container > input{
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 1rem;
}

.view-car .modal .modal-body .modal-body-text {
    text-align: left;
    align-self: center;
}

.view-car .modal .modal-body .modal-body-img {
    float: left;
    width: 40%;
    display: grid;
}

.view-car .modal .modal-body .modal-body-img .car-name {
    font-size: 18px;
    font-weight: bold;
    font-family: roboto, sans-serif;
}

.view-car .modal-footer .modal-footer {
    display: flex;
}

.view-car .modal .modal-footer {
    justify-content: space-between;
    padding: 1.2rem 3rem;
}

.view-car .modal .modal-footer .button-container {
    width: 60%;
    text-align: left;
}

.view-car .modal .modal-footer .button-container > button {
    background-color: #B18F4F;
    border-color: #B18F4F;
    font-size: 14px;
    padding-left: 3rem;
    padding-right: 3rem;
}

.view-car .modal .modal-footer .model-number-container {
    width: 40%;
    text-align: center;
}

.view-car .modal .modal-footer .model-number-container .model-number {
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;
    background-color: #F0EFEF;
    max-width: 9.7rem;
    padding: .2rem .4rem;
    border-radius: .5rem;
    letter-spacing: .1rem;
    margin: 0 auto;
}

.required {
    border: 1px solid red !important;
}

.view-car .borrow-mark {
    position: absolute;
    display: flex;
    top: 0.75rem;
    left: 1rem;
}

.view-car .borrow-mark > span{
    margin-left: 1rem;
    color:  #B18F4F;
    font-family: roboto, sans-serif;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.view-car .borrow-mark-overlay-container {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
}

.view-car .borrow-mark-overlay-container .borrow-mark-overlay {
    display: flex;
}

.view-car .borrow-mark-overlay-container .borrow-mark-overlay > span{
    margin-left: 1rem;
    color: #FFFFFF;
    font-family: roboto, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
}

.view-car-header > button {
    background-color: #FF90D3 !important;
    font-family: "Roboto";
    font-size: 18px;
    max-width: 359px;
    width: 80%;
    height: 65px;
    border-radius: 32.5px;
    border: 0px solid #000000;
    transition: background-color .5 ease;
}

.view-car-header > button:hover {
    background-color: #ffb3e0 !important;
}

.view-car-header > button:focus {
    outline: 0;
}

.view-car-header .gold-hr {
    background-color: #B18F4F !important;
}

.view-car .try-luck {
    width: 100%;
    max-width: 54rem;
    margin: auto;
}

.view-car .try-luck p {
    font-size: 1.3rem;
}

/* Lend Modal End */

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .lend-container .lend {
        margin-top: 1.5rem !important;
    } 
}

@media screen and (max-width: 980px) {
    .view-car .statistics .description-block {
        display: none;
    }      
}

@media screen and (max-width: 786px) {
    .view-car-header h2 {
        padding: 3rem 0 2rem 0;
    }

    .view-car .try-luck p {
        padding: 0 3.5rem;
    }

    .view-car-main-image {
        background: linear-gradient(to bottom, #F0EFEF 0%,#F0EFEF 72%,#F0EFEF 72%,white 72%,white 100%);
    }

    .view-car-header {
        padding-bottom: 0;
    }
    .sportcoupe .view-car-header h1,
    .vr2 .view-car-header h1,
    .view-car-header h1 {
        font-size: 2.8rem;
    }

    .view-car-header h2 {
        margin: 1rem 0 2rem 0;
        font-size: 1rem;
    }

    .view-car .view-car-header h2 span {
        font-size: 1.2rem;
    }

    .view-car-header hr {
        width: 2rem;
        margin: .5rem auto 1.7rem auto;
    }

    .view-car-header a.button.primary.big {
        font-weight: normal;
        padding: .9rem 1rem;
        letter-spacing: .17rem;
    }

    /* SPLIT */
    main .view-car div.split.white {
        padding: 0;
    }

    main .view-car div.split div.image-container {
        margin: 3rem 0 2rem 0;
    }

    main .view-car div.split p.title {
        font-size: 1.4rem;
    }

    main .view-car div.split div.description-block,
    main div.split.inverted div.description-block {
        padding: 0 2.2rem;
    }

    main .view-car div.split hr {
        width: 1.7rem;
    }

    main .view-car .statistics h3 {
        margin-top: 2rem;
        font-size: 3.5rem;
    }

    .lend {
        margin-top: 1rem !important;
        padding: 0.90rem 4.1rem !important;
    }

    .badge-container .badge-pc {
        display: none;
    }

    .badge-container .badge-mobile {
        margin-top: 0.5rem;
        display: inline-block;
    }
}

@media screen and (max-width: 718px) {
    .view-car .button.primary.big.gift {
        margin-top: 1rem;
    }        

    .lend-container {
        display: block !important;
    }
}

@media screen and (max-width: 568px) {
    .view-car .stats {
        flex-direction: column;
    }

    .view-car .stats {
        flex: 1 0 100%;
    }

    .view-car .stats hr {
        width: 70%
    }

    .view-car .stats > div.stats-chart {
        margin-top: 2rem;
    }

    .view-car .stats .stats-chart .stat-component {
        padding: 0 2rem;
    }

    .view-car .statistics {
        padding-bottom: 3rem;
    }

    .view-car .button.primary.big {
        margin: 0 1rem;
        width: 17rem;
    }

}

.video-modal-container {
    width: 100%;
    min-height: 100%;
    position: fixed;
    z-index: 998;
}

.video-modal-container .background-modal {
    width: 100%;
    height: 200vh;
    position: absolute;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.6);
}

.video-modal-container .modal-inner-box {
    width: 1366px;
    height: 768px;
    position: absolute;
    z-index: 1000;
    background-color: #000000;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@media screen and (max-width: 1366px) {
   
    .video-modal-container .modal-inner-box {
        width: 1024px;
        height: 600px;
        position: absolute;
        z-index: 1000;
        background-color: #000000;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
    } 
}

@media screen and (max-width: 1024px) {
   
    .video-modal-container .modal-inner-box {
        width: 900px;
        height: 560px;
        position: absolute;
        z-index: 1000;
        background-color: #000000;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
    } 
}

@media screen and (max-width: 870px) {
   
    .video-modal-container .modal-inner-box {
        width: 600px;
        height: 400px;
        position: absolute;
        z-index: 1000;
        background-color: #000000;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
    } 
}

@media screen and (max-width: 620px) {
   
    .video-modal-container .modal-inner-box {
        width: 400px;
        height: 250px;
        position: absolute;
        z-index: 1000;
        background-color: #000000;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
    } 
}
.confirm-email {
    color: #000000;
}

.confirm-email .fa-check-circle {
    font-size: 6rem;
    margin: 4rem 0 2rem 0;
}

.confirm-email p {
    margin-top: 2rem;
}

.confirm-email a {
    margin-top: 2rem;
}

.recover-password {
  color: #000000;
  background-color: #F0EFEF;
  text-align: left;
  height: 100%;
  padding: 0 1rem 5rem 1rem;
}

.recover-password > div {
  max-width: 35rem;
  margin: 0 auto;
}

.recover-password h1 {
  font-size: 2rem;
  font-weight: normal;
  font-weight: initial;
  margin: 0;
  padding: 6rem 0 .5rem 0;
}

.recover-password p {
  letter-spacing: .05rem;
  padding: 1rem 0;
}

.recover-password button {
  margin: 2rem 0;
  padding: 1rem 3rem;
}

.recover-password hr {
  border: none;
  height: .1rem;
  width: 1.5rem;
  background-color: #B18F4F;
  margin: 0;
}

.recover-password button {
  width: 8.75rem;
}

.design-challenge .header {
    background: url('/images/design-challenge-2/header_1920.jpg') no-repeat center center;
    background-size: auto 100%;
    background-color: #000000;
    height: 40rem;
    width: 100vw;
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 3rem;
}

.design-challenge .header > div {
    display: inline-block;
}

.design-challenge .header h1 {
    margin: 0 0 2.5rem 0;
    font-size: 7.5rem;
}

.design-challenge .header h2 {
    margin: .5rem 0;
    font-size: 3rem;
}

.design-challenge h3 {
    font-size: 3rem;
    font-weight: normal;
    margin: 0 0 1rem 0;
}

.design-challenge hr {
    margin: 0 0 1rem 0;
    width: 2.5rem;
    background-color: #B18F4F;
    border: none;
    height: 1px;
}

.design-challenge .header a.button.primary,
.design-challenge .header button.secondary {
    font-size: 1.12rem;
    width: 18.75rem;
    padding: 1.4rem 0;
    margin: 0 .93rem;
    letter-spacing: .2rem;
}

.design-challenge .arrow {
    background-color: #000000;
    height: 3.6rem;
}

.design-challenge .two-column {
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
}

.design-challenge p {
    margin-top: 0;
    color: #333333;
    line-height: 1.65rem;
    letter-spacing: .035rem;
    padding-right: 1rem;
}

.design-challenge .center {
    max-width: 60rem;
}

.design-challenge .about {
    padding: 7rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
}

.design-challenge .design-brief {
    color: #000000;
    background-color: #F0EFEF;
}

.design-challenge .design-brief .center {
    max-width: 90rem;
}

.design-challenge .design-brief h3 {
    font-size: 1.625rem;
}

.design-challenge .design-brief > div {
    display: flex;
}

.design-challenge .design-brief > div > div {
    flex: 1 0 41.5%;
}

.design-challenge .design-brief > div > .brief {
    flex: 1 0 58.5%;
}

.design-challenge .design-brief > div > .brief > div {
    margin: 0 auto;
    max-width: 32.9rem;
}

.design-challenge .design-brief .brief {
    padding: 6.8rem 0 5.5rem 0;
    text-align: left;
}

.design-challenge .design-brief .design-brief-image {
    background: #222222 url('/images/design-challenge-2/design_brief_721.jpg') no-repeat center center;
    background-size: 100% auto;
}

.design-challenge .pioneer-banner {
    background: url('/images/design-challenge-2/pioneer_1920.jpg') no-repeat center center;
    background-color: #8a8a8a;
    height: 36rem;
    width: 100vw;
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 3rem;
}

.design-challenge .pioneer-banner > div {
    display: inline-block;
}

.design-challenge .pioneer-banner h2 {
    font-size: 6rem;
    text-transform: uppercase;
}

.design-challenge .persona {
    padding: 7rem 0 8.8rem 0;
    color: #000000;
    text-align: left;
}

.design-challenge .persona .grey {
    color: #959595;
}

.design-challenge .persona {
    display: flex;
}

.design-challenge .persona > div {
    flex: 1 0 48%;
}

.design-challenge .persona > div p {
    margin-bottom: 2rem;
}

.design-challenge .persona > div img {
    margin: 2rem 0;
}

.design-challenge .persona .grey span {
    color: #000000;
}

.design-challenge .persona-mobile {
    display: none;
}

.design-challenge .persona .persona-right-image {
    flex: 1 0 52%;
    background-image: url('/images/design-challenge-2/persona_2_425.jpg');
    background-size: auto 100%;
    background-repeat:   no-repeat;
    background-position: right center;
}

.design-challenge .environment-banner {
    background-image: url('/images/design-challenge-2/header_1920.jpg');
    background-repeat:   no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    height: 29.75rem;
}

.design-challenge .environment {
    padding: 7rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
    background-color: #F0EFEF;
}

.design-challenge .environment .two-column {
    margin-bottom: 3rem;
}

.design-challenge .environment .images {
    display: flex;
    justify-content: space-between;
}

.design-challenge .environment .images div {
    flex: 0 0 47.8%;
}

.design-challenge .environment .images div img {
    box-shadow: 0 78px 40px -50px #a9a9a9
}

.design-challenge .music {
    padding: 7rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
}

.design-challenge .music {
    display: flex;
}

.design-challenge .music > div {
    flex: 1 0 50%;
    margin: auto;
}

.design-challenge .music .text-left {
    padding-right: 1rem;
}

.design-challenge .music > div img {
    margin: 2rem 0;
}

.design-challenge .music .spotify {
    text-align: right;
}

.design-challenge .spotify-mobile {
    display: none;
}

.design-challenge .color-palette {
    padding: 7rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
    background-color: #F0EFEF;
}

.design-challenge .color-palette .center > div {
    display: flex;
}

.design-challenge .color-palette .center > div > div {
    flex: 1 0 50%;
}

.design-challenge .color-palette .center > div > div.colors {
    padding-left: 2rem;
    align-content: flex-start;
}

.design-challenge .color-palette .colors {
    display: flex;
    flex-wrap: wrap;
}

.design-challenge .color-palette .colors .color-1 {
    background-color: #1D1E20;
    flex: 1 0 21.97%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-2 {
    background-color: #4D4E52;
    flex: 1 0 29.82%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-3 {
    background-color: #747579;
    flex: 1 0 10.98%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-4 {
    background-color: #E3E1E2;
    flex: 1 0 18.60%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-5 {
    background-color: #E2C1B1;
    flex: 1 0 10.76%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-6 {
    background-color: #AE9084;
    flex: 1 0 7.84%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .rgb {
    flex: 1 0 100%;
    text-align: right;
    color: #8a8a8a;
    font-size: .8rem;
    margin-top: .4rem;
}

.design-challenge .take-part {
    padding: 10.5rem 2rem 8.8rem 2rem;
    background-color: #000000;
    text-align: left;
}

.design-challenge .take-part h3 {
    font-size: 1.62rem;
}

.design-challenge .take-part .two-column {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
}

.design-challenge .take-part p {
    color: #FFFFFF;
    padding-right: 0;
}

.design-challenge .take-part .timeline {
    background-image: url('/images/design-challenge-2/time_line_1440.jpg');
    background-repeat:   no-repeat;
    background-position: center center;
    background-size: auto 100%;
    height: 37.25rem;
    margin-top: 7.5rem;
}

.design-challenge .take-part .register {
    text-align: center;
}

.design-challenge .take-part .register a {
    width: 18.75rem;
    padding: 1.4rem 0;
    font-size: 1.12rem;
    margin-top: 8.5rem;
    letter-spacing: .16rem;
}

.design-challenge .prizes-and-rules {
    padding: 10rem 2rem 10rem 2rem;
    color: #000000;
    text-align: left;
    background-color: #F0EFEF;
}


.design-challenge .prizes-and-rules h3 {
    font-size: 1.62rem;
}

.design-challenge .prizes-and-rules p {
    padding-right: 0;
    margin-bottom: 1.5rem;
}

.design-challenge .prizes-and-rules p.last {
    position: absolute;
    bottom: 0;
}

.design-challenge .prizes-and-rules a {
    color: #000000;
    letter-spacing: normal;
    letter-spacing: initial;
    text-decoration: underline;
    text-transform: none;
    text-transform: initial;
    font-size: 1rem;
}

.design-challenge .prizes-and-rules .center > div {
    display: flex;
    justify-content: space-between;
}

.design-challenge .prizes-and-rules .center > div > div {
    flex: 0 1 48.5%;
    position: relative;
    padding-bottom: 3.5rem;
}

.design-challenge .partners-and-sponsors {
    padding: 9rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
}

.design-challenge .partners-and-sponsors h3 {
    font-size: 1.62rem;
}

.design-challenge .partners-and-sponsors p {
    padding-right: 0;
}

.design-challenge .partners-and-sponsors .two-column {
    padding-bottom: 11rem;
    max-height: 25rem;
}

.design-challenge .partners-and-sponsors .logos {
    display: flex;
}

.design-challenge .partners-and-sponsors .logos > div {
    flex: 1 0 2%;
    margin: auto;
    position: relative;
}

.design-challenge .partners-and-sponsors .logos .gravity-sketch,
.design-challenge .partners-and-sponsors .logos .auctionity {
    flex: 1 0 46%;
}

.design-challenge .partners-and-sponsors .logos > div img {
    position: absolute;
    bottom: 0;
}

.design-challenge .partners-and-sponsors .logos > div.auctionity img {
    bottom: 3.9rem;
}

.design-challenge .partners-and-sponsors .logos .gravity-sketch img {
    max-width: 15rem;
}

.design-challenge .powered-by {
    padding: 6rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
}

.design-challenge .powered-by h3 {
    font-size: 1.62rem;
}

.design-challenge .powered-by .logos {
    margin-top: 3rem;
    display: flex;
}

.design-challenge .powered-by .logos > div {
    flex: 1 0 50%;
    margin: auto;
}

.design-challenge .powered-by .logos > div img {
    width: 85%;
}

.design-challenge .powered-by .logos > div.ethereum {
    padding-left: 1rem;
}

.design-challenge .partners-and-sponsors .gravity-sketch-mobile {
    display: none;
}

.design-challenge .join-now {
    background-color: #000000;
    background-image: url('/images/design-challenge-2/bottom_banner_1920.jpg');
    background-repeat:   no-repeat;
    background-position: center center;
    background-size: auto 100%;
    background-attachment: fixed;
    height: 38rem;
    width: 100vw;
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 3rem;
}

.design-challenge .join-now > div {
    display: inline-block;
}

.design-challenge .join-now h2 {
    font-size: 7.5rem;
    margin-bottom: 2.5rem;
}

.design-challenge .join-now a.button.primary.big {
    width: 18.75rem;
    padding: 1.4rem 0;
}

.design-challenge .environment div.environment-image-1-mobile {
    display: none;
}
.design-challenge .prizes-and-rules a.normal-link,
.partners-and-sponsors a {
    color: #B18F4F;
    letter-spacing: normal;
    letter-spacing: initial;
    text-transform: none;
    text-transform: initial;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (max-width: 1490px) {
    .design-challenge .environment-banner {
        background-size: auto 100%;
    }
}

@media screen and (max-width: 1090px) {
    .design-challenge .design-brief > div {
        flex-direction: column-reverse;
    }

    .design-challenge .design-brief .design-brief-image {
        height: 21rem;
        background-size: auto 100%;
    }
}

@media screen and (max-width: 1040px) {
    .design-challenge .prizes-and-rules .center > div {
        flex-direction: column;
    }

    .design-challenge .prizes-and-rules .center > div {}

}

@media screen and (max-width: 892px) {
    .design-challenge .two-column {
        -webkit-column-count: 1;
                column-count: 1;
        grid-column-gap: normal;
        -webkit-column-gap: normal;
                column-gap: normal;
        grid-column-gap: initial;
        -webkit-column-gap: initial;
                column-gap: initial;
    }

    .design-challenge p {
        padding-right: 0;
    }

    .design-challenge .partners-and-sponsors .two-column {
        max-height: none;
        max-height: initial;
    }

    .design-challenge .persona {
        flex-direction: column;
    }

    .design-challenge .persona-desktop {
        display: none;
    }

    .design-challenge .persona-mobile {
        display: block;
        text-align: center;
        padding-bottom: 2rem;
    }

    .design-challenge .persona .persona-right-image {
        height: 43.18rem;
        background-position: center center;
        margin: 4rem 0;
    }

    .design-challenge .about,
    .design-challenge .design-brief .brief,
    .design-challenge .music,
    .design-challenge .prizes-and-rules,
    .design-challenge .partners-and-sponsors {
        padding: 4rem 3rem;
    }

    .design-challenge .environment,
    .design-challenge .take-part {
        padding: 4rem 2.3rem;
    }

    .design-challenge .environment h3,
    .design-challenge .take-part h3,
    .design-challenge .environment p,
    .design-challenge .take-part p {
        padding: 0 .7rem;
    }

    .design-challenge .environment hr,
    .design-challenge .take-part hr {
        margin-left: .7rem;
    }

    .design-challenge .color-palette {
        padding: 4rem 0;
    }

    .design-challenge .color-palette h3,
    .design-challenge .color-palette p {
        padding: 0 3rem;
    }

    .design-challenge .color-palette hr {
        margin-left: 3rem;
    }

    .design-challenge .color-palette .center > div > div.colors {
        margin: 4rem 0 3rem 0;
    }

    .design-challenge .color-palette .center > div > div.colors .rgb {
        padding: .5rem 2rem;
    }

    .design-challenge .persona {
        padding: 3rem 0;
    }

    .design-challenge .persona > div h3,
    .design-challenge .persona > div > div > * {
        padding: 0 2.7rem;
    }

    .design-challenge .persona .persona-mobile {
        padding: 0 1.5rem;
    }

    .design-challenge .persona > div hr {
        margin-left: 3rem;
    }

    .design-challenge .persona > div p {
        margin-bottom: 0;
    }

    .design-challenge .persona > div img {
        margin: 2rem 0 3.5rem 0;
    }

    .design-challenge .music .text-left {
        padding-right: 0;
    }

    .design-challenge .partners-and-sponsors .logos .gravity-sketch {
        display: none;
    }

    .design-challenge .partners-and-sponsors .logos > div {
        flex: initial;
    }

    .design-challenge .partners-and-sponsors .two-column {
        padding-bottom: 8rem;
    }

    .design-challenge .partners-and-sponsors .gravity-sketch-mobile {
        display: block;
        padding: 5rem 1.5rem;
        text-align: center;
    }

    .design-challenge .join-now {
        height: 14rem;
        background-attachment: scroll;
        background-attachment: initial;
    }

    .design-challenge .join-now a.button.primary.big {
        width: 18.45rem;
        padding: 1rem 0;
    }

    .design-challenge .join-now h2 {
        margin-top: 4rem;
        margin-bottom: 1rem;
    }

    .design-challenge .take-part .timeline {
        background-image: url(/images/design-challenge-2/time_line_mobile_373.jpg);
        height: 68.5rem;
        margin-top: 2rem;
    }

    .design-challenge .take-part .register a {
        margin-top: 2rem;
    }

    .design-challenge .environment .images div.environment-image-1 {
        display: none;
    }

    .design-challenge .environment div.environment-image-1-mobile {
        display: block;
        padding: 3rem 0;
        text-align: center;
    }

    .design-challenge .environment .images div.environment-image-2 {
        padding-bottom: 5rem;
        text-align: center;
    }

    .design-challenge .environment .images div {
        flex: 1 0 100%;
    }

    .design-challenge .prizes-and-rules p.last {
        position: static;
        position: initial;
    }

    .design-challenge .powered-by .logos > div.ethereum {
        padding-left: 0;
        padding-left: initial;
    }

    .design-challenge .powered-by .logos > div img {
        width: 100%;
        padding: 1rem 3rem;
    }

    .design-challenge .powered-by {
        padding-bottom: 2rem;
    }

    .design-challenge .partners-and-sponsors .logos > div.auctionity img {
        bottom: 0;
    }
}

@media screen and (max-width: 780px) {
    .design-challenge .header {
        height: 37.8rem;
        background-position: -37rem center;
    }

    .design-challenge .header a.button.primary {
        width: 16.45rem;
        padding: 1rem 0;
        margin-top: 3rem;
        font-size: 1rem;
    }

    .design-challenge .header h1 {
        font-size: 3.68rem;
        line-height: 3.8rem;
    }

    .design-challenge .header h2 {
        font-size: 1.87rem;
        padding: .5rem 0;
        margin-top: 3rem;
    }

    .design-challenge .pioneer-banner {
        height: 15.43rem;
        padding-bottom: 0;
        padding-bottom: initial;
        background-attachment: scroll;
        background-attachment: initial;
        background-size: auto 120%;
    }

    .design-challenge .pioneer-banner h2 {
        font-size: 4.18rem;
    }

    .design-challenge .music {
        flex-direction: column;
    }

    .design-challenge .join-now h2 {
        font-size: 2.37rem;
    }

    .design-challenge .color-palette .center > div {
        flex-direction: column;
    }

    .design-challenge .color-palette .center > div > div.colors {
        padding-left: 0;
    }
}

@media screen and (max-width: 594px) {
    .design-challenge .header {
        background: url('/images/design-challenge-2/header_1920.jpg') no-repeat center center;
    }

    .design-challenge .header button.secondary {
        display: none;
    }

    .design-challenge .arrow {
        display: none;
    }

    .design-challenge .environment-banner {
        background-position: -50rem center;
    }

    .design-challenge .spotify {
        display: none;
    }

    .design-challenge .music > div.spotify-mobile {
        margin-top: 2.5rem;
        display: block;
    }

    .design-challenge .powered-by .logos {
        display: inline;
        display: initial;
    }

    .design-challenge .pioneer-banner {
        background-size: auto 100%;
        background-position-x: -6rem;
    }
}

@media screen and (max-width: 390px) {
    .design-challenge .persona .persona-right-image {
        box-shadow: 0 55px 40px -50px #a9a9a9;
    }
}

.design-challenge-register-success {
  margin: 5rem auto;
  max-width: 35rem;
  padding: 0 2rem;
}

.design-challenge-register-success h1 {
  font-weight: normal;
  font-weight: initial;
}
.design-challenge-register-success .success-message p.text-left {
  line-height: 1.7rem;
}
.design-challenge-register-success .success-message p.first-p {
  margin: 0 auto;
  max-width: 21rem;
}


.design-challenge-register-success a.button.primary {
  padding: 1rem 1.6rem
}

.design-terms {
    text-align: left;
    color: #000000;
    max-width: 42rem;
    margin-top: 5rem;
    margin-bottom: 12rem;
}

.design-terms h1 {
    font-size: 1.87rem;
    margin-bottom: 5rem;
}

.design-terms h2 {
    font-weight: normal;
    font-weight: initial;
}

.design-terms p {
    color: #333333;
    margin-bottom: 2rem;
}

.design-terms a {
    color: #B18F4F;
    text-transform: none;
    text-transform: initial;
    letter-spacing: normal;
    letter-spacing: initial;
    font-size: medium;
    font-size: initial;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
    display: flex;
    margin-left: -1.87rem; /* gutter size offset */
    width: auto;
    max-width: 85rem;
}

.my-masonry-grid_column {
    padding-left: 2.5rem; /* gutter size */
    background-clip: padding-box;
}

.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: transparent;
    margin-bottom: 1.87rem;
    display: inline-block;
}

.design-challenge-card-images {
    font-size: 0;
    position: relative;
}

.modal-design-challenge-vote {
    display: flex;
    flex-direction: row-reverse;
}

.modal-design-challenge-vote img {
    display: block;;
}

.modal-design-challenge-vote .user-info {
    flex: 1 1;
    padding: 0 1rem;
}

.modal-design-challenge-vote .images {
    flex: 0 0 75%;
    min-width: 34rem
}

.modal-design-challenge-vote .user-info h1 {
    font-size: 1.6rem;
    font-weight: normal;
    font-weight: initial;
    margin-bottom: .5rem;
}

.modal-design-challenge-vote .user-info a,
.vote-h3 a {
    color: #000000;
    text-transform: none;
    text-transform: initial;
    letter-spacing: normal;
    letter-spacing: initial;
    outline: 0;
}

.vote-h3 a {
    font-size: 1rem;
}

.modal-design-challenge-vote .user-info > div {
    margin: .2rem 0;
}

.modal-design-challenge-vote .user-info > hr {
    margin: 1.5rem 0;
    border: none;
    height: 1px;
    background-color: #CDCDCD;
}

.modal-design-challenge-vote .user-info a:hover,
.vote-h3 a {
    color: #B18F4F;
}

.modal-design-challenge-vote .fas.fa-heart {
    color: #D0D0D0;
}

.design-challenge-card-images .ribbon {
    position: absolute;
    right: 0;
    width: 45%;
    top: 2rem;
}

@media screen and (max-width: 786px) {
    .modal-design-challenge-vote {
        flex-direction: column;
    }

    .modal-design-challenge-vote .user-info {
        padding: 2rem;
    }

    .modal-design-challenge-vote .images {
        min-width: 20rem;
        min-height: 10rem;
    }
}

@media (min-width: 799px) {
    .notify{
        margin-top: 35px;
        width: 100%;
        height: 70px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #B18F4F;
        font-family: 'Roboto', sans-serif;
    }
    
    div.notify span{
        width: 700px;
        font-size: 1rem;
        font-weight: bold;
        color: black;
        text-align: start;
    }

    .notify-text{
        margin-left: 5px;
    }
    
    .close-notify{
        font-size: 1.4rem;
        color: black;
        cursor: pointer;
    }

}

@media (min-width: 580px) and (max-width: 799px) {
    .notify{
        margin-top: 35px;
        width: 100%;
        height: 70px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #B18F4F;
        font-family: 'Roboto', sans-serif;
    }
    
    div.notify span{
        width: 480px;
        font-size: 0.7rem;
        font-weight: bold;
        color: black;
        text-align: start;
    }

    .notify-text{
        margin-left: 5px;
    }
    
    .close-notify{
        font-size: 1.4rem;
        color: black;
        cursor: pointer;
    }

}

@media (min-width: 393px) and (max-width: 579px) {
    .notify{
        margin-top: 35px;
        width: 100%;
        height: 70px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #B18F4F;
        z-index: 99;
        font-family: 'Roboto', sans-serif;
    }
    
    div.notify span{
        width: 300px;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 0.6rem;
        font-weight: bold;
        color: black;
    }

    .notify-text{
        width: 100%;
        text-align: center;
    }
    
    .close-notify{
        font-size: 1.4rem;
        color: black;
        cursor: pointer;
    }

}

@media screen and (max-width: 392px) {
    .notify{
        margin-top: 35px;
        width: 100%;
        height: 70px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #B18F4F;
        z-index: 99;
        font-family: 'Roboto', sans-serif;
    }
    
    div.notify span{
        width: 200px;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 0.6rem;
        font-weight: bold;
        color: black;
    }

    .notify-text{
        width: 100%;
        text-align: center;
    }
    
    .close-notify{
        font-size: 1.4rem;
        color: black;
        cursor: pointer;
    }
}
.game, 
.game .banner__area,
.game .banner__area .page-area,
.game .banner__area .page-area .single__banner {
  height: 100% !important;
}

.game #thumbs {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.game .left {
  text-align: left;
}

.game .header_area .container, .footer_top_area .container {
  max-width: 1370px;
}

.game .banner__area.mm-t .container {
  max-width: 1245px
}

.game .img {
  max-width: 100%;
  transition: 0.3s;
}

.game a, .game .button, button {
  transition: 0.3s;
}

.game a:focus, .game .button:focus, .game button:focus {
  text-decoration: none;
  outline: none;
}

.game a:focus {
  text-decoration: none;
}

.game a:focus, 
.game a:hover, 
.game .portfolio-cat a:hover, 
.game .footer -menu li a:hover {
  text-decoration: none;
}

.game input:focus, .game button:focus {
  outline: none;
}

.game a, .game button {
  color: #fff;
  outline: medium none;
}

.game h1, 
.game h2, 
.game h3, 
.game h4, 
.game h5 {
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
}

.game h1 a, 
.game h2 a, 
.game h3 a, 
.game h4 a, 
.game h5 a, 
.game h6 a {
  color: inherit;
}

.game ul {
  margin: 0px;
  padding: 0px;
}

.game li {
  list-style: none;
}

.game p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #505050;
  margin-bottom: 13px;
  font-family: "Roboto", sans-serif;
}

.game label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

::selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

*::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

*::-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

*::placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

.user-data .user-data-wrapper {
  display: flex;
  padding-left: 50px;
  max-width: 95%;  
  width: 100%;
}

.user-data .user-details {
  display: flex;
  align-items: flex-end;
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding: 0 15px;
  letter-spacing: 0.144em;
  text-overflow: ellipsis;  
}

.user-data .points {  
  margin-left: 10px;
  font-size: 14px;
  color: #B18F4F;
}

.user-data .score{
  color: #B18F4F;
  margin-left: 50px;
  font-size: 14px;
}

.user-data .user-details .last-race {  
  font-size: 14px;  
  margin-left: auto;
}

.user-data .user-details .last-race .last-race-link {
  text-transform: lowercase;
  color: #B18F4F !important;
}

.game .owl-carousel .owl-nav div {
  left: 0px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #fff;
  left: 0;
}

.game .owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 0px;
}

.game .owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.game .owl-carousel:hover .owl-nav div:hover {
  color: #B18F4F;
}

.game .mb-85 {
  margin-bottom: 85px;
}

.game .mo-footer .share-twitter {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  font-family: roboto, sans-serif;
}

@media (max-width: 767px) {
  .game .mb-85 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .mb-85 {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .mb-85 {
    margin-bottom: 40px;
  }
}

.game .mb-65 {
  margin-bottom: 65px;
}

@media (max-width: 767px) {
  .game .mb-65 {
    margin-bottom: 20px;
  }
}

.game .mt-80 {
  margin-top: 80px;
}

.game .mb-40 {
  margin-bottom: 40px !important;
}

.game .home_2 #back-top a {
  background: #4543de;
  box-shadow: 0px 10px 20px 0px rgba(69, 67, 222, 0.4);
}

.game #back-top {
  position: fixed;
  right: 20px;
  bottom: 30px;
  cursor: pointer;
  z-index: 9999999;
  display: none;
}

.game #back-top a {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  color: #fff;
  line-height: 40px;
  background: #ff4357;
  font-size: 15px;
  border-radius: 30px;
  transition: 0.3s;
  box-shadow: 0px 10px 20px 0px rgba(255, 67, 87, 0.4);
}

.race_again {
  display: block !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  color: #fff !important;  
  margin: 1.5rem auto;
  padding: 10px 30px !important;
}

.race_again:hover {
  color: #B18F4F !important;
  border-color: #B18F4F;
  background: transparent;  
}

.game .boxed_btn {
  background: #B18F4F;
  border-radius: 4px;
  display: inline-block;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 15px 38px;
  border: 1px solid transparent;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .game .boxed_btn {
    padding: 12px 30px;
  }
}

.game .boxed_btn:hover {
  color: #B18F4F;
  border-color: #B18F4F;
  background: transparent;
}

.game .boxed_btn_2 {
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  color: #B18F4F;
  font-weight: 600;
  padding: 12px 40px;
  border: 1px solid #B18F4F;
}

@media (max-width: 767px) {
  .game .boxed_btn_2 {
    padding: 9px 20px;
  }
}

.game .boxed_btn_2:hover {
  color: #fff;
  background: #B18F4F;
}

.game .line_btn {
  font-size: 16px;
  color: #fff;
  padding: 12px 41px;
  border: 1px solid #fff;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 767px) {
  .game .line_btn {
    padding: 12px 30px;
  }
}

.game .line_btn:hover {
  background: #fff;
  color: #000;
}

.game .blank_btn {
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 12px 65px;
  display: inline-block;
  transition: 0.3s;
  font-weight: 600;
}

@media (max-width: 767px) {
  .game .blank_btn {
    padding: 12px 30px;
  }
}

.game .blank_btn:hover {
  color: #000;
  background: #fff;
}

.game .boxed_btn3 {
  background: #4543de;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  padding: 12px 40px;
  transition: 0.3s;
  border: 1px solid transparent;
}

@media (max-width: 767px) {
  .game .boxed_btn3 {
    padding: 12px 30px;
  }
}

.game .boxed_btn3:hover {
  color: #4543de;
  border-color: #4543de;
  background: transparent;
}

.game .button-music-helper {
  display: none;
}

/*----------------------------------------------------*/

.game .section_title.white_text h3 {
  color: #fff;
}

.game .section_title span {
  font-size: 16px;
  color: #8f8f8f;
  font-weight: 700;
  display: block;
  line-height: 16px;
  margin-bottom: 21px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title span {
    font-size: 14px;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .game .section_title span {
    font-size: 14px;
    margin-top: 10px;
  }
}

.game .section_title h3 {
  font-size: 42px;
  font-weight: 700;
  line-height: 54px;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title h3 {
    font-size: 38px;
    line-height: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title h3 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .game .section_title h3 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

.game .section_title h4 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.35;
  color: #1c1c1c;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title h4 {
    font-size: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title h4 {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .game .section_title h4 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title h4 {
    font-size: 26px;
  }
}

.game .section_title.section_title2 h3 {
  margin-bottom: 18px;
  margin-bottom: 0;
  font-weight: 400;
}

.game .section_title.section_title2 p {
  line-height: 1.67;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 8px;
  color: #505050;
}

@media (max-width: 767px) {
  .game .section_title.section_title2 p br {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title.section_title2 p br {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title.section_title2 p br {
    display: none;
  }
}

.game .section_title_2 h3 {
  font-size: 42px;
  font-weight: 400;
  line-height: 1.29;
}

.game .section_title_2 h3 span {
  color: #4543de;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title_2 h3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_2 h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .game .section_title_2 h3 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_2 h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

.game .section_title_3 h3 {
  font-size: 42px;
  font-weight: 400;
  line-height: 1.29;
}

.game .section_title_3 h3 span {
  color: #4543de;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title_3 h3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_3 h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .game .section_title_3 h3 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_3 h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

.game .section_title_3 p {
  font-size: 20px;
  line-height: 1.6;
  color: #505050;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .game .section_title_3 p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_3 p br {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_3 p br {
    display: none;
  }
}

.game .section_title_4 h3 {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.29;
  color: #0B0B21;
}

.game .section_title_4 h3 span {
  color: #4543de;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title_4 h3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_4 h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .game .section_title_4 h3 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_4 h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

.game .section_title_4 p {
  font-size: 18px;
  line-height: 1.6;
  color: #505050;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .game .section_title_4 p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_4 p br {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_4 p br {
    display: none;
  }
}

.game .pl-80 {
  padding-left: 80px;
}

@media (max-width: 767px) {
  .game .pl-80 {
    padding-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .pl-80 {
    padding-left: 30px;
  }
}

.game .pl-100 {
  padding-left: 100px;
}

@media (max-width: 767px) {
  .game .pl-100 {
    padding-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .pl-100 {
    padding-left: 30px;
  }
}

.game .mb-90 {
  margin-bottom: 82px;
}

@media (max-width: 767px) {
  .game .mb-90 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .mb-90 {
    margin-bottom: 50px;
  }
}

.game .pl-65 {
  padding-left: 65px;
}

@media (max-width: 767px) {
  .game .pl-65 {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .pl-65 {
    padding-left: 0px;
  }
}

.game .pb-180 {
  padding-bottom: 180px;
}

@media (max-width: 767px) {
  .game .pb-180 {
    padding-bottom: 80px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .pb-180 {
    padding-bottom: 100px;
  }
}

.game .mb-30 {
  margin-bottom: 30px;
}

.game .header_area {
  padding: 0px 35px 0 35px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #1A1A1D;
}

.game .header_area.header_static {
  position: relative;
  padding: 0px 35px 0 35px;
}

@media (max-width: 767px) {
  .game .header_area.header_static {
    padding: 10px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .header_area.header_static {
    padding: 10px 0px;
  }
}

@media (max-width: 767px) {
  .game .header_area {
    padding: 10px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .header_area {
    padding: 10px 0px;
  }
}

.game .header_area .header__wrapper {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}

.game .header_area .header__wrapper .header__left {
  flex-basis: 30%;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .header__wrapper .header__left {
    flex-basis: 20%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .header__wrapper .header__left {
    flex-basis: 30%;
  }
}

.game .header_area .header__wrapper .header__right {
  flex-basis: 30%;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .header__wrapper .header__right {
    flex-basis: 20%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .header__wrapper .header__right {
    flex-basis: 30%;
  }
}

.game .header_area .header__wrapper .header__right span {
  color: #B18F4F !important;
  font-size: 15px;
  font-weight: 400;
  margin-right: 30px;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .header__wrapper .header__right {
    flex-basis: 25%;
  }
}

.game .header_area .header__wrapper .header__right .thumb {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.game .header_area .header__wrapper .header__right .thumb img {
  width: 100%;
}

.game .header_area .main_menu ul li {
  display: inline-block;
  position: relative;
}

.game .header_area .main_menu ul li a {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  margin: 0 26px;
  padding: 30px 0;
  display: inline-block;
  text-transform: uppercase;
}

.game .header_area .main_menu ul li a:hover, 
.game .header_area .main_menu ul li.active a {
  color: #B18F4F;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .main_menu ul li a {
    font-size: 14px;
    margin: 0 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .main_menu ul li a {
    margin: 0 15px;
  }
}

.game .header_area .main_menu ul li .submenu {
  width: 200px;
  position: absolute;
  left: 0;
  top: 120%;
  background: #fff;
  text-align: left;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.game .header_area .main_menu ul li .submenu li {
  display: block;
  margin: 0;
}

.game .header_area .main_menu ul li .submenu li a {
  font-size: 15px;
  color: #505050;
  padding: 0;
  font-weight: 400;
  display: block;
  margin: 0;
  padding: 11px 20px;
  transition: 0.3s;
  position: relative;
  text-transform: uppercase;
  left: 0;
}

.game .header_area .main_menu ul li .submenu li a:hover {
  left: 5px;
  color: #B18F4F;
}

.game .header_area .main_menu ul li:hover>a {
  color: #B18F4F;
}

.game .header_area .main_menu ul li:hover .submenu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.game .header_area .contact_wrap {
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .game .header_area .contact_wrap {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .header_area .contact_wrap {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .header_area .contact_wrap {
    display: none !important;
  }
}

.game .header_area .contact_wrap .mobile_no p {
  color: #505050;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
}

.game .header_area .contact_wrap .mobile_no p span a {
  color: #B18F4F;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .contact_wrap .mobile_no p {
    font-size: 13px;
    margin-right: 8px;
  }
}

.game .header_area .contact_wrap .signin a.sign_in {
  font-size: 16px;
  color: #505050;
  font-weight: 600;
  margin-right: 40px;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .contact_wrap .signin a.sign_in {
    margin-right: 15px;
  }
}

.game .header_area .contact_wrap .signin a.sign_in:hover {
  color: #4543DE;
}

.game .header_area .contact_wrap .contact_btn {
  margin-left: 48px;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .contact_wrap .contact_btn {
    margin-left: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .contact_wrap .contact_btn {
    margin-left: 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .contact_wrap .contact_btn .boxed_btn {
    padding: 12px 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .main_menu ul li a {
    margin: 0 22px;
  }
}

.game .header_area.header_area2 .main_menu ul li a:hover {
  color: #4543de;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .boxed_btn3 {
    font-size: 14px;
    padding: 12px 13px;
  }
}

.game .header_area.header_area2 .main_menu ul li:hover>a {
  color: #4543de;
}

.game .header_area.header_area2 .header__wrapper .header__left {
  flex-basis: 15%;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .header__wrapper .header__left {
    flex-basis: 15%;
  }
}

.game .header_area.header_area2 .header__wrapper .main_menu {
  flex-basis: 50%;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .header__wrapper .main_menu {
    flex-basis: 60%;
  }
}

.game .header_area.header_area2 .header__wrapper .header__right {
  flex-basis: 35%;
  display: none span;
  display-color: #fff !important;
  display-font-size: 13px;
  display-font-weight: 400;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .header__wrapper .header__right {
    flex-basis: 25%;
  }
}

/*----------------------------------------------------*/

.game .slicknav_menu .slicknav_icon {
  margin-right: 6px;
  margin-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 0px;
}

.game .slicknav_nav a {
  font-size: 14px;
  color: #000;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-weight: 400;
}

.game .slicknav_nav .slicknav_row, .slicknav_nav a {
  padding: 9.5px 15px;
  margin: 0;
}

.game .slicknav_arrow {
  position: absolute;
  right: 0;
  width: 40px;
  height: 37px;
  text-align: center;
  line-height: 40px;
  top: 0;
}

.game .slicknav_nav li {
  display: block;
  border-bottom: none;
  position: relative;
}

.game .mobile_menu .slicknav_nav ul.submenu {
  list-style: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: #f7f7f7;
}

.game .mobile_menu .slicknav_nav ul.submenu li a {
  font-size: 16px;
}

.game .mobile_menu .slicknav_nav .slicknav_arrow {
  margin: 0;
}

.game .slicknav_nav .slicknav_row:hover {
  color: #B18F4F;
}

.game .slicknav_nav a:hover {
  border-radius: 0px;
  background: transparent;
  color: #B18F4F;
}

.game .slicknav_menu .slicknav_icon-bar {
  background-color: #363e51;
  height: 2px;
  margin: 5px 0;
  transition: all 0.3s ease 0s;
  width: 25px;
  position: relative;
}

.game .slicknav_menu .slicknav_icon {
  margin-right: 0px;
}

.game .mobile_menu {
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 9999999;
}

.game .slicknav_btn {
  right: 24px;
}

.game .slicknav_nav {
  background: #1a1a1d none repeat scroll 0 0;
  border-top: 1px solid #fff;
  box-shadow: 0 5px 7px 0 rgba(13, 0, 36, 0.07);
  margin-top: 12px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.game .slicknav_nav .slicknav_row, .game .slicknav_nav a {
  padding: 6.5px 20px;
  margin: 0;
}

.game .slicknav_nav .slicknav_row, .game .slicknav_nav a {
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 10px 20px;
}

/*----------------------------------------------------*/

.game .slicknav_btn {
  padding: 0px;
  border: 0px;
}

.game .slicknav_btn .slicknav_icon-bar {
  background-color: #B18F4F;
  transition: all .5s linear;
  display: block;
  width: 22px;
  height: 2px;
}

.game .home_2 .slicknav_btn .slicknav_icon-bar {
  background-color: #4543de;
}

.game .slicknav_btn .slicknav_icon-bar+.slicknav_icon-bar {
  margin-top: 5px;
}

.game .slicknav_btn .slicknav_icon-bar:nth-child(2) {
  transform: rotate(0deg);
}

.game .slicknav_btn.slicknav_open .slicknav_icon-bar:nth-child(2) {
  transform: rotate(-45deg);
}

.game .slicknav_btn .slicknav_icon-bar:nth-child(3) {
  opacity: 1;
}

.game .slicknav_btn.slicknav_open .slicknav_icon-bar:nth-child(3) {
  opacity: 0;
}

.game .slicknav_btn .slicknav_icon-bar:nth-child(1) {
  transform: rotate(0deg);
  top: 0;
}

.game .slicknav_btn.slicknav_open .slicknav_icon-bar:nth-child(1) {
  transform: rotate(45deg);
  top: 7px;
  position: relative;
}

/*----------------------------------------------------*/

/*----------------------------------------------------*/

.game .banner_bg {
  background-image: url(/game-images/banner.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: calc(100vh - 328px);
}

.game .single__banner.banner_bg.single__banner2.single__banner3 {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1
}

.game .single__banner.banner_bg.single__banner2.single__banner3::after {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.game .banner__area .single__banner {
  min-height: 900px;
  height: calc(100vh - 248px);
  position: relative
}

@media (min-width: 1280px) and (max-width: 1440px)  {
  .game .all-heading {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px !important;
  }
  .game .track-page .all-heading {
    margin-bottom: 10px !important;
  }
  .boxed_btn {
    margin-top: 0 !important;
  }
  .game .starts_wrap {
    margin-top: 0 !important;
  }
  /*Select Car*/  
  .game .banner__area.mm-t .single__banner .banner_content {
    padding-top: 30px !important;    
  }
  .game .banner__area .single__banner .banner_content .big_car_thumb h4 {
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px !important;
  }
  .game .banner__area .single__banner {
    min-height: 800px;
    height: calc(100vh - 248px);
    position: relative;
  }
  .race_btn {
    margin-top: 0.5rem !important;
  }
  /* Summary*/
  .game .race-summary-page-area .all-heading {
    margin-bottom: 0px;
  }
  .game .race-summary-page-area .starts_wrap.wi-300 {
    margin-top: 50px !important;
  }
}
@media (min-width: 1441px) and (max-width: 1920px)  {

  .game .page-area {
    height: 100%;
  }
  .game .banner__area .single__banner.summary-h {  
    height: calc(100vh - 248px);  
  }
  .game .banner__area .single__banner {
    min-height: 900px;
    height: calc(100vh - 248px);
    position: relative
  }
  /* Select car */
  .game .banner__area.mm-t .single__banner .banner_content {
    padding-top: 40px !important;    
  }
  .big_car_thumb .race_btn {
    margin-top: 0 !important;
  }
  #select-car {
    min-height: 750px;
  }
  /* Track */
  .game .track-page .all-heading {
    margin-bottom: 40px !important;
    margin-top: 40px;
  }
  .single__banner .banner_bg .single__banner2 .bann-800 {
    padding-bottom: 100px;
    height: 100%;
  }
  .game .container .wi-1245 {
    width: 1245px;
  }
  .game .container {
    max-width: 1245px;
  }
  .game .starts_wrap{
    margin-top: 0px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  /*Race Start*/
  .game .mt-100 {
    margin-top: 50px !important;
  }
  /* Summary*/
  .game .race-summary-page-area .all-heading {
    margin-bottom: 50px;
  }
  .game .race-summary-page-area .starts_wrap.wi-300 {
    margin-top: 50px !important;
  }
}


.game .banner__area .single__banner.summary-h {  
  height: auto;  
}

@media (min-width: 1921px) and (max-width: 2560px)  {
  /* Select car */
  #select-car {
    padding-top: 5rem;
  }
  .game .banner__area .single__banner.summary-h {  
    height: calc(100vh - 248px);  
  }
  /* Select track */
  .game .banner__area .single__banner {
    padding-top: 5rem;
    min-height: 900px;
    height: calc(100vh - 248px);
    position: relative
  }
  /* Race Start */
  .game .race-start {
    padding-top: 5rem;
  }
  .game .starts_wrap {
    margin-top: 0 !important;
  }
  .game .mt-120 {
    margin-top: 170px !important;
  }
  /* Summary*/
  .game .race-summary-page-area .all-heading {
    margin-bottom: 50px !important;
  }
  .game .race-summary-page-area .starts_wrap.wi-300 {
    margin-bottom: 50px !important;
  }
}

.game .banner__area.mm-t .single__banner {
  height: calc(100vh - 373px);
}

@media (max-width: 767px) {
  .game .banner__area .single__banner.single__banner2 .banner_content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner.single__banner2 .banner_content {
    padding-bottom: 50px;
  }
}

.game .car_txt {
  position: absolute;
  left: 19%;
  top: 20%;
}

.game .car_txt p {
  margin-bottom: 0;
  color: #fff;
  font-size: 25px;
  font-weight: 400;
}

.game .car_txt p span {
  display: inline-block;
  color: #B18F4F;
  margin-left: 10px;
  font-size: 20px
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .car_txt {
    background: #1A1A1D;
    background: #1A1A1D;
    padding: 7px 30px;
    border-radius: 50px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}

@media (max-width: 767px) {
  .game .mm-t .car_txt {
    background: #1A1A1D;
    background: #1A1A1D;
    padding: 7px 30px;
    border-radius: 50px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    margin-top: 30px;
    top: 50px !important;
  }
}

.game .banner__area .single__banner .banner_content {
  padding-top: 170px;
  display: grid;
  grid-template-columns: 20% 60% 20%;
}

.game .banner__area.mm-t .single__banner .banner_content {
  padding-top: 70px;
  position: relative;
}

.game .banner_content .single_content {
  margin-top: 33px;
  text-align: left;
}

.game .banner_content .single_content-right {
  margin-top: 80px;
}

.game .banner_content .prev_button_content {
  margin-bottom: 10px;
}

.game .banner_content .prev_button_content > span {
  color: #B18F4F;
  cursor: pointer;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content {
    grid-template-columns: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content {
    grid-template-columns: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
  }
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .single_content {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .single_content {
    width: 70%;
    margin: auto;
  }
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb {
  position: relative;
  margin-bottom: 30px;
  margin-top: 50px;
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb .thumb img {
  width: 100%;
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb .speed_info {
  position: absolute;
  top: 8px;
  left: 7px;
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb .speed_info h4 {
  font-size: 8px;
  font-weight: bold;
  margin-bottom: 0;
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb .speed_info h5 {
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 0;
}

.game .banner__area .single__banner .banner_content .single_content .left_content_header h4 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .single_content .left_content_header h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .single_content .left_content_header h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}

.game .banner__area .single__banner .banner_content .single_content .left_content_header span {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .single_content .left_content_header span {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .single_content .left_content_header span {
    display: none;
  }
}

.game .banner__area .single__banner .banner_content .single_content h1 {
  font-size: 96px;
  line-height: 1;
  color: #fff;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 26px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .single_content h1 {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .single_content h1 {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .starts_wrap .bordered_title {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .big_car_thumb {
    text-align: center;
    padding: 0 20px;
  }
}

.game .starts_wrap .bordered_title h4 {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  border-bottom: 1px solid #B18F4F;
  padding-bottom: 6px;
  margin-bottom: 13px;
  text-align: left;
}

@media (max-width: 767px) {
  .game .starts_wrap .bordered_title h4 {
    display: inline-block;
    padding: 0 20px 8px 20px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .starts_wrap .bordered_title h4 {
    display: inline-block;
    padding: 0 20px 8px 20px;
    text-align: center;
  }
}

.game .starts_wrap .lines .single_line span.line_wrap {
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  line-height: 26px;
  letter-spacing: 1px;
}

.game .banner__area .single__banner .banner_content .big_car_thumb {
  text-align: center;
  padding: 0 30px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .big_car_thumb {
    position: relative;
    margin-top: 120px;
  }
}

.game .banner__area .single__banner .banner_content .big_car_thumb h4 {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 70px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .big_car_thumb h4 {
    position: absolute;
    font-size: 30px;
    margin-top: 30px;
    width: 300px;
    margin: 0px auto 30px auto;
  }
}

.banner__area .single__banner .banner_content .big_car_thumb .thumb img {
  width: 100%;
}

.game .slider_controller_wrap {
  background: #1A1A1C;
  padding: 25px 0;
}

.game .slider_controller_wrap.slider_controller_wrap2 {
  padding-top: 120px;
  background: transparent;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .slider_controller_wrap.slider_controller_wrap2 {
    display: none;
  }
}

.game .slider_controller_wrap .owl-carousel .thumb_info {
  text-align: center;
}

.game .slider_controller_wrap .owl-carousel .item-carousel {
  padding: 0;
  max-width: 100%;
  width: 100%;
  margin: 0;
}

.game .slider_controller_wrap .owl-carousel .item-carousel .left-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-color: transparent;
  border-left: transparent;
  border-right: 10px solid rgba(114, 114, 114, 0.5);
}

.game .slider_controller_wrap .owl-carousel .item-carousel .right-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-color: transparent;
  border-right: transparent;
  border-left: 10px solid rgba(114, 114, 114, 0.5);
}

.game .slider_controller_wrap .owl-carousel .thumb_info h4 {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}

.game .slider_controller_wrap .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.game .boxed_btn.boxed_btn2 {
  display: block;
  margin-top: 40px;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .game #big {
    width: 300px;
    margin: auto;
  }
}

@media (max-width: 767px) {
  .game #big {
    width: 280px;
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .big_car_thumb {
    margin: auto;
  }
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .big_car_thumb {
    position: relative;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
}

/*----------------------------------------------------*/

/*# sourceMappingURL=style.css.map */

.game .banner_content .starts_wrap {
  margin-top: 30px;
}

/*=============================== Style Css ======================================*/

.game .pt-100 {
  padding-top: 60px;
}

.game .pb-180 {
  padding-bottom: 180px;
}

.game .container.gm-page-w {
  max-width: 1140px;
}

.game .all-car-text.car_txt {
  position: inherit;
  left: 0;
  top: 0;
}

.game .game-page .all-car-text.car_txt {
  left: 90px;
}

.game .all-heading {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 50px;
}

.game .pt-middle {
  padding-top: 10rem;
}

.game .all-heading h2 {
  font-size: 36px;
  color: #fff;
  font-weight: 600;
}

.game .start-flex {
  display: flex;
  justify-content: space-between;
}

.game .starts_wrap {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.game .bor-titel-max h4 {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  padding-bottom: 6px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  display: inline-block;
}

.game .bor-titel-max h4::after {
  height: 1px;
  background: #B18F4F;
  width: 100%;
  bottom: 0;
  left: 0;
  content: "";
  position: absolute;
}

.game .start-game img {
  margin-left: -17px;
}

.game .start-game a {
  display: block;
}

.game .mo-footer {
  padding: 70px 0;
}

.game .mo-footer .box-container {  
  width: -webkit-fit-content;  
  width: -moz-fit-content;  
  width: fit-content;  
  padding: 1rem 3rem;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.game .mo-footer .box-container .race-again-btn {
  font-family: roboto, sans-serif;
  margin-left: 5rem;
}

.game .mo-footer .points-obtained {
  color: #B18F4F;
  font-family: roboto, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.144em;
}

.game .mo-footer p {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;  
}

.game .mo-footer a {
  color: #B18F4F;
  text-transform: none;
  text-transform: initial;
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
}

.game .mo-footer span {
  color: #B18F4F;
  text-transform: none;
  text-transform: initial;
  font-size: 24px;
  cursor: pointer;
}
.tooltip-container{
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  color: #FFFFFF;
  font-weight: bold;
  background-color: #B18F4F;
  border: 1px solid #B18F4F;
}
.tooltip-container .tooltip-arrow[data-placement*='right']::after {
  border-right-color: #B18F4F !important;
}
.tooltip-arrow[data-placement*='bottom']::after {
  border-color: transparent transparent #B18F4F transparent;
}
.tooltip-container .tooltip-arrow[data-placement*='right']::before {
  border-right-color: #B18F4F !important;
}
.tooltip-container .tooltip-arrow[data-placement*='top']::after {
  border-top-color: #B18F4F !important;
}
.tooltip-container .tooltip-arrow[data-placement*='top']::before {
  border-top-color: #B18F4F !important;
}

@media screen and (max-width: 991px) {
  .game .monthly-page .single_line.bor-flex.d-flex.justify-content-between span {
    font-size: 16px;
  }
  .game .mo-footer p {
    font-size: 16px;
  }
  .game .starts_wrap.wi-600 {
    margin: 30px auto;
  }
  .game .pt-middle {
    padding-top: 8rem;
  }

}

.game .bor-titel-max {
  text-align: center;
}

/*======================Track page===================*/

@media screen and (max-width: 991px) {
  .game .banner__area .single__banner {
    min-height: 1110px;
  }

  #select-car {
    min-height: 850px;
  }

  .game .select-box::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .game .select-box::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  
  .game .select-box::-webkit-scrollbar-thumb {
    background-color: rgba(114, 114, 114, 0.5);
    border-radius: 10px;
  }

  .user-data .user-data-wrapper {
    display: flex;
    justify-content: space-around;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #212529;
    border-radius: 50px;
    padding: 10px 15px 5px 15px; 
    margin: 0 auto;   
    margin-top: 30px; 
  }

  .user-data .user-data-wrapper .divider {    
    border-left: 2px solid #000;
    height: 100%;
    margin-left: 1rem;    
  }

  .user-data .user-data-wrapper .divider::before {
    content: "\200D";
    display:inline;
  }  

  .user-data .user-data-wrapper .icons {
    color: #B18F4F;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .user-data .user-data-wrapper .controls{
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 1rem;
  }

  .user-data .user-details {        
    text-align: center;    
    font-size: 24px; 
    height: -webkit-fit-content; 
    height: -moz-fit-content; 
    height: fit-content;
    margin-left: 1rem;  
  }
  .user-data .user-details .username {
    max-width: 136px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .user-data .points {
    margin-left: 1rem;
    font-size: 20px;
    color: #B18F4F;
  }

  .user-data .score{
    margin-left: 50px;
    font-size: 18px;
  }

}

.game .container.wi-1000 {
  max-width: 1245px;
}

.game .starts_wrap.wi-290 {
  margin: 0 auto;
  max-width: 290px;
}

.game .select-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.game .select-box-item {
  background: #fff none repeat scroll 0 0;
  margin-top: 30px;
  width: 30%;
  height: 130px;
}

.game .select-box-item.gm-active {
  background: #b18f4f none repeat scroll 0 0;
}

.game .select-box-item {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  cursor: pointer;
  display: flex;
  flex-flow: column;
}

.game .select-box-item.lk-game::after {
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e1e1e+1,383838+42,383838+60,383838+60,1e1e1e+100 */
  background: #1e1e1e;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #1e1e1e 1%, #383838 42%, #383838 60%, #383838 60%, #1e1e1e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e1e1e', endColorstr='#1e1e1e', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.game .select-box-img {
  width: 100%;
  height: 70px;
  padding: 0 10px 10px 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.game .select-box-img img {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
}

.game .select-box-items {
  padding: 10px;
  text-align: left;
  height: 40px;
}

.game .starts_wrap.wi-310 {
  max-width: 374px;
  margin: 10px auto;
}

.game .select-box-items h5, 
.game .select-box-items h6, 
.game .misano-content h5, 
.game .misano-content h6 {
  color: #fff;
  line-height: 1;
}

.game .misano-img img {
  width: 100%;
}

.game .select-box-items>h5 {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 2px;
}

.game .select-box-items>h6 {
  font-size: 10px;
  font-weight: 400;
}

.game .select-box-item p {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

@media screen and (max-width: 991px) {
  .game .starts_wrap.wi-290 {
    margin: 20px auto 100px;
    text-align: center;
  }
  .game .starts_wrap.wi-310 {
    max-width: 300px;
    margin: 20px auto 60px;
    text-align: center;
  }
}

/* =================== Misano-area ======================  */

.game .misano-dinan-box-area {
  margin-top: 30px;
}

.game .misano-items {
  position: relative;
}

.game .misano-content {
  left: 31px;
  position: absolute;
  top: 30px;
  text-align: left;
}

.game .mi-arrow-right {
  align-items: center;
  background: #b18f4f none repeat scroll 0 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 46px;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 46px;
}

.game .mi-arrow-right i {
  color: #ffff;
  font-size: 22px;
}

/* =================== Misano-area ======================  */

/*======================Track page===================*/

/*======================Rece_Start page===================*/

.game .container.wi-750 {
  max-width: 750px;
}

.game .race-starts-page-area .all-heading h2 span {
  color: #b18f4f;
  font-weight: 400;
  margin-left: 5px;
}
.game .all-heading .waiting-players {
  color: #FFFFFF;
  font-weight: bold;
  font-family: roboto, sans-serif;
} 

.game .race-starts-page-area .all-heading h2 br {
  display: none
}

.game .misano-content h5 {
  font-size: 10px;
}

.game .misano-content h6 {
  font-size: 10px;
  font-weight: 400;
}

.game .race-starts-page-area .all-car-text.car_txt, 
.game .race-summary-page-area .all-car-text.car_txt {
  margin-left: -100px;
  margin-bottom: 20px
}

.game .mt-10 {
  margin-top: 10px
}

@media screen and (max-width: 991px) {
  .game .race-starts-page-area .all-car-text.car_txt, 
  .game .race-summary-page-area .all-car-text.car_txt {
    margin-bottom: 20px;
    margin-left: 0;
  }
  .game .race-starts-page-area .all-heading h2 br {
    display: block
  }
  .game .race-starts-area {
    margin-top: 0;
  }
  .game .race-starts-area .misano-items {
    margin: 0 auto;
    max-width: 325px;
  }
  .game .race-starts-area .misano-items img {
    width: 100%
  }
  .game .race-starts-page-area .all-heading h2 span {
    font-size: 35px;
    margin-left: 0px;
  }
}

/*======================Rece_Start page===================*/

/*======================Rece Summayr page===================*/

.game .container.wi-950 {
  max-width: 950px;
}

.game .container.wi-1245 {
  max-width: 1245px;
}

.game .race-summary-page-area .starts_wrap.wi-300 {
  max-width: 300px;
  margin-top: 50px;
}

.game .starts_wrap.wi-600 {
  margin: 50px auto;
  max-width: 600px;
}

.game .starts_wrap.wi-900 {
  margin: 50px auto;
  max-width: 900px;
}

.game .all-car-text.car_txt b {
  opacity: 0
}

@media screen and (max-width: 991px) {
  .game .race-summary-page-area .bordered_title {
    position: relative;
    text-align: center;
  }
  .game .race-summary-page-area .bordered_title::after {
    background: #1A1A1D none repeat scroll 0 0;
    content: "";
    height: 82%;
    left: 0;
    position: absolute;
    top: -6px;
    width: 100%;
    z-index: -1;
  }
  .game .starts_wrap.wi-300 {
    margin: 0 auto 20px;
    max-width: 310px;
  }
  .game .single__banner.banner_bg.single__banner2.single__banner3 {
    background-image: url("/game-images/banner.png") !important;
  }
  .game .single__banner.banner_bg.single__banner2.single__banner3::after {    
    display: none;
  }
  .game .all-car-text.car_txt b {
    opacity: 1
  }
}

.game .monthly-page .single_line.d-flex.justify-content-between span {
  width: 20%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.game .monthly-page .single_line.d-flex.justify-content-between span.right.line_wrap {
  text-align: right;
}

.game .monthly-page .single_line.d-flex.justify-content-between .trigger {
  text-align: right;
}

.game .monthly-page .single_line.d-flex.justify-content-between span.crnter.line_wrap {
  text-align: center;
}

.game .monthly-page .single_line.bor-flex.d-flex.justify-content-between {
  border-bottom: 1px solid #b18f4f;
  margin-bottom: 10px;
}

.game .monthly-page .single_line.bor-flex.d-flex.justify-content-between span {
  font-size: 16px;
  line-height: 30px;
}

.game .monthly-page .single_line.d-flex.justify-content-between:hover span {
  color: #b18f4f;
}

.game .thumb img.pc-none {
  display: none;
}

.game .left_content_header h4.pc-none {
  display: none;
}

.game .banner__area.race-summary-page-area.monthly-page .all-heading {
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .game .banner__area.race-summary-page-area.monthly-page .all-heading {
    margin-bottom: 20px;
    margin-top: 70px
  }
}

/*======================Rece Summayr page===================*/

@media screen and (max-width: 1199px) {
  .game .all-car-text.car_txt, .race-summary-page-area .all-car-text.car_txt {
    margin-bottom: 20px;
    margin-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .game .header_area {
    background: #1a1a1d none repeat scroll 0 0;
    padding: 10px 0;
  }
  .game .pt-130 {
    padding-top: 100px;
  }
  .game .all-heading {
    margin-bottom: 30px;
    margin-top: 70px;
  }
  .game .car_txt b {
    color: #000;
  }
  .all-heading h2 {
    font-size: 30px;
  }
  .game .bor-titel-max h4 {
    font-size: 15px;
  }
  .game .all-car-text.car_txt {
    background: #1a1a1d none repeat scroll 0 0;
    border-radius: 50px;
    left: 50%;
    padding: 7px 30px;
    position: absolute;
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 1;
  }
  .game .start-flex {
    display: inherit;
    text-align: center;
  }
  .game .starts_wrap {
    margin-top: 0;
  }
  .game .start-game img {
    margin-left: 0;
  }
  .game .bor-titel-max h4::after {
    height: 1px;
    left: 50%;
    transform: translate(-50%);
  }
  /* ============== Track Css  ===========*/
  .game .select-box {
    margin: 0 auto;
  }
  .game .misano-dinan-box-area {
    margin: 40px auto 0;
    max-width: 325px;
  }
  .game .misano-dinan-box-area img {
    width: 100%
  }
  .game .starts_wrap.wi-210 {
    margin: 30px auto 50px;
    max-width: 325px;
  }
  .game .select-box {
    display: flex;
    flex-wrap: inherit;
    justify-content: inherit;
    overflow: auto;
    white-space: nowrap;
  }
  .game .select-box a.select-box-item {
    display: inline-table;
    height: 155px;
    margin: 0 10px;
    position: relative;
    width: 210px;
  }

  .game .select-box-img {
    width: 100%;
    height: 70px;
    padding: 0 10px 10px 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .game .select-box-img img {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
  }
  
  .game .select-box-items {
    padding: 10px;
    text-align: left;
    height: 55px;
  }

  /* ============== Track Css  ===========*/
  .game .thumb img.mob-non {
    display: none;
  }
  .game .thumb img.pc-none {
    display: block;
  }
  .game .left_content_header h4.pc-none {
    display: block;
    color: #4E4E54;
    font-size: 15px
  }

  .game .mo-footer .box-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    background-color: transparent;
  }

  .game .mo-footer .box-container .race-again-btn {
    margin-left: 0;
  }

  .game .mo-footer .box-container .race-again-btn .race_again {
    margin-top: 1rem;
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }

  .game .mo-footer .points-obtained {
    margin-bottom: 0.5rem;
  }

  .game .mo-footer .position-msg {
    color: #FFFFFF;    
    font-size: 24px;
    font-family: roboto, sans-serif;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  .game .start-game a img {
    width: 100%;
  }

  .game .mo-footer .position-msg {
    color: #FFFFFF;    
    font-size: 22px;
    font-family: roboto, sans-serif;
    margin-bottom: 0.5rem;
  }
}

.game .slicknav_menu .slicknav_icon::before {
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 425px) {
  .game .mm-t .big_car_thumb h4 {
    margin: -50px auto 30px auto !important;
  }

  .game .mo-footer .position-msg {
    color: #FFFFFF;    
    font-size: 20px;
    font-family: roboto, sans-serif;
    margin-bottom: 0.5rem;
  }
}

.game .select-all-car-text.car_txt-in {
  position: absolute;
  top: 60px;
  left: 130px;
  z-index: 9999;
}

.game .select-all-car-text.car_txt-in p {
  color: #fff;
  margin-bottom: 0;
  color: #fff;
  font-size: 25px;
  font-weight: 400;
}

.game .select-all-car-text.car_txt-in p span {
  color: #fff;
  margin-bottom: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  color: #B18F4F;
  margin-left: 20px;
}

.game .select-all-car-text.car_txt-in p b {
  opacity: 0;
}

@media screen and (max-width: 991px) {
  .game .select-all-car-text.car_txt-in {
    background: #1a1a1d none repeat scroll 0 0;
    border-radius: 50px;
    left: 50%;
    padding: 7px 30px;
    position: absolute;
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 1;
    top: 50px
  }
  .game .select-all-car-text.car_txt-in p {
    margin-bottom: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
  }
  .game .select-all-car-text.car_txt-in p b {
    opacity: 1;
  }
  .game .single_content .starts_wrap {
    text-align: center;
  }
  .game .mm-t .banner_content .starts_wrap {
    margin-top: 18px;
  }
  .game .banner__area .single__banner .banner_content .big_car_thumb {
    padding: 0;
  }
  .game .pt-100 {
    padding-top: 30px;
  }
  .game .container .last-race {
    font-weight: 500;
  }
  .game .container .last-race .last-race-link {
    color: #B18F4F;    
    font-size: 16px;
    text-transform: lowercase;
  }

}

.game .monthly-page .lines {
  margin-top: 95px;
}

.game .lines-mid {
  margin-top: 50px;
}

.game .mt-100 {
  margin-top: 100px
}

.game .mt-120 {
  margin-top: 120px
}

.game .race-summary-page-area .all-heading {
  margin-bottom: 20px;
}
.game .race-summary-page-area .all-heading .summary-race-name {
  color: #FFFFFF;
  font-family: roboto, sans-serif;
  font-weight: 400;
}

.game .race-starts-page-area .all-car-text.car_txt {
  left: 110px;
}

.game .banner__area.game-page.track-page .all-car-text.car_txt {
  left: 40px;
}

.game .track-page .all-heading {
  margin-bottom: 60px;
}

.game .track-page .misano-dinan-box-area {
  margin-top: 25px;
}

/*  Sample track simulation */

.simlation-position-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;    
  padding: 0;  
}

.simlation-position-container .media-container {
  background-color: #1A1A1C;
  height: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 11px 25px -10px #000;
}

.simlation-position-container .media-container .simulation-container {
  width: 900px;
  height: 502px;
}

.simlation-position-container .position-container {
  width: 100%;
}

.simlation-position-container .position-container .pos-sub-container {
  width: 100%;
  padding-left: 2rem;
}

.simlation-position-container .position-container .pos-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 0;
  background-color: #323236;
}

.simlation-position-container .position-container .pos-item .pos-number {
  width: 40px;
  height: 100%;
  text-align: center;
  border-right: 1px solid #616166;
  color: #FFFFFF;
  font-weight: 600;
}

.simlation-position-container .position-container .pos-item .pos-name {
  height: 100%;
  color: #616166;
  padding-left: 1rem;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
}

.simlation-position-container .position-container .is-player {
  background-color: #B18F4F !important;  
}

.simlation-position-container .position-container .is-player .pos-number {
  color: #000000;
  border-right: 1px solid #000000;
}

.simlation-position-container .position-container .is-player .pos-name {
  color: #000000;
}

.game-page .react-tabs {
  text-align: left;
}

.game-page .react-tabs__tab-list {
  border-bottom: 2px solid #D5B54B;
}

.game-page .react-tabs__tab-list li {
  color: #D5B54B;
  font-size: 20px;
  font-weight: 400;
}

.game-page .react-tabs__tab-list .react-tabs__tab--selected {
  background: #D5B54B;
  border-color: #D5B54B;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 1250px) {
  /*  Sample track simulation */
  .simlation-position-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;    
    padding: 0;  
  }

  .simlation-position-container .position-container {
    width: 50%;
    margin-top: 5rem;
  }

}

@media screen and (max-width: 991px) {
  .game .mo-footer {
    position: inherit;
    bottom: 0;
    left: inherit;
    transform: inherit;
    padding: 30px;
    text-align: center;
  }
  .game .race-summary-page-area .all-heading {
    margin-bottom: 0px;
  }
  .game .mt-100 {
    margin-top: 00px
  }
  .game .mt-120 {
    margin-top: 0px
  }
  .game .game-page .all-car-text.car_txt {
    left: 50%;
  }
  .game .race-starts-page-area .all-car-text.car_txt, .banner__area.game-page.track-page .all-car-text.car_txt {
    left: 50%;
  }
  .game .car_txt p {
    font-size: 20px;
  }
  .game .car_txt p span {
    font-size: 14px;
  }
  .game .banner__area .single__banner .banner_content .big_car_thumb h4 {
    margin-bottom: 15px;
  }
  .game .monthly-page .lines {
    margin-top: 0;
  }
  .game .monthly-page .starts_wrap.wi-600 {
    margin: 10px auto;
  }
  .game .monthly-page .single_line.bor-flex.d-flex.justify-content-between span {
    font-size: 16px;
  }
  .game .race-summary-page-area .starts_wrap.wi-300 {
    margin-top: 30px;
  }
  .simlation-position-container .media-container {
    background-color: #1A1A1C;
      height: auto;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      box-shadow: 0 11px 25px -10px #000;
  }
  .simlation-position-container .media-container .simulation-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
  }
  .simlation-position-container .media-container .simulation-container #miniEngineCanvas{
    width: 100%;
    height: auto;
  }

  /*  Sample track simulation */
  .simlation-position-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;    
    padding: 0;  
  }

  .simlation-position-container .position-container {
    width: 50%;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 767px) {
  .game .banner__area.mm-t .single__banner .banner_content {
    padding-top: 60px;
  }
  .game .banner_content .single_content {
    margin-top: 0px;
  }
  .simlation-position-container .media-container {
    background-color: #1A1A1C;
      height: auto;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      box-shadow: 0 11px 25px -10px #000;
  }
  .simlation-position-container .media-container .simulation-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
  }
  .simlation-position-container .media-container .simulation-container #miniEngineCanvas{
    width: 100%;
    height: auto;
  }

  /*  Sample track simulation */
  .simlation-position-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;    
    padding: 0;  
  }

  .simlation-position-container .position-container {
    width: 100%;
    margin-top: 2rem;
  }

  .simlation-position-container .position-container .pos-sub-container {
    width: 100%;
    padding: 2rem;
  }

  .game-page .react-tabs__tab-list {
    border-bottom: 2px solid #D5B54B;
    display: inline-flex;
    width: 100%;
  }

  .game-page .react-tabs__tab-list li {
    color: #D5B54B;
    font-size: 20px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.drop-event-container {
  width: 100%;
  height: 100%;
  background-color: #1c1c1c;
  font-family: "Roboto";
}

.drop-event-container .event-banner {
  width: 100%;
  position: relative;
  font-family: "Roboto";
}

.drop-event-container .event-banner > img {
  width: 100%;
}

.drop-event-container .event-banner > h1 {
  width: 100%;
  font-size: 65px;
  font-weight: 700;
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%);
  width: 90%;
}

.drop-event-container .event-banner > h2 {
  width: 100%;
  font-size: 22px;
  position: absolute;
  left: 50%;
  bottom: 7%;
  transform: translate(-50%);
}

.drop-event-container .event-info-container {
  width: 100%;
  height: auto;
}

#rocket-units-long {
  display: none;
}

.presentation-video-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 5rem;
}

.presentation-video-container > div {
  width: 996px;
  margin-top: 3rem;
}

.presentation-video-container > h2 {
  margin: 3rem 10rem;
  max-width: 40rem;
}

.presentation-video-container .event-sub-title {
  display: block;
  width: 80%;
  font-size: 30px;
  margin-bottom: 3rem;
  font-weight: normal;
}

.presentation-video-container > div > div {
  width: 100%;
}

#scott-robertson p {
  font-size: 20px;
  padding: 0 2rem;
}

.event-info-container > .down-arrow {
  display: none;
}

.event-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-info-container > h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.introducing {
  font-size: 21px;
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.introducing span {
  font-size: 26px;
}

.introducing img {
  max-width: 90%;
}

.italics p {
  font-style: italic;
}

.introducing p {
  max-width: 68rem;
  margin-top: 1rem;
}

.opaque {
  background-color: #7f655a !important;
  border: none !important;
}

hr.separator {
  width: 70px;
  background-color: #b18f4f;
}

.models-units {
  height: 11rem;
  padding: 0 2rem;
}

.models-units img {
  margin-top: -5rem;
}

.drop-event-content {
  width: 100%;
}

.drop-event-content .outer-box {
  width: 1200px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position-x: center;
  padding-top: 5rem;
}

.drop-event-content .outer-box .inner-box {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outer-box .inner-box .content-info {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.outer-box .inner-box .content-info-batches {
  margin-bottom: 2rem;
}

.content-image .total-table {
  background-color: #b576b9;
  border-radius: 17px;
  /* padding: 1.5rem 1rem; */
}

.content-image .total-table .table-inner {
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.content-image .total-table .table-inner > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 1rem; */
  flex: 1 1;
  height: 150px;
  min-width: 140px;
}

.content-image
  .total-table
  .table-inner
  > div:nth-child(1)
  > span:nth-child(2) {
  font-size: 60px;
  font-weight: lighter;
  margin-top: 0;
}

.content-image .total-table .table-inner > div:nth-child(3) > span:nth-child(2),
.content-image
  .total-table
  .table-inner
  > div:nth-child(2)
  > span:nth-child(2) {
  font-size: 14px;
  font-weight: normal;
}

/* .content-image .total-table .table-inner > div > span:nth-child(2) {
  margin-top: 1rem;
} */

.content-image .total-table .table-inner > div:not(:nth-child(3)) {
  border-right: 1px solid #000000;
}

.content-image .total-table .table-inner > div > span:nth-child(1) {
  font-weight: bold;
}

.content-info > .content-text {
  width: 550px;
}

.content-info > .content-image {
  height: 380px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-info > .content-image > img {
  width: 450px;
  height: 100%;
}

.content-info > .content-text {
  padding: 2rem;
  text-align: center;
}

.content-info > .content-text > span {
  font-weight: bold;
  font-size: 19px;
}

.content-info > .content-text > p {
  font-size: 19px;
  line-height: 35px;
  font-weight: 300;
  margin-top: 1rem;
}

.launch {
  margin-top: -4rem;
}

.batches-section {
  width: 376px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.batches-image,
.batches-image > img {
  width: 624px !important;
  height: auto !important;
  height: initial !important;
}

.batches-section > span:nth-child(8),
.batches-section > span:nth-child(5) {
  margin-top: 1rem !important;
}

.batches-section > span:nth-child(1) {
  font-weight: bold;
  font-size: 19px;
  margin-bottom: 1rem;
}

.batches-section > span:not(:nth-child(1)) {
  font-weight: 500;
  font-size: 16px;
}

.batches-section > p {
  font-weight: 300;
  font-size: 15px !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal !important;
}

.drop-event-container .asterisk {
  width: 100%;
  margin-bottom: 5rem;
}

.drop-event-container .asterisk > span {
  margin: 0 auto;
  color: #b18f4f;
  font-size: 18px;
  font-weight: 500;
}

.drop-event-container .will-be-winner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.drop-event-container .will-be-winner > span:nth-child(1) {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 3rem;
}

.drop-event-container .will-be-winner > span:nth-child(2) {
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 3rem;
}

.drop-event-container .will-be-winner > p {
  font-size: 20px;
  width: 90%;
}

.content-info .design-link {
  text-transform: none !important;
  font-size: 19px;
}

.drop-event-container .buy-now-bottom {
  margin-bottom: 7rem;
}

.drop-event-container .logos-contianer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drop-event-container .logos-container > div:nth-child(1) {
  width: 70px;
  height: 2px;
  margin: 0 auto;
  border-top: 1px solid #b18f4f;
}

.drop-event-container .logos-container > div:nth-child(2) {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logos-container > div:nth-child(2) > div:not(:nth-child(1)) {
  margin-left: 2rem;
}

@media screen and (max-width: 1200px) {
  .drop-event-container .event-banner > h1 {
    font-size: 50px;
    bottom: 20%;
  }

  .drop-event-container .event-banner > h2 {
    width: 90%;
    font-size: 22px;
    position: absolute;
    left: 50%;
    bottom: 7%;
    transform: translate(-50%);
  }

  .drop-event-content .outer-box {
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 98%;
    background-position-x: center;
    padding-top: 2rem;
  }

  .drop-event-content .outer-box .inner-box {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .introducing p {
    max-width: 90%;
    margin: 1.5rem 2rem;
  }
}

@media screen and (max-width: 990px) {
  .drop-event-container .event-banner > img {
    width: 100%;
  }

  .with-margin {
    margin-top: 0rem;
  }

  #design-legends-log {
    width: 90%;
    margin-top: 19rem;
    font-size: 35px;
  }

  .drop-event-container .event-banner > h1 {
    margin-bottom: auto;
    bottom: 4%;
    max-width: 100%;
  }

  .drop-event-container .event-banner > h2 {
    display: none;
  }

  .introducing p {
    max-width: 80%;
  }

  #rocket-units-long {
    display: block;
  }

  #rocket-units {
    display: none;
  }

  .presentation-video-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 10rem;
  }

  .presentation-video-container > div {
    width: 100%;
  }

  .presentation-video-container > div > div > .video {
    width: 100%;
  }

  .event-info-container > .separator {
    display: none;
  }

  .event-info-container > .down-arrow {
    display: block;
    margin-top: 3rem;
  }

  .event-info-container > .down-arrow > img {
    width: 50px;
  }

  .drop-event-content .outer-box {
    width: 100%;
    margin: 0 auto;
    background-image: none;
    padding-top: 5rem;
  }

  .outer-box .inner-box .content-info {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .buy-now-link a {
    margin-top: 8rem;
  }

  .content-info > .content-text {
    padding: 2rem;
    text-align: center;
  }

  .content-info > .content-text > span {
    font-weight: bold;
    font-size: 19px;
  }

  .content-info > .content-text > p {
    font-size: 19px;
    line-height: 35px;
    font-weight: 300;
    margin-top: 1rem;
  }

  .drop-event-content > div > div > div:nth-child(1) {
    margin-bottom: 1rem !important;
  }

  .drop-event-content > div > div > div:nth-child(2) {
    flex-direction: column-reverse !important;
    margin-bottom: 1rem !important;
  }

  .batches-section > span:not(:nth-child(1)) {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
  }

  .batches-section > p {
    font-weight: 300;
    font-size: 15px !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important;
    color: rgba(255, 255, 255, 0.5);
  }

  .drop-event-content > div > div > div:nth-child(3) {
    margin-bottom: 1rem !important;
  }

  .drop-event-container .asterisk {
    width: 100%;
    margin-bottom: 2rem;
  }

  .drop-event-container .will-be-winner > span:nth-child(2) {
    color: rgba(255, 255, 255, 0.5);
  }

  .models-units img {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .drop-edition {
    padding: 0 2rem;
  }

  .drop-event-container .event-banner > h1 {
    font-size: 30px;
  }

  .content-info > .content-text {
    width: 90% !important;
  }

  #about-the-drop {
    margin-bottom: 3rem;
  }

  .content-info > .content-image {
    width: 90% !important;
    height: 100%;
  }

  .content-info > .content-image > img {
    width: 90% !important;
    height: 100%;
  }

  .model-units img {
    margin-top: -50rem !important;
  }

  .content-image .total-table {
    width: 90%;
    background-color: #b576b9;
    border-radius: 17px;
    padding: 1.5rem 0;
  }

  .drop-event-content .outer-box .inner-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .outer-box .inner-box .content-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-image .total-table .table-inner > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    flex: 1 1;
    height: 150px;
  }

  .drop-event-container .will-be-winner > span:nth-child(1) {
    width: 90%;
    font-size: 30px;
  }

  .drop-event-container .will-be-winner > span:nth-child(2) {
    width: 90%;
    font-size: 22px;
  }
  .will-be-winner {
    margin-bottom: 15rem;
  }
}

@media screen and (max-width: 425px) {
  .visit-car-page {
    padding: 1.4rem 2rem !important;
  }

  .drop-event-container .event-banner > h1 {
    font-size: 25px;
  }

  .content-info > .content-text {
    width: 100% !important;
    height: 100%;
  }

  .content-info > .content-image {
    width: 100% !important;
    height: 100%;
  }

  .content-info > .content-image > img {
    width: 100% !important;
    height: 100%;
  }

  .content-image .total-table {
    width: 98%;
    background-color: #b576b9;
    border-radius: 17px;
    padding: 1.5rem 0;
  }

  .drop-event-content .outer-box .inner-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .outer-box .inner-box .content-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-image .total-table .table-inner > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    flex: 1 1;
    height: 150px;
  }

  .drop-event-container .will-be-winner > span:nth-child(1) {
    width: 95%;
    font-size: 30px;
  }

  .drop-event-container .will-be-winner > span:nth-child(2) {
    width: 95%;
    font-size: 22px;
  }

  .content-image
    .total-table
    .table-inner
    > div:nth-child(3)
    > span:nth-child(2),
  .content-image
    .total-table
    .table-inner
    > div:nth-child(2)
    > span:nth-child(2) {
    font-size: 12px;
    font-weight: normal;
  }

  .content-image
    .total-table
    .table-inner
    > div:nth-child(1)
    > span:nth-child(2) {
    font-size: 50px;
  }

  .content-image .total-table .table-inner > div {
    min-width: auto;
  }
}

.toast-container {
    border: 1px solid #B18F4F;     
    margin-top: 5rem;
}

.toast-container .react-toast-notifications__toast__icon-wrapper {
    background-color: #B18F4F;
    color: #FFFFFF;
}

.error .react-toast-notifications__toast__icon-wrapper {
    background-color: #d16969 !important;        
}

.error {
    border: 1px solid #d16969 !important; 
}
