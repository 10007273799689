.sign-in {
    color: #333333;
    background-color: #FFFFFF;
    text-align: left;
    padding: 4.3rem 0;
}

.sign-in .center {
    max-width: 35rem;
    padding: 0 1rem;
}

.sign-in h1 {
    color: #000000;
    font-size: 1.62rem;
    font-weight: normal;
}

.sign-in input[type="text"],
.sign-in input[type="password"] {
    outline: none;
    max-width: 35rem;
    width: 100%;
    padding: 1.3rem .5rem;
    border-radius: .4rem;
    border: 1px solid #dddddd;
    font-size: 1rem;
    margin: .4rem 0;
}

.sign-in input[type="text"]:focus,
.sign-in input[type="password"]:focus {
    border: 1px solid #B18F4F;
}

.sign-in .eu-resident {
    margin: 2.1rem 0 .5rem 0;
}

.sign-in input[type="radio"] {
    vertical-align: baseline;
    width: 1rem;
    height: 1rem;
}

.sign-in hr {
    margin: 1rem 0;
    border: none;
    height: 1px;
    background-color: #DDDDDD;
}

.sign-in .sign-in-div {
    text-align: right;
    padding-top: 1.93rem;
}

.sign-in .sign-in-div button {
    width: 7.3rem;
    margin-left: .5rem;
}

.sign-in .already-have-account {
    padding: 1rem 0;
}

.sign-in .legal a {
    color: #B18F4F;
    font-size: initial;
    text-transform: initial;
    letter-spacing: initial;
}

@media screen and (max-width: 786px) {
    .sign-in {
        padding: 2rem 0;
    }
}
