.design-terms {
    text-align: left;
    color: #000000;
    max-width: 42rem;
    margin-top: 5rem;
    margin-bottom: 12rem;
}

.design-terms h1 {
    font-size: 1.87rem;
    margin-bottom: 5rem;
}

.design-terms h2 {
    font-weight: initial;
}

.design-terms p {
    color: #333333;
    margin-bottom: 2rem;
}

.design-terms a {
    color: #B18F4F;
    text-transform: initial;
    letter-spacing: initial;
    font-size: initial;
}
