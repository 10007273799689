@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800|Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");

.game, 
.game .banner__area,
.game .banner__area .page-area,
.game .banner__area .page-area .single__banner {
  height: 100% !important;
}

.game #thumbs {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.game .left {
  text-align: left;
}

.game .header_area .container, .footer_top_area .container {
  max-width: 1370px;
}

.game .banner__area.mm-t .container {
  max-width: 1245px
}

.game .img {
  max-width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.game a, .game .button, button {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.game a:focus, .game .button:focus, .game button:focus {
  text-decoration: none;
  outline: none;
}

.game a:focus {
  text-decoration: none;
}

.game a:focus, 
.game a:hover, 
.game .portfolio-cat a:hover, 
.game .footer -menu li a:hover {
  text-decoration: none;
}

.game input:focus, .game button:focus {
  outline: none;
}

.game a, .game button {
  color: #fff;
  outline: medium none;
}

.game h1, 
.game h2, 
.game h3, 
.game h4, 
.game h5 {
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
}

.game h1 a, 
.game h2 a, 
.game h3 a, 
.game h4 a, 
.game h5 a, 
.game h6 a {
  color: inherit;
}

.game ul {
  margin: 0px;
  padding: 0px;
}

.game li {
  list-style: none;
}

.game p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #505050;
  margin-bottom: 13px;
  font-family: "Roboto", sans-serif;
}

.game label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

*::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

*::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

*::-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

*::placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

.user-data .user-data-wrapper {
  display: flex;
  padding-left: 50px;
  max-width: 95%;  
  width: 100%;
}

.user-data .user-details {
  display: flex;
  align-items: flex-end;
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding: 0 15px;
  letter-spacing: 0.144em;
  text-overflow: ellipsis;  
}

.user-data .points {  
  margin-left: 10px;
  font-size: 14px;
  color: #B18F4F;
}

.user-data .score{
  color: #B18F4F;
  margin-left: 50px;
  font-size: 14px;
}

.user-data .user-details .last-race {  
  font-size: 14px;  
  margin-left: auto;
}

.user-data .user-details .last-race .last-race-link {
  text-transform: lowercase;
  color: #B18F4F !important;
}

.game .owl-carousel .owl-nav div {
  left: 0px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #fff;
  left: 0;
}

.game .owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 0px;
}

.game .owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.game .owl-carousel:hover .owl-nav div:hover {
  color: #B18F4F;
}

.game .mb-85 {
  margin-bottom: 85px;
}

.game .mo-footer .share-twitter {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  font-family: roboto, sans-serif;
}

@media (max-width: 767px) {
  .game .mb-85 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .mb-85 {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .mb-85 {
    margin-bottom: 40px;
  }
}

.game .mb-65 {
  margin-bottom: 65px;
}

@media (max-width: 767px) {
  .game .mb-65 {
    margin-bottom: 20px;
  }
}

.game .mt-80 {
  margin-top: 80px;
}

.game .mb-40 {
  margin-bottom: 40px !important;
}

.game .home_2 #back-top a {
  background: #4543de;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(69, 67, 222, 0.4);
  -moz-box-shadow: 0px 10px 20px 0px rgba(69, 67, 222, 0.4);
  box-shadow: 0px 10px 20px 0px rgba(69, 67, 222, 0.4);
}

.game #back-top {
  position: fixed;
  right: 20px;
  bottom: 30px;
  cursor: pointer;
  z-index: 9999999;
  display: none;
}

.game #back-top a {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  color: #fff;
  line-height: 40px;
  background: #ff4357;
  font-size: 15px;
  border-radius: 30px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(255, 67, 87, 0.4);
  -moz-box-shadow: 0px 10px 20px 0px rgba(255, 67, 87, 0.4);
  box-shadow: 0px 10px 20px 0px rgba(255, 67, 87, 0.4);
}

.race_again {
  display: block !important;
  width: fit-content !important;
  color: #fff !important;  
  margin: 1.5rem auto;
  padding: 10px 30px !important;
}

.race_again:hover {
  color: #B18F4F !important;
  border-color: #B18F4F;
  background: transparent;  
}

.game .boxed_btn {
  background: #B18F4F;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 15px 38px;
  border: 1px solid transparent;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .game .boxed_btn {
    padding: 12px 30px;
  }
}

.game .boxed_btn:hover {
  color: #B18F4F;
  border-color: #B18F4F;
  background: transparent;
}

.game .boxed_btn_2 {
  padding: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  color: #B18F4F;
  font-weight: 600;
  padding: 12px 40px;
  border: 1px solid #B18F4F;
}

@media (max-width: 767px) {
  .game .boxed_btn_2 {
    padding: 9px 20px;
  }
}

.game .boxed_btn_2:hover {
  color: #fff;
  background: #B18F4F;
}

.game .line_btn {
  font-size: 16px;
  color: #fff;
  padding: 12px 41px;
  border: 1px solid #fff;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 767px) {
  .game .line_btn {
    padding: 12px 30px;
  }
}

.game .line_btn:hover {
  background: #fff;
  color: #000;
}

.game .blank_btn {
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 12px 65px;
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-weight: 600;
}

@media (max-width: 767px) {
  .game .blank_btn {
    padding: 12px 30px;
  }
}

.game .blank_btn:hover {
  color: #000;
  background: #fff;
}

.game .boxed_btn3 {
  background: #4543de;
  padding: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  padding: 12px 40px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid transparent;
}

@media (max-width: 767px) {
  .game .boxed_btn3 {
    padding: 12px 30px;
  }
}

.game .boxed_btn3:hover {
  color: #4543de;
  border-color: #4543de;
  background: transparent;
}

.game .button-music-helper {
  display: none;
}

/*----------------------------------------------------*/

.game .section_title.white_text h3 {
  color: #fff;
}

.game .section_title span {
  font-size: 16px;
  color: #8f8f8f;
  font-weight: 700;
  display: block;
  line-height: 16px;
  margin-bottom: 21px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title span {
    font-size: 14px;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .game .section_title span {
    font-size: 14px;
    margin-top: 10px;
  }
}

.game .section_title h3 {
  font-size: 42px;
  font-weight: 700;
  line-height: 54px;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title h3 {
    font-size: 38px;
    line-height: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title h3 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .game .section_title h3 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

.game .section_title h4 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.35;
  color: #1c1c1c;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title h4 {
    font-size: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title h4 {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .game .section_title h4 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title h4 {
    font-size: 26px;
  }
}

.game .section_title.section_title2 h3 {
  margin-bottom: 18px;
  margin-bottom: 0;
  font-weight: 400;
}

.game .section_title.section_title2 p {
  line-height: 1.67;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 8px;
  color: #505050;
}

@media (max-width: 767px) {
  .game .section_title.section_title2 p br {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title.section_title2 p br {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title.section_title2 p br {
    display: none;
  }
}

.game .section_title_2 h3 {
  font-size: 42px;
  font-weight: 400;
  line-height: 1.29;
}

.game .section_title_2 h3 span {
  color: #4543de;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title_2 h3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_2 h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .game .section_title_2 h3 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_2 h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

.game .section_title_3 h3 {
  font-size: 42px;
  font-weight: 400;
  line-height: 1.29;
}

.game .section_title_3 h3 span {
  color: #4543de;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title_3 h3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_3 h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .game .section_title_3 h3 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_3 h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

.game .section_title_3 p {
  font-size: 20px;
  line-height: 1.6;
  color: #505050;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .game .section_title_3 p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_3 p br {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_3 p br {
    display: none;
  }
}

.game .section_title_4 h3 {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.29;
  color: #0B0B21;
}

.game .section_title_4 h3 span {
  color: #4543de;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .section_title_4 h3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_4 h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .game .section_title_4 h3 br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_4 h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

.game .section_title_4 p {
  font-size: 18px;
  line-height: 1.6;
  color: #505050;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .game .section_title_4 p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .section_title_4 p br {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .section_title_4 p br {
    display: none;
  }
}

.game .pl-80 {
  padding-left: 80px;
}

@media (max-width: 767px) {
  .game .pl-80 {
    padding-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .pl-80 {
    padding-left: 30px;
  }
}

.game .pl-100 {
  padding-left: 100px;
}

@media (max-width: 767px) {
  .game .pl-100 {
    padding-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .pl-100 {
    padding-left: 30px;
  }
}

.game .mb-90 {
  margin-bottom: 82px;
}

@media (max-width: 767px) {
  .game .mb-90 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .mb-90 {
    margin-bottom: 50px;
  }
}

.game .pl-65 {
  padding-left: 65px;
}

@media (max-width: 767px) {
  .game .pl-65 {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .pl-65 {
    padding-left: 0px;
  }
}

.game .pb-180 {
  padding-bottom: 180px;
}

@media (max-width: 767px) {
  .game .pb-180 {
    padding-bottom: 80px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .pb-180 {
    padding-bottom: 100px;
  }
}

.game .mb-30 {
  margin-bottom: 30px;
}

.game .header_area {
  padding: 0px 35px 0 35px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #1A1A1D;
}

.game .header_area.header_static {
  position: relative;
  padding: 0px 35px 0 35px;
}

@media (max-width: 767px) {
  .game .header_area.header_static {
    padding: 10px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .header_area.header_static {
    padding: 10px 0px;
  }
}

@media (max-width: 767px) {
  .game .header_area {
    padding: 10px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .header_area {
    padding: 10px 0px;
  }
}

.game .header_area .header__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}

.game .header_area .header__wrapper .header__left {
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .header__wrapper .header__left {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .header__wrapper .header__left {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
  }
}

.game .header_area .header__wrapper .header__right {
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .header__wrapper .header__right {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .header__wrapper .header__right {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
  }
}

.game .header_area .header__wrapper .header__right span {
  color: #B18F4F !important;
  font-size: 15px;
  font-weight: 400;
  margin-right: 30px;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .header__wrapper .header__right {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
}

.game .header_area .header__wrapper .header__right .thumb {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.game .header_area .header__wrapper .header__right .thumb img {
  width: 100%;
}

.game .header_area .main_menu ul li {
  display: inline-block;
  position: relative;
}

.game .header_area .main_menu ul li a {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  margin: 0 26px;
  padding: 30px 0;
  display: inline-block;
  text-transform: uppercase;
}

.game .header_area .main_menu ul li a:hover, 
.game .header_area .main_menu ul li.active a {
  color: #B18F4F;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .main_menu ul li a {
    font-size: 14px;
    margin: 0 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .main_menu ul li a {
    margin: 0 15px;
  }
}

.game .header_area .main_menu ul li .submenu {
  width: 200px;
  position: absolute;
  left: 0;
  top: 120%;
  background: #fff;
  text-align: left;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.game .header_area .main_menu ul li .submenu li {
  display: block;
  margin: 0;
}

.game .header_area .main_menu ul li .submenu li a {
  font-size: 15px;
  color: #505050;
  padding: 0;
  font-weight: 400;
  display: block;
  margin: 0;
  padding: 11px 20px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  text-transform: uppercase;
  left: 0;
}

.game .header_area .main_menu ul li .submenu li a:hover {
  left: 5px;
  color: #B18F4F;
}

.game .header_area .main_menu ul li:hover>a {
  color: #B18F4F;
}

.game .header_area .main_menu ul li:hover .submenu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.game .header_area .contact_wrap {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .game .header_area .contact_wrap {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .header_area .contact_wrap {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .header_area .contact_wrap {
    display: none !important;
  }
}

.game .header_area .contact_wrap .mobile_no p {
  color: #505050;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
}

.game .header_area .contact_wrap .mobile_no p span a {
  color: #B18F4F;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .contact_wrap .mobile_no p {
    font-size: 13px;
    margin-right: 8px;
  }
}

.game .header_area .contact_wrap .signin a.sign_in {
  font-size: 16px;
  color: #505050;
  font-weight: 600;
  margin-right: 40px;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .contact_wrap .signin a.sign_in {
    margin-right: 15px;
  }
}

.game .header_area .contact_wrap .signin a.sign_in:hover {
  color: #4543DE;
}

.game .header_area .contact_wrap .contact_btn {
  margin-left: 48px;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .contact_wrap .contact_btn {
    margin-left: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area .contact_wrap .contact_btn {
    margin-left: 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .game .header_area .contact_wrap .contact_btn .boxed_btn {
    padding: 12px 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .main_menu ul li a {
    margin: 0 22px;
  }
}

.game .header_area.header_area2 .main_menu ul li a:hover {
  color: #4543de;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .boxed_btn3 {
    font-size: 14px;
    padding: 12px 13px;
  }
}

.game .header_area.header_area2 .main_menu ul li:hover>a {
  color: #4543de;
}

.game .header_area.header_area2 .header__wrapper .header__left {
  -ms-flex-preferred-size: 15%;
  flex-basis: 15%;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .header__wrapper .header__left {
    -ms-flex-preferred-size: 15%;
    flex-basis: 15%;
  }
}

.game .header_area.header_area2 .header__wrapper .main_menu {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .header__wrapper .main_menu {
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
  }
}

.game .header_area.header_area2 .header__wrapper .header__right {
  -ms-flex-preferred-size: 35%;
  flex-basis: 35%;
  display: none span;
  display-color: #fff !important;
  display-font-size: 13px;
  display-font-weight: 400;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .header_area.header_area2 .header__wrapper .header__right {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
}

/*----------------------------------------------------*/

.game .slicknav_menu .slicknav_icon {
  margin-right: 6px;
  margin-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 0px;
}

.game .slicknav_nav a {
  font-size: 14px;
  color: #000;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-weight: 400;
}

.game .slicknav_nav .slicknav_row, .slicknav_nav a {
  padding: 9.5px 15px;
  margin: 0;
}

.game .slicknav_arrow {
  position: absolute;
  right: 0;
  width: 40px;
  height: 37px;
  text-align: center;
  line-height: 40px;
  top: 0;
}

.game .slicknav_nav li {
  display: block;
  border-bottom: none;
  position: relative;
}

.game .mobile_menu .slicknav_nav ul.submenu {
  list-style: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: #f7f7f7;
}

.game .mobile_menu .slicknav_nav ul.submenu li a {
  font-size: 16px;
}

.game .mobile_menu .slicknav_nav .slicknav_arrow {
  margin: 0;
}

.game .slicknav_nav .slicknav_row:hover {
  color: #B18F4F;
}

.game .slicknav_nav a:hover {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  background: transparent;
  color: #B18F4F;
}

.game .slicknav_menu .slicknav_icon-bar {
  background-color: #363e51;
  height: 2px;
  margin: 5px 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 25px;
  position: relative;
}

.game .slicknav_menu .slicknav_icon {
  margin-right: 0px;
}

.game .mobile_menu {
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 9999999;
}

.game .slicknav_btn {
  right: 24px;
}

.game .slicknav_nav {
  background: #1a1a1d none repeat scroll 0 0;
  border-top: 1px solid #fff;
  box-shadow: 0 5px 7px 0 rgba(13, 0, 36, 0.07);
  margin-top: 12px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.game .slicknav_nav .slicknav_row, .game .slicknav_nav a {
  padding: 6.5px 20px;
  margin: 0;
}

.game .slicknav_nav .slicknav_row, .game .slicknav_nav a {
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 10px 20px;
}

/*----------------------------------------------------*/

.game .slicknav_btn {
  padding: 0px;
  border: 0px;
}

.game .slicknav_btn .slicknav_icon-bar {
  background-color: #B18F4F;
  -webkit-transition: all .5s linear;
  -o-transition: all .5s linear;
  transition: all .5s linear;
  display: block;
  width: 22px;
  height: 2px;
}

.game .home_2 .slicknav_btn .slicknav_icon-bar {
  background-color: #4543de;
}

.game .slicknav_btn .slicknav_icon-bar+.slicknav_icon-bar {
  margin-top: 5px;
}

.game .slicknav_btn .slicknav_icon-bar:nth-child(2) {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.game .slicknav_btn.slicknav_open .slicknav_icon-bar:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.game .slicknav_btn .slicknav_icon-bar:nth-child(3) {
  opacity: 1;
}

.game .slicknav_btn.slicknav_open .slicknav_icon-bar:nth-child(3) {
  opacity: 0;
}

.game .slicknav_btn .slicknav_icon-bar:nth-child(1) {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  top: 0;
}

.game .slicknav_btn.slicknav_open .slicknav_icon-bar:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
  position: relative;
}

/*----------------------------------------------------*/

/*----------------------------------------------------*/

.game .banner_bg {
  background-image: url(/game-images/banner.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: calc(100vh - 328px);
}

.game .single__banner.banner_bg.single__banner2.single__banner3 {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1
}

.game .single__banner.banner_bg.single__banner2.single__banner3::after {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.game .banner__area .single__banner {
  min-height: 900px;
  height: calc(100vh - 248px);
  position: relative
}

@media (min-width: 1280px) and (max-width: 1440px)  {
  .game .all-heading {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px !important;
  }
  .game .track-page .all-heading {
    margin-bottom: 10px !important;
  }
  .boxed_btn {
    margin-top: 0 !important;
  }
  .game .starts_wrap {
    margin-top: 0 !important;
  }
  /*Select Car*/  
  .game .banner__area.mm-t .single__banner .banner_content {
    padding-top: 30px !important;    
  }
  .game .banner__area .single__banner .banner_content .big_car_thumb h4 {
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px !important;
  }
  .game .banner__area .single__banner {
    min-height: 800px;
    height: calc(100vh - 248px);
    position: relative;
  }
  .race_btn {
    margin-top: 0.5rem !important;
  }
  /* Summary*/
  .game .race-summary-page-area .all-heading {
    margin-bottom: 0px;
  }
  .game .race-summary-page-area .starts_wrap.wi-300 {
    margin-top: 50px !important;
  }
}
@media (min-width: 1441px) and (max-width: 1920px)  {

  .game .page-area {
    height: 100%;
  }
  .game .banner__area .single__banner.summary-h {  
    height: calc(100vh - 248px);  
  }
  .game .banner__area .single__banner {
    min-height: 900px;
    height: calc(100vh - 248px);
    position: relative
  }
  /* Select car */
  .game .banner__area.mm-t .single__banner .banner_content {
    padding-top: 40px !important;    
  }
  .big_car_thumb .race_btn {
    margin-top: 0 !important;
  }
  #select-car {
    min-height: 750px;
  }
  /* Track */
  .game .track-page .all-heading {
    margin-bottom: 40px !important;
    margin-top: 40px;
  }
  .single__banner .banner_bg .single__banner2 .bann-800 {
    padding-bottom: 100px;
    height: 100%;
  }
  .game .container .wi-1245 {
    width: 1245px;
  }
  .game .container {
    max-width: 1245px;
  }
  .game .starts_wrap{
    margin-top: 0px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  /*Race Start*/
  .game .mt-100 {
    margin-top: 50px !important;
  }
  /* Summary*/
  .game .race-summary-page-area .all-heading {
    margin-bottom: 50px;
  }
  .game .race-summary-page-area .starts_wrap.wi-300 {
    margin-top: 50px !important;
  }
}


.game .banner__area .single__banner.summary-h {  
  height: auto;  
}

@media (min-width: 1921px) and (max-width: 2560px)  {
  /* Select car */
  #select-car {
    padding-top: 5rem;
  }
  .game .banner__area .single__banner.summary-h {  
    height: calc(100vh - 248px);  
  }
  /* Select track */
  .game .banner__area .single__banner {
    padding-top: 5rem;
    min-height: 900px;
    height: calc(100vh - 248px);
    position: relative
  }
  /* Race Start */
  .game .race-start {
    padding-top: 5rem;
  }
  .game .starts_wrap {
    margin-top: 0 !important;
  }
  .game .mt-120 {
    margin-top: 170px !important;
  }
  /* Summary*/
  .game .race-summary-page-area .all-heading {
    margin-bottom: 50px !important;
  }
  .game .race-summary-page-area .starts_wrap.wi-300 {
    margin-bottom: 50px !important;
  }
}

.game .banner__area.mm-t .single__banner {
  height: calc(100vh - 373px);
}

@media (max-width: 767px) {
  .game .banner__area .single__banner.single__banner2 .banner_content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner.single__banner2 .banner_content {
    padding-bottom: 50px;
  }
}

.game .car_txt {
  position: absolute;
  left: 19%;
  top: 20%;
}

.game .car_txt p {
  margin-bottom: 0;
  color: #fff;
  font-size: 25px;
  font-weight: 400;
}

.game .car_txt p span {
  display: inline-block;
  color: #B18F4F;
  margin-left: 10px;
  font-size: 20px
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .car_txt {
    background: #1A1A1D;
    background: #1A1A1D;
    padding: 7px 30px;
    border-radius: 50px;
    z-index: 1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    white-space: nowrap;
  }
}

@media (max-width: 767px) {
  .game .mm-t .car_txt {
    background: #1A1A1D;
    background: #1A1A1D;
    padding: 7px 30px;
    border-radius: 50px;
    z-index: 1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    white-space: nowrap;
    margin-top: 30px;
    top: 50px !important;
  }
}

.game .banner__area .single__banner .banner_content {
  padding-top: 170px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20% 63% 17%;
  grid-template-columns: 20% 60% 20%;
}

.game .banner__area.mm-t .single__banner .banner_content {
  padding-top: 70px;
  position: relative;
}

.game .banner_content .single_content {
  margin-top: 33px;
  text-align: left;
}

.game .banner_content .single_content-right {
  margin-top: 80px;
}

.game .banner_content .prev_button_content {
  margin-bottom: 10px;
}

.game .banner_content .prev_button_content > span {
  color: #B18F4F;
  cursor: pointer;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content {
    grid-template-columns: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content {
    grid-template-columns: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
  }
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .single_content {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .single_content {
    width: 70%;
    margin: auto;
  }
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb {
  position: relative;
  margin-bottom: 30px;
  margin-top: 50px;
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb .thumb img {
  width: 100%;
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb .speed_info {
  position: absolute;
  top: 8px;
  left: 7px;
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb .speed_info h4 {
  font-size: 8px;
  font-weight: bold;
  margin-bottom: 0;
}

.game .banner__area .single__banner .banner_content .single_content .speed_thumb .speed_info h5 {
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 0;
}

.game .banner__area .single__banner .banner_content .single_content .left_content_header h4 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .single_content .left_content_header h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .single_content .left_content_header h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}

.game .banner__area .single__banner .banner_content .single_content .left_content_header span {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .single_content .left_content_header span {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .single_content .left_content_header span {
    display: none;
  }
}

.game .banner__area .single__banner .banner_content .single_content h1 {
  font-size: 96px;
  line-height: 1;
  color: #fff;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 26px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .single_content h1 {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .single_content h1 {
    display: none;
  }
}

@media (max-width: 767px) {
  .game .starts_wrap .bordered_title {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .banner__area .single__banner .banner_content .big_car_thumb {
    text-align: center;
    padding: 0 20px;
  }
}

.game .starts_wrap .bordered_title h4 {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  border-bottom: 1px solid #B18F4F;
  padding-bottom: 6px;
  margin-bottom: 13px;
  text-align: left;
}

@media (max-width: 767px) {
  .game .starts_wrap .bordered_title h4 {
    display: inline-block;
    padding: 0 20px 8px 20px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .starts_wrap .bordered_title h4 {
    display: inline-block;
    padding: 0 20px 8px 20px;
    text-align: center;
  }
}

.game .starts_wrap .lines .single_line span.line_wrap {
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  line-height: 26px;
  letter-spacing: 1px;
}

.game .banner__area .single__banner .banner_content .big_car_thumb {
  text-align: center;
  padding: 0 30px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .big_car_thumb {
    position: relative;
    margin-top: 120px;
  }
}

.game .banner__area .single__banner .banner_content .big_car_thumb h4 {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 70px;
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .big_car_thumb h4 {
    position: absolute;
    font-size: 30px;
    margin-top: 30px;
    width: 300px;
    margin: 0px auto 30px auto;
  }
}

.banner__area .single__banner .banner_content .big_car_thumb .thumb img {
  width: 100%;
}

.game .slider_controller_wrap {
  background: #1A1A1C;
  padding: 25px 0;
}

.game .slider_controller_wrap.slider_controller_wrap2 {
  padding-top: 120px;
  background: transparent;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .game .slider_controller_wrap.slider_controller_wrap2 {
    display: none;
  }
}

.game .slider_controller_wrap .owl-carousel .thumb_info {
  text-align: center;
}

.game .slider_controller_wrap .owl-carousel .item-carousel {
  padding: 0;
  max-width: 100%;
  width: 100%;
  margin: 0;
}

.game .slider_controller_wrap .owl-carousel .item-carousel .left-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-color: transparent;
  border-left: transparent;
  border-right: 10px solid rgba(114, 114, 114, 0.5);
}

.game .slider_controller_wrap .owl-carousel .item-carousel .right-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-color: transparent;
  border-right: transparent;
  border-left: 10px solid rgba(114, 114, 114, 0.5);
}

.game .slider_controller_wrap .owl-carousel .thumb_info h4 {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}

.game .slider_controller_wrap .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.game .boxed_btn.boxed_btn2 {
  display: block;
  margin-top: 40px;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .game #big {
    width: 300px;
    margin: auto;
  }
}

@media (max-width: 767px) {
  .game #big {
    width: 280px;
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .game .big_car_thumb {
    margin: auto;
  }
}

@media (max-width: 767px) {
  .game .banner__area .single__banner .banner_content .big_car_thumb {
    position: relative;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
}

/*----------------------------------------------------*/

/*# sourceMappingURL=style.css.map */

.game .banner_content .starts_wrap {
  margin-top: 30px;
}

/*=============================== Style Css ======================================*/

.game .pt-100 {
  padding-top: 60px;
}

.game .pb-180 {
  padding-bottom: 180px;
}

.game .container.gm-page-w {
  max-width: 1140px;
}

.game .all-car-text.car_txt {
  position: inherit;
  left: 0;
  top: 0;
}

.game .game-page .all-car-text.car_txt {
  left: 90px;
}

.game .all-heading {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 50px;
}

.game .pt-middle {
  padding-top: 10rem;
}

.game .all-heading h2 {
  font-size: 36px;
  color: #fff;
  font-weight: 600;
}

.game .start-flex {
  display: flex;
  justify-content: space-between;
}

.game .starts_wrap {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.game .bor-titel-max h4 {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  padding-bottom: 6px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  display: inline-block;
}

.game .bor-titel-max h4::after {
  height: 1px;
  background: #B18F4F;
  width: 100%;
  bottom: 0;
  left: 0;
  content: "";
  position: absolute;
}

.game .start-game img {
  margin-left: -17px;
}

.game .start-game a {
  display: block;
}

.game .mo-footer {
  padding: 70px 0;
}

.game .mo-footer .box-container {  
  width: fit-content;  
  padding: 1rem 3rem;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.game .mo-footer .box-container .race-again-btn {
  font-family: roboto, sans-serif;
  margin-left: 5rem;
}

.game .mo-footer .points-obtained {
  color: #B18F4F;
  font-family: roboto, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.144em;
}

.game .mo-footer p {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;  
}

.game .mo-footer a {
  color: #B18F4F;
  text-transform: initial;
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
}

.game .mo-footer span {
  color: #B18F4F;
  text-transform: initial;
  font-size: 24px;
  cursor: pointer;
}
.tooltip-container{
  height: fit-content !important;
  color: #FFFFFF;
  font-weight: bold;
  background-color: #B18F4F;
  border: 1px solid #B18F4F;
}
.tooltip-container .tooltip-arrow[data-placement*='right']::after {
  border-right-color: #B18F4F !important;
}
.tooltip-arrow[data-placement*='bottom']::after {
  border-color: transparent transparent #B18F4F transparent;
}
.tooltip-container .tooltip-arrow[data-placement*='right']::before {
  border-right-color: #B18F4F !important;
}
.tooltip-container .tooltip-arrow[data-placement*='top']::after {
  border-top-color: #B18F4F !important;
}
.tooltip-container .tooltip-arrow[data-placement*='top']::before {
  border-top-color: #B18F4F !important;
}

@media screen and (max-width: 991px) {
  .game .monthly-page .single_line.bor-flex.d-flex.justify-content-between span {
    font-size: 16px;
  }
  .game .mo-footer p {
    font-size: 16px;
  }
  .game .starts_wrap.wi-600 {
    margin: 30px auto;
  }
  .game .pt-middle {
    padding-top: 8rem;
  }

}

.game .bor-titel-max {
  text-align: center;
}

/*======================Track page===================*/

@media screen and (max-width: 991px) {
  .game .banner__area .single__banner {
    min-height: 1110px;
  }

  #select-car {
    min-height: 850px;
  }

  .game .select-box::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .game .select-box::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  
  .game .select-box::-webkit-scrollbar-thumb {
    background-color: rgba(114, 114, 114, 0.5);
    border-radius: 10px;
  }

  .user-data .user-data-wrapper {
    display: flex;
    justify-content: space-around;
    width: fit-content;
    background-color: #212529;
    border-radius: 50px;
    padding: 10px 15px 5px 15px; 
    margin: 0 auto;   
    margin-top: 30px; 
  }

  .user-data .user-data-wrapper .divider {    
    border-left: 2px solid #000;
    height: 100%;
    margin-left: 1rem;    
  }

  .user-data .user-data-wrapper .divider::before {
    content: "\200D";
    display:inline;
  }  

  .user-data .user-data-wrapper .icons {
    color: #B18F4F;
    cursor: pointer;
    height: fit-content;
  }

  .user-data .user-data-wrapper .controls{
    cursor: pointer;
    height: fit-content;
    margin-left: 1rem;
  }

  .user-data .user-details {        
    text-align: center;    
    font-size: 24px; 
    height: fit-content;
    margin-left: 1rem;  
  }
  .user-data .user-details .username {
    max-width: 136px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .user-data .points {
    margin-left: 1rem;
    font-size: 20px;
    color: #B18F4F;
  }

  .user-data .score{
    margin-left: 50px;
    font-size: 18px;
  }

}

.game .container.wi-1000 {
  max-width: 1245px;
}

.game .starts_wrap.wi-290 {
  margin: 0 auto;
  max-width: 290px;
}

.game .select-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.game .select-box-item {
  background: #fff none repeat scroll 0 0;
  margin-top: 30px;
  width: 30%;
  height: 130px;
}

.game .select-box-item.gm-active {
  background: #b18f4f none repeat scroll 0 0;
}

.game .select-box-item {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  cursor: pointer;
  display: flex;
  flex-flow: column;
}

.game .select-box-item.lk-game::after {
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e1e1e+1,383838+42,383838+60,383838+60,1e1e1e+100 */
  background: #1e1e1e;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #1e1e1e 1%, #383838 42%, #383838 60%, #383838 60%, #1e1e1e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #1e1e1e 1%, #383838 42%, #383838 60%, #383838 60%, #1e1e1e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #1e1e1e 1%, #383838 42%, #383838 60%, #383838 60%, #1e1e1e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e1e1e', endColorstr='#1e1e1e', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.game .select-box-img {
  width: 100%;
  height: 70px;
  padding: 0 10px 10px 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.game .select-box-img img {
  height: fit-content;
  width: fit-content;
  align-self: center;
}

.game .select-box-items {
  padding: 10px;
  text-align: left;
  height: 40px;
}

.game .starts_wrap.wi-310 {
  max-width: 374px;
  margin: 10px auto;
}

.game .select-box-items h5, 
.game .select-box-items h6, 
.game .misano-content h5, 
.game .misano-content h6 {
  color: #fff;
  line-height: 1;
}

.game .misano-img img {
  width: 100%;
}

.game .select-box-items>h5 {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 2px;
}

.game .select-box-items>h6 {
  font-size: 10px;
  font-weight: 400;
}

.game .select-box-item p {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

@media screen and (max-width: 991px) {
  .game .starts_wrap.wi-290 {
    margin: 20px auto 100px;
    text-align: center;
  }
  .game .starts_wrap.wi-310 {
    max-width: 300px;
    margin: 20px auto 60px;
    text-align: center;
  }
}

/* =================== Misano-area ======================  */

.game .misano-dinan-box-area {
  margin-top: 30px;
}

.game .misano-items {
  position: relative;
}

.game .misano-content {
  left: 31px;
  position: absolute;
  top: 30px;
  text-align: left;
}

.game .mi-arrow-right {
  align-items: center;
  background: #b18f4f none repeat scroll 0 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 46px;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 46px;
}

.game .mi-arrow-right i {
  color: #ffff;
  font-size: 22px;
}

/* =================== Misano-area ======================  */

/*======================Track page===================*/

/*======================Rece_Start page===================*/

.game .container.wi-750 {
  max-width: 750px;
}

.game .race-starts-page-area .all-heading h2 span {
  color: #b18f4f;
  font-weight: 400;
  margin-left: 5px;
}
.game .all-heading .waiting-players {
  color: #FFFFFF;
  font-weight: bold;
  font-family: roboto, sans-serif;
} 

.game .race-starts-page-area .all-heading h2 br {
  display: none
}

.game .misano-content h5 {
  font-size: 10px;
}

.game .misano-content h6 {
  font-size: 10px;
  font-weight: 400;
}

.game .race-starts-page-area .all-car-text.car_txt, 
.game .race-summary-page-area .all-car-text.car_txt {
  margin-left: -100px;
  margin-bottom: 20px
}

.game .mt-10 {
  margin-top: 10px
}

@media screen and (max-width: 991px) {
  .game .race-starts-page-area .all-car-text.car_txt, 
  .game .race-summary-page-area .all-car-text.car_txt {
    margin-bottom: 20px;
    margin-left: 0;
  }
  .game .race-starts-page-area .all-heading h2 br {
    display: block
  }
  .game .race-starts-area {
    margin-top: 0;
  }
  .game .race-starts-area .misano-items {
    margin: 0 auto;
    max-width: 325px;
  }
  .game .race-starts-area .misano-items img {
    width: 100%
  }
  .game .race-starts-page-area .all-heading h2 span {
    font-size: 35px;
    margin-left: 0px;
  }
}

/*======================Rece_Start page===================*/

/*======================Rece Summayr page===================*/

.game .container.wi-950 {
  max-width: 950px;
}

.game .container.wi-1245 {
  max-width: 1245px;
}

.game .race-summary-page-area .starts_wrap.wi-300 {
  max-width: 300px;
  margin-top: 50px;
}

.game .starts_wrap.wi-600 {
  margin: 50px auto;
  max-width: 600px;
}

.game .starts_wrap.wi-900 {
  margin: 50px auto;
  max-width: 900px;
}

.game .all-car-text.car_txt b {
  opacity: 0
}

@media screen and (max-width: 991px) {
  .game .race-summary-page-area .bordered_title {
    position: relative;
    text-align: center;
  }
  .game .race-summary-page-area .bordered_title::after {
    background: #1A1A1D none repeat scroll 0 0;
    content: "";
    height: 82%;
    left: 0;
    position: absolute;
    top: -6px;
    width: 100%;
    z-index: -1;
  }
  .game .starts_wrap.wi-300 {
    margin: 0 auto 20px;
    max-width: 310px;
  }
  .game .single__banner.banner_bg.single__banner2.single__banner3 {
    background-image: url("/game-images/banner.png") !important;
  }
  .game .single__banner.banner_bg.single__banner2.single__banner3::after {    
    display: none;
  }
  .game .all-car-text.car_txt b {
    opacity: 1
  }
}

.game .monthly-page .single_line.d-flex.justify-content-between span {
  width: 20%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.game .monthly-page .single_line.d-flex.justify-content-between span.right.line_wrap {
  text-align: right;
}

.game .monthly-page .single_line.d-flex.justify-content-between .trigger {
  text-align: right;
}

.game .monthly-page .single_line.d-flex.justify-content-between span.crnter.line_wrap {
  text-align: center;
}

.game .monthly-page .single_line.bor-flex.d-flex.justify-content-between {
  border-bottom: 1px solid #b18f4f;
  margin-bottom: 10px;
}

.game .monthly-page .single_line.bor-flex.d-flex.justify-content-between span {
  font-size: 16px;
  line-height: 30px;
}

.game .monthly-page .single_line.d-flex.justify-content-between:hover span {
  color: #b18f4f;
}

.game .thumb img.pc-none {
  display: none;
}

.game .left_content_header h4.pc-none {
  display: none;
}

.game .banner__area.race-summary-page-area.monthly-page .all-heading {
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .game .banner__area.race-summary-page-area.monthly-page .all-heading {
    margin-bottom: 20px;
    margin-top: 70px
  }
}

/*======================Rece Summayr page===================*/

@media screen and (max-width: 1199px) {
  .game .all-car-text.car_txt, .race-summary-page-area .all-car-text.car_txt {
    margin-bottom: 20px;
    margin-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .game .header_area {
    background: #1a1a1d none repeat scroll 0 0;
    padding: 10px 0;
  }
  .game .pt-130 {
    padding-top: 100px;
  }
  .game .all-heading {
    margin-bottom: 30px;
    margin-top: 70px;
  }
  .game .car_txt b {
    color: #000;
  }
  .all-heading h2 {
    font-size: 30px;
  }
  .game .bor-titel-max h4 {
    font-size: 15px;
  }
  .game .all-car-text.car_txt {
    background: #1a1a1d none repeat scroll 0 0;
    border-radius: 50px;
    left: 50%;
    padding: 7px 30px;
    position: absolute;
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 1;
  }
  .game .start-flex {
    display: inherit;
    text-align: center;
  }
  .game .starts_wrap {
    margin-top: 0;
  }
  .game .start-game img {
    margin-left: 0;
  }
  .game .bor-titel-max h4::after {
    height: 1px;
    left: 50%;
    transform: translate(-50%);
  }
  /* ============== Track Css  ===========*/
  .game .select-box {
    margin: 0 auto;
  }
  .game .misano-dinan-box-area {
    margin: 40px auto 0;
    max-width: 325px;
  }
  .game .misano-dinan-box-area img {
    width: 100%
  }
  .game .starts_wrap.wi-210 {
    margin: 30px auto 50px;
    max-width: 325px;
  }
  .game .select-box {
    display: flex;
    flex-wrap: inherit;
    justify-content: inherit;
    overflow: auto;
    white-space: nowrap;
  }
  .game .select-box a.select-box-item {
    display: inline-table;
    height: 155px;
    margin: 0 10px;
    position: relative;
    width: 210px;
  }

  .game .select-box-img {
    width: 100%;
    height: 70px;
    padding: 0 10px 10px 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .game .select-box-img img {
    height: fit-content;
    width: fit-content;
    align-self: center;
  }
  
  .game .select-box-items {
    padding: 10px;
    text-align: left;
    height: 55px;
  }

  /* ============== Track Css  ===========*/
  .game .thumb img.mob-non {
    display: none;
  }
  .game .thumb img.pc-none {
    display: block;
  }
  .game .left_content_header h4.pc-none {
    display: block;
    color: #4E4E54;
    font-size: 15px
  }

  .game .mo-footer .box-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    background-color: transparent;
  }

  .game .mo-footer .box-container .race-again-btn {
    margin-left: 0;
  }

  .game .mo-footer .box-container .race-again-btn .race_again {
    margin-top: 1rem;
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }

  .game .mo-footer .points-obtained {
    margin-bottom: 0.5rem;
  }

  .game .mo-footer .position-msg {
    color: #FFFFFF;    
    font-size: 24px;
    font-family: roboto, sans-serif;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  .game .start-game a img {
    width: 100%;
  }

  .game .mo-footer .position-msg {
    color: #FFFFFF;    
    font-size: 22px;
    font-family: roboto, sans-serif;
    margin-bottom: 0.5rem;
  }
}

.game .slicknav_menu .slicknav_icon::before {
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 425px) {
  .game .mm-t .big_car_thumb h4 {
    margin: -50px auto 30px auto !important;
  }

  .game .mo-footer .position-msg {
    color: #FFFFFF;    
    font-size: 20px;
    font-family: roboto, sans-serif;
    margin-bottom: 0.5rem;
  }
}

.game .select-all-car-text.car_txt-in {
  position: absolute;
  top: 60px;
  left: 130px;
  z-index: 9999;
}

.game .select-all-car-text.car_txt-in p {
  color: #fff;
  margin-bottom: 0;
  color: #fff;
  font-size: 25px;
  font-weight: 400;
}

.game .select-all-car-text.car_txt-in p span {
  color: #fff;
  margin-bottom: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  color: #B18F4F;
  margin-left: 20px;
}

.game .select-all-car-text.car_txt-in p b {
  opacity: 0;
}

@media screen and (max-width: 991px) {
  .game .select-all-car-text.car_txt-in {
    background: #1a1a1d none repeat scroll 0 0;
    border-radius: 50px;
    left: 50%;
    padding: 7px 30px;
    position: absolute;
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 1;
    top: 50px
  }
  .game .select-all-car-text.car_txt-in p {
    margin-bottom: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
  }
  .game .select-all-car-text.car_txt-in p b {
    opacity: 1;
  }
  .game .single_content .starts_wrap {
    text-align: center;
  }
  .game .mm-t .banner_content .starts_wrap {
    margin-top: 18px;
  }
  .game .banner__area .single__banner .banner_content .big_car_thumb {
    padding: 0;
  }
  .game .pt-100 {
    padding-top: 30px;
  }
  .game .container .last-race {
    font-weight: 500;
  }
  .game .container .last-race .last-race-link {
    color: #B18F4F;    
    font-size: 16px;
    text-transform: lowercase;
  }

}

.game .monthly-page .lines {
  margin-top: 95px;
}

.game .lines-mid {
  margin-top: 50px;
}

.game .mt-100 {
  margin-top: 100px
}

.game .mt-120 {
  margin-top: 120px
}

.game .race-summary-page-area .all-heading {
  margin-bottom: 20px;
}
.game .race-summary-page-area .all-heading .summary-race-name {
  color: #FFFFFF;
  font-family: roboto, sans-serif;
  font-weight: 400;
}

.game .race-starts-page-area .all-car-text.car_txt {
  left: 110px;
}

.game .banner__area.game-page.track-page .all-car-text.car_txt {
  left: 40px;
}

.game .track-page .all-heading {
  margin-bottom: 60px;
}

.game .track-page .misano-dinan-box-area {
  margin-top: 25px;
}

/*  Sample track simulation */

.simlation-position-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;    
  padding: 0;  
}

.simlation-position-container .media-container {
  background-color: #1A1A1C;
  height: auto;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 11px 25px -10px #000;
}

.simlation-position-container .media-container .simulation-container {
  width: 900px;
  height: 502px;
}

.simlation-position-container .position-container {
  width: 100%;
}

.simlation-position-container .position-container .pos-sub-container {
  width: 100%;
  padding-left: 2rem;
}

.simlation-position-container .position-container .pos-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 0;
  background-color: #323236;
}

.simlation-position-container .position-container .pos-item .pos-number {
  width: 40px;
  height: 100%;
  text-align: center;
  border-right: 1px solid #616166;
  color: #FFFFFF;
  font-weight: 600;
}

.simlation-position-container .position-container .pos-item .pos-name {
  height: 100%;
  color: #616166;
  padding-left: 1rem;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
}

.simlation-position-container .position-container .is-player {
  background-color: #B18F4F !important;  
}

.simlation-position-container .position-container .is-player .pos-number {
  color: #000000;
  border-right: 1px solid #000000;
}

.simlation-position-container .position-container .is-player .pos-name {
  color: #000000;
}

.game-page .react-tabs {
  text-align: left;
}

.game-page .react-tabs__tab-list {
  border-bottom: 2px solid #D5B54B;
}

.game-page .react-tabs__tab-list li {
  color: #D5B54B;
  font-size: 20px;
  font-weight: 400;
}

.game-page .react-tabs__tab-list .react-tabs__tab--selected {
  background: #D5B54B;
  border-color: #D5B54B;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 1250px) {
  /*  Sample track simulation */
  .simlation-position-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;    
    padding: 0;  
  }

  .simlation-position-container .position-container {
    width: 50%;
    margin-top: 5rem;
  }

}

@media screen and (max-width: 991px) {
  .game .mo-footer {
    position: inherit;
    bottom: 0;
    left: inherit;
    transform: inherit;
    padding: 30px;
    text-align: center;
  }
  .game .race-summary-page-area .all-heading {
    margin-bottom: 0px;
  }
  .game .mt-100 {
    margin-top: 00px
  }
  .game .mt-120 {
    margin-top: 0px
  }
  .game .game-page .all-car-text.car_txt {
    left: 50%;
  }
  .game .race-starts-page-area .all-car-text.car_txt, .banner__area.game-page.track-page .all-car-text.car_txt {
    left: 50%;
  }
  .game .car_txt p {
    font-size: 20px;
  }
  .game .car_txt p span {
    font-size: 14px;
  }
  .game .banner__area .single__banner .banner_content .big_car_thumb h4 {
    margin-bottom: 15px;
  }
  .game .monthly-page .lines {
    margin-top: 0;
  }
  .game .monthly-page .starts_wrap.wi-600 {
    margin: 10px auto;
  }
  .game .monthly-page .single_line.bor-flex.d-flex.justify-content-between span {
    font-size: 16px;
  }
  .game .race-summary-page-area .starts_wrap.wi-300 {
    margin-top: 30px;
  }
  .simlation-position-container .media-container {
    background-color: #1A1A1C;
      height: auto;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      box-shadow: 0 11px 25px -10px #000;
  }
  .simlation-position-container .media-container .simulation-container {
    width: 100%;
    height: fit-content;
    margin: 0;
    padding: 0;
  }
  .simlation-position-container .media-container .simulation-container #miniEngineCanvas{
    width: 100%;
    height: auto;
  }

  /*  Sample track simulation */
  .simlation-position-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;    
    padding: 0;  
  }

  .simlation-position-container .position-container {
    width: 50%;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 767px) {
  .game .banner__area.mm-t .single__banner .banner_content {
    padding-top: 60px;
  }
  .game .banner_content .single_content {
    margin-top: 0px;
  }
  .simlation-position-container .media-container {
    background-color: #1A1A1C;
      height: auto;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      box-shadow: 0 11px 25px -10px #000;
  }
  .simlation-position-container .media-container .simulation-container {
    width: 100%;
    height: fit-content;
    margin: 0;
    padding: 0;
  }
  .simlation-position-container .media-container .simulation-container #miniEngineCanvas{
    width: 100%;
    height: auto;
  }

  /*  Sample track simulation */
  .simlation-position-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;    
    padding: 0;  
  }

  .simlation-position-container .position-container {
    width: 100%;
    margin-top: 2rem;
  }

  .simlation-position-container .position-container .pos-sub-container {
    width: 100%;
    padding: 2rem;
  }

  .game-page .react-tabs__tab-list {
    border-bottom: 2px solid #D5B54B;
    display: inline-flex;
    width: 100%;
  }

  .game-page .react-tabs__tab-list li {
    color: #D5B54B;
    font-size: 20px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}