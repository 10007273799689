.video-modal-container {
    width: 100%;
    min-height: 100%;
    position: fixed;
    z-index: 998;
}

.video-modal-container .background-modal {
    width: 100%;
    height: 200vh;
    position: absolute;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.6);
}

.video-modal-container .modal-inner-box {
    width: 1366px;
    height: 768px;
    position: absolute;
    z-index: 1000;
    background-color: #000000;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@media screen and (max-width: 1366px) {
   
    .video-modal-container .modal-inner-box {
        width: 1024px;
        height: 600px;
        position: absolute;
        z-index: 1000;
        background-color: #000000;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
    } 
}

@media screen and (max-width: 1024px) {
   
    .video-modal-container .modal-inner-box {
        width: 900px;
        height: 560px;
        position: absolute;
        z-index: 1000;
        background-color: #000000;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
    } 
}

@media screen and (max-width: 870px) {
   
    .video-modal-container .modal-inner-box {
        width: 600px;
        height: 400px;
        position: absolute;
        z-index: 1000;
        background-color: #000000;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
    } 
}

@media screen and (max-width: 620px) {
   
    .video-modal-container .modal-inner-box {
        width: 400px;
        height: 250px;
        position: absolute;
        z-index: 1000;
        background-color: #000000;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
    } 
}