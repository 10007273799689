footer > div.footer-desktop {
    display: flex;
    padding: 0 3rem;
}

footer > div.footer-desktop > div {
    flex: 1;
}

footer .footer-desktop img {
    width: 15rem;
}

footer .footer-desktop ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer .footer-desktop ul li {
    padding: .5rem 0;
}

footer .footer-desktop div.isologotype {
    flex: 0 0 29%;
}

footer .footer-desktop div.links-left {
    flex: 0 0 15%;
}

footer .footer-desktop div.links-right {
    flex: 0 0 19%;
}

footer .footer-desktop div.copyright {
    font-size: .73rem;
    line-height: 1.3rem;
    color: #666666;
    padding-top: .5rem;
}

footer .footer-desktop div.social {
    display: flex;
    flex: 0 0 15%;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding-top: .5rem;
}

footer .footer-desktop div.social a {
    font-size: 1.15rem;
}

footer .footer-desktop div.social i {
    flex: 0 1;
}

footer .game_stat_music_container {
    display: none;
}

@media screen and (max-width: 786px) {
    footer > div.footer-desktop {
        display: none !important;
    }
}
