.garage .no-cars {
    margin: 0;
    color: #000000;
    text-align: center;
    height: 45.9vh;
}

.garage .no-cars h2 {
    margin: 0 0 3rem 0;
    font-weight: 400;
    font-size: 1.8rem;
}

.garage .no-cars p {
    font-size: 1rem;
    padding-bottom: 3rem;
    max-width: 21rem;
    margin: 0 auto;
}

.garage .no-cars a.button.primary {
    padding: 1rem 1.6rem;
}

.garage .header {
    color: #000000;
    text-align: left;
    font-size: 1.625rem;
    padding: 2rem 0;
    max-width: 60rem;
    margin: 0 auto;
}

.garage .header i {
    font-size: 1rem;
    padding: 0 1rem;
}

.garage .cards {
    background-color: #F0EFEF;
}

.garage .cards > div {
    max-width: 60rem;
    margin: 0 auto;
    padding: 5rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.garage .cards .borrow-mark {
    position: absolute;
    display: flex;
    top: 0.75rem;
    left: 1rem;
}

.garage .cards .card .borrow-mark > span{
    margin-left: 1rem;
    color:  #B18F4F;
    font-family: roboto, sans-serif;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.garage .cards .overlay .borrow-mark-overlay-container {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
}

.garage .cards .overlay .borrow-mark-overlay-container .borrow-mark-overlay {
    display: flex;
}

.garage .cards .overlay .borrow-mark-overlay-container .borrow-mark-overlay > span{
    margin-left: 1rem;
    color: #FFFFFF;
    font-family: roboto, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
}

.garage .cards .card {
    position: relative;
    background-color: #FFFFFF;
    color: #000000;
    max-width: 18.75rem;
    text-align: left;
    margin: 0 .5rem 1.85rem .5rem;
    padding-bottom: 2.5rem;
    overflow: hidden;
}

.garage .cards .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(177, 143, 79, 0.90);
    z-index: 100;
    text-align: center;
    opacity: 0;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
}

.garage .cards .overlay:hover {
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
    opacity: 100;
}

.garage .cards .overlay > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.garage .cards .overlay > div .button.primary {
    width: 83%;
    margin: .5rem 0;
    background-color: transparent;
    border-color: #FFFFFF;
}

.garage .cards .overlay > div .borrow {
    letter-spacing: .1rem;
    border-radius: .2rem;
    text-transform: uppercase;
    border: 0.05rem solid #B18F4F;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    font-size: .8rem;
    outline: none;
}

.garage .modal-back {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}
.garage .modal {
    width: fit-content;
    height: fit-content;
    margin: auto;
    position:fixed;
    z-index: 1001;    
    display: block !important; 
}

.garage .modal {
    color: #000000;
    font-family: roboto, sans-serif;
}

.garage .modal .modal-dialog {
    max-width: 700px;
    z-index: 1002;  
}

.garage .modal .modal-header {
    padding: 1rem 1rem 1rem 3rem;
}

.garage .modal .modal-body {
    display: flex;
    padding: 1rem 3rem;
}

.garage .modal .modal-body .modal-body-container {
    float: left;
    width: 60%;
    padding-right: 2rem;
}

.garage .modal .modal-body .modal-body-container > input{
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 1rem;
}

.garage .modal .modal-body .modal-body-text {
    text-align: left;
    align-self: center;
}

.garage .modal .modal-body .modal-body-img {
    float: left;
    width: 40%;
    display: grid;
}

.garage .modal .modal-body .modal-body-img .car-name {
    font-size: 18px;
    font-weight: bold;
    font-family: roboto, sans-serif;
}

.garage .modal-footer .modal-footer {
    display: flex;
}

.garage .modal .modal-footer {
    justify-content: space-between;
    padding: 1.2rem 3rem;
}

.garage .modal .modal-footer .button-container {
    width: 60%;
    text-align: left;
}

.garage .modal .modal-footer .button-container > button {
    background-color: #B18F4F;
    border-color: #B18F4F;
    font-size: 14px;
    padding-left: 3rem;
    padding-right: 3rem;
}

.garage .modal .modal-footer .model-number-container {
    width: 40%;
    text-align: center;
}

.garage .modal .modal-footer .model-number-container .model-number {
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;
    background-color: #F0EFEF;
    max-width: 9.7rem;
    padding: .2rem .4rem;
    border-radius: .5rem;
    letter-spacing: .1rem;
    margin: 0 auto;
}

.required {
    border: 1px solid red !important;
}

.my-node-enter {
    opacity: 0;    
}
.my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;    
}
.my-node-exit {
    opacity: 1;    
}
.my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;    
}

.garage .cards .overlay > div .button.primary:hover {
    background-color: #FFFFFF;
    color: #000000;
}

.garage .cards .card img {
    max-width: 120%;
    position: relative;
    right: 10%;
}

.garage .cards .card .model-name,
.garage .cards .card .model-number {
    margin: 0 1.6rem 1rem 1.6rem;
}

.garage .cards .card .model-name {
    font-size: 2.3125rem;
    font-weight: bold;
}

.garage .cards .card .model-number {
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;
    background-color: #F0EFEF;
    max-width: 9.7rem;
    padding: .2rem 0;
    border-radius: .5rem;
    letter-spacing: .1rem;
}

.garage .cards .card .stat {
    padding: 1.5rem 1.5rem 0 1.5rem;
    display: flex;
    flex-wrap: wrap;
}

.garage .cards .card .stat-bar {
    height: 2px;
    background-color: #F0EFEF;
    flex: 1 0 85%;
    margin-top: .8rem;
}

.garage .cards .card .stat-value {
    flex: 1 0 10%;
    text-align: right;
    font-size: .9rem;
}

.garage .cards .card .stat-bar > .colored {
    height: 2px;
    background-color: #000000;
}

.garage .cards .card .stat-bar > .colored > .cover {
    height: 2px;
    background-color: #F0EFEF;
}

.garage .cards .card .stat-name {
    flex: 1 0 100%;
    text-transform: uppercase;
    color: #ABABAB;
    font-size: .6rem;
    letter-spacing: .12rem;
    font-weight: 500;
}

.garage .cards .card .stat-bar > .stat-circle {
    width: 6px;
    height: 6px;
    background-color: #000000;
    border-radius: 50rem;
    position: relative;
    margin-top: -0.25rem;
}

.garage .cards .card .mobile-buttons {
    text-align: center;
    margin-top: 2.5rem;
    display: none;
}

.garage .cards .card .mobile-buttons a {
    width: 90%;
    margin: .5rem 0;
    padding: 1rem 0;
}

@media screen and (max-width: 960px) {
    .garage .cards .card {
        max-width: 44%;
    }
}

@media screen and (max-width: 680px) {
    .garage .cards .card {
        max-width: initial;
        margin: 0 1.3rem 1.85rem 1.3rem;
    }

    .garage .cards .overlay {
        display: none;
    }

    .garage .cards .card .mobile-buttons {
        display: block;
    }
}
