.vision-wrapper {
    background-color: #FFFFFF;
    height: 100%;
    padding-bottom: 5rem;
}

.vision {
    color: #000000;
    text-align: left;
    margin: 0 auto;
}

.vision .center {
    max-width: 60rem;
}

.vision .header-image {
    background-image: url('/images/vision/1.jp2');
    background-size: cover;
}

.vision .header-image img {
    width: 100%;
}

.vision h1 {
    margin-top: 12rem;
    font-size: 2.25rem;
}

.vision p {
    font-size: 1.625rem;
    line-height: 2.5rem;
}

.vision .second-image {
    background-image: url('/images/vision/2.jp2');
    background-repeat:   no-repeat;
    background-position: center center;
    background-size: 100%;
    color: #FFFFFF;
    margin-top: 12rem;
    margin-bottom: 14rem;
}

.vision .second-image p {
    padding: 20rem 0;
}

.vision-wrapper hr {
    width: 2rem;
    margin: 0;
    border: none;
    height: 1px;
    background-color: #B18F4F;
}

.vision-wrapper .creation-hub,
.vision-wrapper .professional-work {
    text-align: left;
    margin: 10rem 0;
}

.vision-wrapper .creation-hub p,
.vision-wrapper .professional-work p {
    font-size: 1rem;
    font-weight: lighter;
    line-height: 1.5rem;
    padding-right: 4rem;
}

.vision-wrapper .creation-hub h2,
.vision-wrapper .professional-work h2 {
    font-size: 1.625rem;
    font-weight: normal;
}

.vision-wrapper .creation-hub > div,
.vision-wrapper .professional-work > div {
    color: #000000;
    margin: 0 auto;
    max-width: 60rem;
    display: flex;
}

.vision-wrapper .professional-work > div {
    flex-direction: row-reverse;
}

.vision-wrapper .creation-hub > div > div,
.vision-wrapper .professional-work > div > div {
    flex: 1 0 50%;
    padding-left: 4rem;
}

.vision-wrapper .professional-work > div > div {
    padding-left: 8rem;
}

.vision-wrapper .professional-work > div > div.image {
    padding-left: 3rem;
}

.vision-wrapper .professional-work p {
    padding-right: 1rem;
}

@media screen and (max-width: 1920px) {
    .vision .header-image {
        background-image: url('/images/vision/1-1920.jp2');
    }

    .vision .second-image {
        background-image: url('/images/vision/2-1920.jp2');
    }
}

@media screen and (max-width: 1600px) {
    .vision .second-image {
        background-image: url('/images/vision/2-1600.jp2');
    }
}

@media screen and (max-width: 1280px) {
    .vision .second-image {
        background-image: url('/images/vision/2-1280.jp2');
    }
}

@media screen and (max-width: 1035px) {
    .vision h1,
    .vision p,
    .vision .second-image p {
        padding: 0 2rem;
        line-height: 1.8rem;
    }

    .vision h1 {
        margin: 2rem 0 1rem 0;
    }

    .vision p {
        font-size: 1rem;
        font-weight: lighter;
        line-height: 1.8rem;
    }

    .vision-wrapper .creation-hub,
    .vision-wrapper .professional-work {
        padding: 2rem 2rem 0 2rem;
        margin: initial;
    }

    .vision-wrapper .creation-hub > div {
        flex-direction: column-reverse;
        max-width: 35rem;
    }

    .vision-wrapper .professional-work > div {
        flex-direction: column-reverse;
        max-width: 35rem;
    }
    .vision-wrapper .creation-hub > div h1,
    .vision-wrapper .professional-work > div h1 {
        padding-top: 1.5rem;
        font-weight: bold;
        font-size: 1rem;
    }

    .vision-wrapper .creation-hub > div p,
    .vision-wrapper .professional-work > div p {
        font-size: 1rem;
        font-weight: lighter;
        line-height: 1.5rem;
        margin-bottom: 0;
    }

    .vision-wrapper .creation-hub p,
    .vision-wrapper .professional-work p {
        padding-right: initial;
    }

    .vision-wrapper .creation-hub > div > div,
    .vision-wrapper .professional-work > div > div {
        margin-bottom: 1rem;
        padding-left: initial;
    }

    .vision-wrapper .professional-work > div > div.image {
        padding-left: initial;
    }

    .vision .second-image {
        margin: 2rem 0 2rem 0;
        background: none;
        color: #000000;
    }

    .vision-wrapper {
        padding-bottom: 2rem;
    }
}

@media screen and (max-width: 1024px) {
    .vision .header-image {
        background-image: url('/images/vision/1-1024.jp2');
    }
}

@media screen and (max-width: 960px) {
    .vision .header-image {
        background-image: url('/images/vision/1-960.jp2');
    }
}
