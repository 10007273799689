nav.desktop-menu {
    display: flex;
    padding: 1rem 3rem .5rem 3rem;
}

nav.desktop-menu > div {
    flex: 1 0 0;
    align-self: center;
    display: flex;
    padding: .3rem 0;
}

nav.desktop-menu img {
    min-width: 15rem;
}

nav.desktop-menu ul {
    display: flex;
    list-style: none;
    margin: 0 auto;
    padding: 0;
}

nav.desktop-menu ul li {
    padding: 0 .9rem;
}

nav.desktop-menu > div.logo {
    flex: 0 1 15%;
}

nav.desktop-menu > div.menu {
    flex: 0 1 65%;
}

nav.desktop-menu > div.sign-in-header {
    flex: 1;
    justify-content: flex-end;
    padding-right: 2rem;
    white-space:nowrap;    
    align-items: center;
}

nav.desktop-menu div.avatar {
    flex: 0 1 5%;
}

nav.desktop-menu div.avatar img {
    width: 3rem;
    min-width: 3rem;
    border-radius: 50%;
}

.game_playpause_container {    
    cursor: pointer;
    bottom: 0px;
    margin-right: 2rem;
    color: #FFF;
}

@media screen and (max-width: 1080px) {
    nav.desktop-menu {
        display: none;
    }
}
