.privacy-policy {
    color: #000000;
    max-width: 35rem;
    text-align: left;
    padding: 3rem 0;
}

.privacy-policy h1 {
    font-size: 2.25rem;
}

.privacy-policy a {
    letter-spacing: initial;
    text-transform: initial;
    font-size: initial;
}

@media screen and (max-width: 786px) {
    .privacy-policy {
        padding: 1rem;
    }
}
