.stat-component {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}

.stat-component .stat-bar {
    height: 2px;
    background-color: #F0EFEF;
    flex: 1 0 85%;
    margin-top: .8rem;
}

.stat-component .stat-value {
    flex: 1 0 10%;
    text-align: right;
    font-size: .9rem;
}

.stat-component .stat-bar > .colored {
    height: 2px;
    background-color: #000000;
}

.stat-component .stat-bar > .colored > .cover {
    height: 2px;
    background-color: #F0EFEF;
}

.stat-component .stat-name {
    flex: 1 0 100%;
    text-transform: uppercase;
    color: #ABABAB;
    font-size: .6rem;
    letter-spacing: .12rem;
    font-weight: 500;
}

.stat-component .stat-bar > .stat-circle {
    width: 6px;
    height: 6px;
    background-color: #000000;
    border-radius: 50rem;
    position: relative;
    margin-top: -0.25rem;
}
