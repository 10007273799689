.faq {
    color: #000000;
    max-width: 35rem;
    text-align: left;
    padding: 3rem 0;
}

.faq h1 {
    font-size: 2.25rem;
}

.faq a {
    letter-spacing: initial;
    text-transform: initial;
    font-size: initial;
}

.faq details {
    border-top: 1px solid #cdcdcd;
    padding: .7rem 0;
}

.faq details.last {
    border-bottom: 1px solid #cdcdcd;
}

.faq details,
.faq details summary {
    padding-left:0;
    background-image:none;
    outline:none;
    cursor: pointer;
}
.faq summary::-moz-list-bullet {
    list-style-type: none;
}

.faq details summary::-webkit-details-marker {
    display:none;
}

.faq summary {
    font-weight: 500;
}

.faq details summary i {
    float: right;
    color: grey;
}

.faq details[open] summary i {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

@media screen and (max-width: 580px) {
    .faq {
        padding: 1rem 0;
        margin: 0;
    }

    .faq h1,
    .faq h2 {
        padding: 0.5rem .5rem 0 .5rem;
    }

    .faq details {
        font-size: 1rem;
        padding: .7rem .5rem;
    }
}
