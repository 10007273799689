.impressum {
    color: #000000;
    max-width: 35rem;
    text-align: left;
    padding: 3rem 0;
}

.impressum h1 {
    font-size: 2.25rem;
}

.impressum a {
    letter-spacing: initial;
    text-transform: initial;
    font-size: initial;
}

@media screen and (max-width: 786px) {
    .impressum {
        padding: 1rem;
    }
}
