.toast-container {
    border: 1px solid #B18F4F;     
    margin-top: 5rem;
}

.toast-container .react-toast-notifications__toast__icon-wrapper {
    background-color: #B18F4F;
    color: #FFFFFF;
}

.error .react-toast-notifications__toast__icon-wrapper {
    background-color: #d16969 !important;        
}

.error {
    border: 1px solid #d16969 !important; 
}