.view-car {
    background-color: #FFFFFF;
}

.view-car .header {
    color: #000000;
    text-align: left;
    font-size: 1.625rem;
    padding: 2rem 0;
    max-width: 60rem;
    margin: 0 auto;
    display: flex;
}

.view-car .header > * {
    flex: 1 1 50%;
}

.view-car .header > .certificate {
    text-align: right;
    padding-right: 1rem;
}

.view-car .header img {
    height: 2rem;
    cursor: pointer;
}

.view-car .header i {
    font-size: 1rem;
    padding: 0 1rem;
}

.view-car-main-image {
    background: linear-gradient(to bottom, #F0EFEF 0%,#F0EFEF 75%,#F0EFEF 75%,white 75%,white 100%);
}

.view-car-header {
    color: #000000;
    background: #FFFFFF;
    padding-bottom: 7rem;
}

.view-car-header h1 {
    font-size: 10rem;
    padding: 0;
    margin: 0;
}

.sportcoupe .view-car-header h1 {
    font-size: 12rem;
}

.vr2 .view-car-header h1 {
    font-size: 10.5rem;
}

.view-car-header h2 {
    font-size: 2.25rem;
    padding: 3rem 0 4rem 0;
    margin: 0;
    font-weight: 400;
}

.view-car-header hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #999999;
    margin: 1rem auto 3rem auto;
}

.view-car .view-car-header h2 span {
    background-color: #F0EFEF;
    border-radius: 2rem;
    padding: .3rem 2rem;
    font-size: 1.625rem;
    text-transform: uppercase;
    letter-spacing: .23rem;
}

.view-car .button.primary.big {
    margin: 0 1rem;
    width: 20rem;
}

.view-car .button.primary.big.gift {
    background-color: #FFFFFF;
    color: #000000;
}

main .view-car div.split.white {
    padding: 1.9rem 0;
}

.statistics {
    color: #000000;
    background-color: #FFFFFF;
    padding-bottom: 11rem;
}

.statistics h3 {
    margin: 0;
    padding: 0;
    font-size: 4.5rem;
    font-weight: 400;
}

.statistics > div > div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    text-align: left;
    -webkit-justify-content: center;
    justify-content: center;
}

.statistics > div p.title {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}

.statistics > div p.title .nominator {
    font-size: 9rem;
    line-height: 1 !important;
}

.statistics > div p.title span.view-car {
    position: relative;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
    right: 0 !important;
    color: #B18F4F;
    font-family: "Roboto";
    font-weight: bold;
    padding-left: 5px;
}

.statistics > div div.image-container {
    -webkit-flex: 0 0 40%;
    flex: 0 0 40%;
    text-align: center;
}

.statistics > div .margin-right {
    margin-right: 8rem
}

.statistics > div hr {
    margin: .5rem 0;
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
}

.statistics > div p.description {
    font-weight: 300;
    line-height: 1.5rem;
    padding-bottom: 1.2rem;
    margin: 0;
    color: #000000;
}

.statistics .description-block {
    max-width: 22rem;
    margin-left: 5rem;
}

.view-car > .gallery {
    background-color: #363636;
}

.carousel .slide img {
    width: auto;
    max-width: 100%;
}

.view-car .stats {
    display: flex;
    text-align: center;
}

.view-car .stats .stats-image {
    color: #DDDDDD;
}

.view-car .stats hr {
    background-color: #ACACAC;
    border: none;
    height: 2px;
}

.view-car .stats .stats-image hr {
    background-color: #DDDDDD;
}

.view-car .stats .stats-chart hr {
    margin: .8rem auto 3rem auto;
}

.view-car .stats .title {
    text-transform: uppercase;
    font-size: .6rem;
    letter-spacing: .12rem;
    font-weight: 500;
}

.view-car .stats > div {
    padding: 0 1rem;
}

.view-car .stats hr {
    margin: .8rem auto;
    width: 15rem
}

.view-car .stats .stats-chart .title {
    color: #ACACAC;
}

.view-car .stats img {
    margin-top: 1.5rem;
}

.abyssus .view-car-main-image img {
    max-width: 85%;
}

.view-car .stats .stats-chart .stat-component {
    margin-bottom: 2rem;
}

.lend-container {
    display: none;
}

.badge-container .badge-mobile {
    display: none;
}

/* Lend Modal */

.view-car .modal-back {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}
.view-car .modal {
    width: fit-content;
    height: fit-content;
    margin: auto;
    position:fixed;
    z-index: 1001;    
    display: block !important; 
}

.view-car .modal {
    color: #000000;
    font-family: roboto, sans-serif;
}

.view-car .modal .modal-dialog {
    max-width: 700px;
    z-index: 1002;  
}

.view-car .modal .modal-header {
    padding: 1rem 1rem 1rem 3rem;
}

.view-car .modal .modal-body {
    display: flex;
    padding: 1rem 3rem;
}

.view-car .modal .modal-body .modal-body-container {
    float: left;
    width: 60%;
    padding-right: 2rem;
}

.view-car .modal .modal-body .modal-body-container > input{
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 1rem;
}

.view-car .modal .modal-body .modal-body-text {
    text-align: left;
    align-self: center;
}

.view-car .modal .modal-body .modal-body-img {
    float: left;
    width: 40%;
    display: grid;
}

.view-car .modal .modal-body .modal-body-img .car-name {
    font-size: 18px;
    font-weight: bold;
    font-family: roboto, sans-serif;
}

.view-car .modal-footer .modal-footer {
    display: flex;
}

.view-car .modal .modal-footer {
    justify-content: space-between;
    padding: 1.2rem 3rem;
}

.view-car .modal .modal-footer .button-container {
    width: 60%;
    text-align: left;
}

.view-car .modal .modal-footer .button-container > button {
    background-color: #B18F4F;
    border-color: #B18F4F;
    font-size: 14px;
    padding-left: 3rem;
    padding-right: 3rem;
}

.view-car .modal .modal-footer .model-number-container {
    width: 40%;
    text-align: center;
}

.view-car .modal .modal-footer .model-number-container .model-number {
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;
    background-color: #F0EFEF;
    max-width: 9.7rem;
    padding: .2rem .4rem;
    border-radius: .5rem;
    letter-spacing: .1rem;
    margin: 0 auto;
}

.required {
    border: 1px solid red !important;
}

.view-car .borrow-mark {
    position: absolute;
    display: flex;
    top: 0.75rem;
    left: 1rem;
}

.view-car .borrow-mark > span{
    margin-left: 1rem;
    color:  #B18F4F;
    font-family: roboto, sans-serif;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.view-car .borrow-mark-overlay-container {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
}

.view-car .borrow-mark-overlay-container .borrow-mark-overlay {
    display: flex;
}

.view-car .borrow-mark-overlay-container .borrow-mark-overlay > span{
    margin-left: 1rem;
    color: #FFFFFF;
    font-family: roboto, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
}

.view-car-header > button {
    background-color: #FF90D3 !important;
    font-family: "Roboto";
    font-size: 18px;
    max-width: 359px;
    width: 80%;
    height: 65px;
    border-radius: 32.5px;
    border: 0px solid #000000;
    transition: background-color .5 ease;
}

.view-car-header > button:hover {
    background-color: #ffb3e0 !important;
}

.view-car-header > button:focus {
    outline: 0;
}

.view-car-header .gold-hr {
    background-color: #B18F4F !important;
}

.view-car .try-luck {
    width: 100%;
    max-width: 54rem;
    margin: auto;
}

.view-car .try-luck p {
    font-size: 1.3rem;
}

/* Lend Modal End */

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .lend-container .lend {
        margin-top: 1.5rem !important;
    } 
}

@media screen and (max-width: 980px) {
    .view-car .statistics .description-block {
        display: none;
    }      
}

@media screen and (max-width: 786px) {
    .view-car-header h2 {
        padding: 3rem 0 2rem 0;
    }

    .view-car .try-luck p {
        padding: 0 3.5rem;
    }

    .view-car-main-image {
        background: linear-gradient(to bottom, #F0EFEF 0%,#F0EFEF 72%,#F0EFEF 72%,white 72%,white 100%);
    }

    .view-car-header {
        padding-bottom: 0;
    }
    .sportcoupe .view-car-header h1,
    .vr2 .view-car-header h1,
    .view-car-header h1 {
        font-size: 2.8rem;
    }

    .view-car-header h2 {
        margin: 1rem 0 2rem 0;
        font-size: 1rem;
    }

    .view-car .view-car-header h2 span {
        font-size: 1.2rem;
    }

    .view-car-header hr {
        width: 2rem;
        margin: .5rem auto 1.7rem auto;
    }

    .view-car-header a.button.primary.big {
        font-weight: normal;
        padding: .9rem 1rem;
        letter-spacing: .17rem;
    }

    /* SPLIT */
    main .view-car div.split.white {
        padding: 0;
    }

    main .view-car div.split div.image-container {
        margin: 3rem 0 2rem 0;
    }

    main .view-car div.split p.title {
        font-size: 1.4rem;
    }

    main .view-car div.split div.description-block,
    main div.split.inverted div.description-block {
        padding: 0 2.2rem;
    }

    main .view-car div.split hr {
        width: 1.7rem;
    }

    main .view-car .statistics h3 {
        margin-top: 2rem;
        font-size: 3.5rem;
    }

    .lend {
        margin-top: 1rem !important;
        padding: 0.90rem 4.1rem !important;
    }

    .badge-container .badge-pc {
        display: none;
    }

    .badge-container .badge-mobile {
        margin-top: 0.5rem;
        display: inline-block;
    }
}

@media screen and (max-width: 718px) {
    .view-car .button.primary.big.gift {
        margin-top: 1rem;
    }        

    .lend-container {
        display: block !important;
    }
}

@media screen and (max-width: 568px) {
    .view-car .stats {
        flex-direction: column;
    }

    .view-car .stats {
        flex: 1 0 100%;
    }

    .view-car .stats hr {
        width: 70%
    }

    .view-car .stats > div.stats-chart {
        margin-top: 2rem;
    }

    .view-car .stats .stats-chart .stat-component {
        padding: 0 2rem;
    }

    .view-car .statistics {
        padding-bottom: 3rem;
    }

    .view-car .button.primary.big {
        margin: 0 1rem;
        width: 17rem;
    }

}
