footer {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

footer > div.footer-mobile {
    display: none;
    padding: 0 2rem;
}

footer > div.footer-mobile > div {
    flex: 1;
}

footer .footer-mobile img {
    width: 15rem;
}

footer .footer-mobile ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer .footer-mobile ul li {
    padding: .05rem 0;
}

footer .footer-mobile div.links-left {
    flex: 0 0 34%;
}

footer .footer-mobile div.links-right {
    flex: 0 0 48%;
}

footer .footer-mobile div.links-left a,
footer .footer-mobile div.links-right a {
    font-size: .6rem;
}

footer .footer-mobile div.isologotype {
    margin-top: .2rem;
    flex: 0 0 18%;
    text-align: center;
}

footer .footer-mobile div.isologotype img {
    width: 3.1rem;
}

footer .footer-mobile div.copyright {
    font-size: .5rem;
    line-height: .8rem;
    letter-spacing: .05rem;
    color: #FFFFFF;
    padding-top: .7rem;
    text-align: center;
}

footer .footer-mobile div.social {
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    padding-top: 2rem;
}

footer .footer-mobile div.social a {
    font-size: 1.15rem;
    padding: 0 .5rem;
}

footer .footer-mobile div.social i {
    flex: 0 1;
}

@media screen and (max-width: 786px) {
    footer > div.footer-mobile {
        display: flex;
        flex-wrap: wrap;;
    }
}
