.link-wallet {
    color: #000000;
    text-align: left;
    padding: 4.3rem 0;
}

.link-wallet .center {
    max-width: 35rem;
}

.link-wallet h1 {
    font-size: 1.62rem;
    font-weight: normal;
}

.link-wallet p {
    margin: 2rem 0;
}

.wallet-linked {
    margin-top: 4rem;
}

.wallet-linked p {
    margin: 0 auto;
    max-width: 30rem;
}
