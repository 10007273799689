.design-challenge .header {
    background: url('/images/design-challenge-2/header_1920.jpg') no-repeat center center;
    background-size: auto 100%;
    background-color: #000000;
    height: 40rem;
    width: 100vw;
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 3rem;
}

.design-challenge .header > div {
    display: inline-block;
}

.design-challenge .header h1 {
    margin: 0 0 2.5rem 0;
    font-size: 7.5rem;
}

.design-challenge .header h2 {
    margin: .5rem 0;
    font-size: 3rem;
}

.design-challenge h3 {
    font-size: 3rem;
    font-weight: normal;
    margin: 0 0 1rem 0;
}

.design-challenge hr {
    margin: 0 0 1rem 0;
    width: 2.5rem;
    background-color: #B18F4F;
    border: none;
    height: 1px;
}

.design-challenge .header a.button.primary,
.design-challenge .header button.secondary {
    font-size: 1.12rem;
    width: 18.75rem;
    padding: 1.4rem 0;
    margin: 0 .93rem;
    letter-spacing: .2rem;
}

.design-challenge .arrow {
    background-color: #000000;
    height: 3.6rem;
}

.design-challenge .two-column {
    column-count: 2;
    column-gap: 40px;
}

.design-challenge p {
    margin-top: 0;
    color: #333333;
    line-height: 1.65rem;
    letter-spacing: .035rem;
    padding-right: 1rem;
}

.design-challenge .center {
    max-width: 60rem;
}

.design-challenge .about {
    padding: 7rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
}

.design-challenge .design-brief {
    color: #000000;
    background-color: #F0EFEF;
}

.design-challenge .design-brief .center {
    max-width: 90rem;
}

.design-challenge .design-brief h3 {
    font-size: 1.625rem;
}

.design-challenge .design-brief > div {
    display: flex;
}

.design-challenge .design-brief > div > div {
    flex: 1 0 41.5%;
}

.design-challenge .design-brief > div > .brief {
    flex: 1 0 58.5%;
}

.design-challenge .design-brief > div > .brief > div {
    margin: 0 auto;
    max-width: 32.9rem;
}

.design-challenge .design-brief .brief {
    padding: 6.8rem 0 5.5rem 0;
    text-align: left;
}

.design-challenge .design-brief .design-brief-image {
    background: #222222 url('/images/design-challenge-2/design_brief_721.jpg') no-repeat center center;
    background-size: 100% auto;
}

.design-challenge .pioneer-banner {
    background: url('/images/design-challenge-2/pioneer_1920.jpg') no-repeat center center;
    background-color: #8a8a8a;
    height: 36rem;
    width: 100vw;
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 3rem;
}

.design-challenge .pioneer-banner > div {
    display: inline-block;
}

.design-challenge .pioneer-banner h2 {
    font-size: 6rem;
    text-transform: uppercase;
}

.design-challenge .persona {
    padding: 7rem 0 8.8rem 0;
    color: #000000;
    text-align: left;
}

.design-challenge .persona .grey {
    color: #959595;
}

.design-challenge .persona {
    display: flex;
}

.design-challenge .persona > div {
    flex: 1 0 48%;
}

.design-challenge .persona > div p {
    margin-bottom: 2rem;
}

.design-challenge .persona > div img {
    margin: 2rem 0;
}

.design-challenge .persona .grey span {
    color: #000000;
}

.design-challenge .persona-mobile {
    display: none;
}

.design-challenge .persona .persona-right-image {
    flex: 1 0 52%;
    background-image: url('/images/design-challenge-2/persona_2_425.jpg');
    background-size: auto 100%;
    background-repeat:   no-repeat;
    background-position: right center;
}

.design-challenge .environment-banner {
    background-image: url('/images/design-challenge-2/header_1920.jpg');
    background-repeat:   no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    height: 29.75rem;
}

.design-challenge .environment {
    padding: 7rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
    background-color: #F0EFEF;
}

.design-challenge .environment .two-column {
    margin-bottom: 3rem;
}

.design-challenge .environment .images {
    display: flex;
    justify-content: space-between;
}

.design-challenge .environment .images div {
    flex: 0 0 47.8%;
}

.design-challenge .environment .images div img {
    box-shadow: 0 78px 40px -50px #a9a9a9
}

.design-challenge .music {
    padding: 7rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
}

.design-challenge .music {
    display: flex;
}

.design-challenge .music > div {
    flex: 1 0 50%;
    margin: auto;
}

.design-challenge .music .text-left {
    padding-right: 1rem;
}

.design-challenge .music > div img {
    margin: 2rem 0;
}

.design-challenge .music .spotify {
    text-align: right;
}

.design-challenge .spotify-mobile {
    display: none;
}

.design-challenge .color-palette {
    padding: 7rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
    background-color: #F0EFEF;
}

.design-challenge .color-palette .center > div {
    display: flex;
}

.design-challenge .color-palette .center > div > div {
    flex: 1 0 50%;
}

.design-challenge .color-palette .center > div > div.colors {
    padding-left: 2rem;
    align-content: flex-start;
}

.design-challenge .color-palette .colors {
    display: flex;
    flex-wrap: wrap;
}

.design-challenge .color-palette .colors .color-1 {
    background-color: #1D1E20;
    flex: 1 0 21.97%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-2 {
    background-color: #4D4E52;
    flex: 1 0 29.82%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-3 {
    background-color: #747579;
    flex: 1 0 10.98%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-4 {
    background-color: #E3E1E2;
    flex: 1 0 18.60%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-5 {
    background-color: #E2C1B1;
    flex: 1 0 10.76%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .color-6 {
    background-color: #AE9084;
    flex: 1 0 7.84%;
    height: 4.37rem;
}

.design-challenge .color-palette .colors .rgb {
    flex: 1 0 100%;
    text-align: right;
    color: #8a8a8a;
    font-size: .8rem;
    margin-top: .4rem;
}

.design-challenge .take-part {
    padding: 10.5rem 2rem 8.8rem 2rem;
    background-color: #000000;
    text-align: left;
}

.design-challenge .take-part h3 {
    font-size: 1.62rem;
}

.design-challenge .take-part .two-column {
    column-gap: 40px;
}

.design-challenge .take-part p {
    color: #FFFFFF;
    padding-right: 0;
}

.design-challenge .take-part .timeline {
    background-image: url('/images/design-challenge-2/time_line_1440.jpg');
    background-repeat:   no-repeat;
    background-position: center center;
    background-size: auto 100%;
    height: 37.25rem;
    margin-top: 7.5rem;
}

.design-challenge .take-part .register {
    text-align: center;
}

.design-challenge .take-part .register a {
    width: 18.75rem;
    padding: 1.4rem 0;
    font-size: 1.12rem;
    margin-top: 8.5rem;
    letter-spacing: .16rem;
}

.design-challenge .prizes-and-rules {
    padding: 10rem 2rem 10rem 2rem;
    color: #000000;
    text-align: left;
    background-color: #F0EFEF;
}


.design-challenge .prizes-and-rules h3 {
    font-size: 1.62rem;
}

.design-challenge .prizes-and-rules p {
    padding-right: 0;
    margin-bottom: 1.5rem;
}

.design-challenge .prizes-and-rules p.last {
    position: absolute;
    bottom: 0;
}

.design-challenge .prizes-and-rules a {
    color: #000000;
    letter-spacing: initial;
    text-decoration: underline;
    text-transform: initial;
    font-size: 1rem;
}

.design-challenge .prizes-and-rules .center > div {
    display: flex;
    justify-content: space-between;
}

.design-challenge .prizes-and-rules .center > div > div {
    flex: 0 1 48.5%;
    position: relative;
    padding-bottom: 3.5rem;
}

.design-challenge .partners-and-sponsors {
    padding: 9rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
}

.design-challenge .partners-and-sponsors h3 {
    font-size: 1.62rem;
}

.design-challenge .partners-and-sponsors p {
    padding-right: 0;
}

.design-challenge .partners-and-sponsors .two-column {
    padding-bottom: 11rem;
    max-height: 25rem;
}

.design-challenge .partners-and-sponsors .logos {
    display: flex;
}

.design-challenge .partners-and-sponsors .logos > div {
    flex: 1 0 2%;
    margin: auto;
    position: relative;
}

.design-challenge .partners-and-sponsors .logos .gravity-sketch,
.design-challenge .partners-and-sponsors .logos .auctionity {
    flex: 1 0 46%;
}

.design-challenge .partners-and-sponsors .logos > div img {
    position: absolute;
    bottom: 0;
}

.design-challenge .partners-and-sponsors .logos > div.auctionity img {
    bottom: 3.9rem;
}

.design-challenge .partners-and-sponsors .logos .gravity-sketch img {
    max-width: 15rem;
}

.design-challenge .powered-by {
    padding: 6rem 2rem 8.8rem 2rem;
    color: #000000;
    text-align: left;
}

.design-challenge .powered-by h3 {
    font-size: 1.62rem;
}

.design-challenge .powered-by .logos {
    margin-top: 3rem;
    display: flex;
}

.design-challenge .powered-by .logos > div {
    flex: 1 0 50%;
    margin: auto;
}

.design-challenge .powered-by .logos > div img {
    width: 85%;
}

.design-challenge .powered-by .logos > div.ethereum {
    padding-left: 1rem;
}

.design-challenge .partners-and-sponsors .gravity-sketch-mobile {
    display: none;
}

.design-challenge .join-now {
    background-color: #000000;
    background-image: url('/images/design-challenge-2/bottom_banner_1920.jpg');
    background-repeat:   no-repeat;
    background-position: center center;
    background-size: auto 100%;
    background-attachment: fixed;
    height: 38rem;
    width: 100vw;
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 3rem;
}

.design-challenge .join-now > div {
    display: inline-block;
}

.design-challenge .join-now h2 {
    font-size: 7.5rem;
    margin-bottom: 2.5rem;
}

.design-challenge .join-now a.button.primary.big {
    width: 18.75rem;
    padding: 1.4rem 0;
}

.design-challenge .environment div.environment-image-1-mobile {
    display: none;
}
.design-challenge .prizes-and-rules a.normal-link,
.partners-and-sponsors a {
    color: #B18F4F;
    letter-spacing: initial;
    text-transform: initial;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (max-width: 1490px) {
    .design-challenge .environment-banner {
        background-size: auto 100%;
    }
}

@media screen and (max-width: 1090px) {
    .design-challenge .design-brief > div {
        flex-direction: column-reverse;
    }

    .design-challenge .design-brief .design-brief-image {
        height: 21rem;
        background-size: auto 100%;
    }
}

@media screen and (max-width: 1040px) {
    .design-challenge .prizes-and-rules .center > div {
        flex-direction: column;
    }

    .design-challenge .prizes-and-rules .center > div {}

}

@media screen and (max-width: 892px) {
    .design-challenge .two-column {
        column-count: 1;
        column-gap: initial;
    }

    .design-challenge p {
        padding-right: 0;
    }

    .design-challenge .partners-and-sponsors .two-column {
        max-height: initial;
    }

    .design-challenge .persona {
        flex-direction: column;
    }

    .design-challenge .persona-desktop {
        display: none;
    }

    .design-challenge .persona-mobile {
        display: block;
        text-align: center;
        padding-bottom: 2rem;
    }

    .design-challenge .persona .persona-right-image {
        height: 43.18rem;
        background-position: center center;
        margin: 4rem 0;
    }

    .design-challenge .about,
    .design-challenge .design-brief .brief,
    .design-challenge .music,
    .design-challenge .prizes-and-rules,
    .design-challenge .partners-and-sponsors {
        padding: 4rem 3rem;
    }

    .design-challenge .environment,
    .design-challenge .take-part {
        padding: 4rem 2.3rem;
    }

    .design-challenge .environment h3,
    .design-challenge .take-part h3,
    .design-challenge .environment p,
    .design-challenge .take-part p {
        padding: 0 .7rem;
    }

    .design-challenge .environment hr,
    .design-challenge .take-part hr {
        margin-left: .7rem;
    }

    .design-challenge .color-palette {
        padding: 4rem 0;
    }

    .design-challenge .color-palette h3,
    .design-challenge .color-palette p {
        padding: 0 3rem;
    }

    .design-challenge .color-palette hr {
        margin-left: 3rem;
    }

    .design-challenge .color-palette .center > div > div.colors {
        margin: 4rem 0 3rem 0;
    }

    .design-challenge .color-palette .center > div > div.colors .rgb {
        padding: .5rem 2rem;
    }

    .design-challenge .persona {
        padding: 3rem 0;
    }

    .design-challenge .persona > div h3,
    .design-challenge .persona > div > div > * {
        padding: 0 2.7rem;
    }

    .design-challenge .persona .persona-mobile {
        padding: 0 1.5rem;
    }

    .design-challenge .persona > div hr {
        margin-left: 3rem;
    }

    .design-challenge .persona > div p {
        margin-bottom: 0;
    }

    .design-challenge .persona > div img {
        margin: 2rem 0 3.5rem 0;
    }

    .design-challenge .music .text-left {
        padding-right: 0;
    }

    .design-challenge .partners-and-sponsors .logos .gravity-sketch {
        display: none;
    }

    .design-challenge .partners-and-sponsors .logos > div {
        flex: initial;
    }

    .design-challenge .partners-and-sponsors .two-column {
        padding-bottom: 8rem;
    }

    .design-challenge .partners-and-sponsors .gravity-sketch-mobile {
        display: block;
        padding: 5rem 1.5rem;
        text-align: center;
    }

    .design-challenge .join-now {
        height: 14rem;
        background-attachment: initial;
    }

    .design-challenge .join-now a.button.primary.big {
        width: 18.45rem;
        padding: 1rem 0;
    }

    .design-challenge .join-now h2 {
        margin-top: 4rem;
        margin-bottom: 1rem;
    }

    .design-challenge .take-part .timeline {
        background-image: url(/images/design-challenge-2/time_line_mobile_373.jpg);
        height: 68.5rem;
        margin-top: 2rem;
    }

    .design-challenge .take-part .register a {
        margin-top: 2rem;
    }

    .design-challenge .environment .images div.environment-image-1 {
        display: none;
    }

    .design-challenge .environment div.environment-image-1-mobile {
        display: block;
        padding: 3rem 0;
        text-align: center;
    }

    .design-challenge .environment .images div.environment-image-2 {
        padding-bottom: 5rem;
        text-align: center;
    }

    .design-challenge .environment .images div {
        flex: 1 0 100%;
    }

    .design-challenge .prizes-and-rules p.last {
        position: initial;
    }

    .design-challenge .powered-by .logos > div.ethereum {
        padding-left: initial;
    }

    .design-challenge .powered-by .logos > div img {
        width: 100%;
        padding: 1rem 3rem;
    }

    .design-challenge .powered-by {
        padding-bottom: 2rem;
    }

    .design-challenge .partners-and-sponsors .logos > div.auctionity img {
        bottom: 0;
    }
}

@media screen and (max-width: 780px) {
    .design-challenge .header {
        height: 37.8rem;
        background-position: -37rem center;
    }

    .design-challenge .header a.button.primary {
        width: 16.45rem;
        padding: 1rem 0;
        margin-top: 3rem;
        font-size: 1rem;
    }

    .design-challenge .header h1 {
        font-size: 3.68rem;
        line-height: 3.8rem;
    }

    .design-challenge .header h2 {
        font-size: 1.87rem;
        padding: .5rem 0;
        margin-top: 3rem;
    }

    .design-challenge .pioneer-banner {
        height: 15.43rem;
        padding-bottom: initial;
        background-attachment: initial;
        background-size: auto 120%;
    }

    .design-challenge .pioneer-banner h2 {
        font-size: 4.18rem;
    }

    .design-challenge .music {
        flex-direction: column;
    }

    .design-challenge .join-now h2 {
        font-size: 2.37rem;
    }

    .design-challenge .color-palette .center > div {
        flex-direction: column;
    }

    .design-challenge .color-palette .center > div > div.colors {
        padding-left: 0;
    }
}

@media screen and (max-width: 594px) {
    .design-challenge .header {
        background: url('/images/design-challenge-2/header_1920.jpg') no-repeat center center;
    }

    .design-challenge .header button.secondary {
        display: none;
    }

    .design-challenge .arrow {
        display: none;
    }

    .design-challenge .environment-banner {
        background-position: -50rem center;
    }

    .design-challenge .spotify {
        display: none;
    }

    .design-challenge .music > div.spotify-mobile {
        margin-top: 2.5rem;
        display: block;
    }

    .design-challenge .powered-by .logos {
        display: initial;
    }

    .design-challenge .pioneer-banner {
        background-size: auto 100%;
        background-position-x: -6rem;
    }
}

@media screen and (max-width: 390px) {
    .design-challenge .persona .persona-right-image {
        box-shadow: 0 55px 40px -50px #a9a9a9;
    }
}
