.recover-password {
  color: #000000;
  background-color: #F0EFEF;
  text-align: left;
  height: 100%;
  padding: 0 1rem 5rem 1rem;
}

.recover-password > div {
  max-width: 35rem;
  margin: 0 auto;
}

.recover-password h1 {
  font-size: 2rem;
  font-weight: initial;
  margin: 0;
  padding: 6rem 0 .5rem 0;
}

.recover-password p {
  letter-spacing: .05rem;
  padding: 1rem 0;
}

.recover-password button {
  margin: 2rem 0;
  padding: 1rem 3rem;
}

.recover-password hr {
  border: none;
  height: .1rem;
  width: 1.5rem;
  background-color: #B18F4F;
  margin: 0;
}

.recover-password button {
  width: 8.75rem;
}
