#circleG{
width:60.666666666666664px;
}

.circleG{
background-color:#FFFFFF;
float:left;
height:13px;
margin-left:7px;
width:13px;
-moz-animation-name:bounce_circleG;
-moz-animation-duration:1.9500000000000002s;
-moz-animation-iteration-count:infinite;
-moz-animation-direction:normal;
-moz-border-radius:9px;
-webkit-animation-name:bounce_circleG;
-webkit-animation-duration:1.9500000000000002s;
-webkit-animation-iteration-count:infinite;
-webkit-animation-direction:normal;
-webkit-border-radius:9px;
-ms-animation-name:bounce_circleG;
-ms-animation-duration:1.9500000000000002s;
-ms-animation-iteration-count:infinite;
-ms-animation-direction:normal;
-ms-border-radius:9px;
-o-animation-name:bounce_circleG;
-o-animation-duration:1.9500000000000002s;
-o-animation-iteration-count:infinite;
-o-animation-direction:normal;
-o-border-radius:9px;
animation-name:bounce_circleG;
animation-duration:1.9500000000000002s;
animation-iteration-count:infinite;
animation-direction:normal;
border-radius:9px;
}

#circleG_1{
-moz-animation-delay:0.39s;
-webkit-animation-delay:0.39s;
-ms-animation-delay:0.39s;
-o-animation-delay:0.39s;
animation-delay:0.39s;
}

#circleG_2{
-moz-animation-delay:0.9099999999999999s;
-webkit-animation-delay:0.9099999999999999s;
-ms-animation-delay:0.9099999999999999s;
-o-animation-delay:0.9099999999999999s;
animation-delay:0.9099999999999999s;
}

#circleG_3{
-moz-animation-delay:1.1700000000000002s;
-webkit-animation-delay:1.1700000000000002s;
-ms-animation-delay:1.1700000000000002s;
-o-animation-delay:1.1700000000000002s;
animation-delay:1.1700000000000002s;
}

@-moz-keyframes bounce_circleG{
0%{
}

50%{
background-color:#000000}

100%{
}

}

@-webkit-keyframes bounce_circleG{
0%{
}

50%{
background-color:#000000}

100%{
}

}

@-ms-keyframes bounce_circleG{
0%{
}

50%{
background-color:#000000}

100%{
}

}

@-o-keyframes bounce_circleG{
0%{
}

50%{
background-color:#000000}

100%{
}

}

@keyframes bounce_circleG{
0%{
}

50%{
background-color:#000000}

100%{
}

}
