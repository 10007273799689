.drop-event-container {
  width: 100%;
  height: 100%;
  background-color: #1c1c1c;
  font-family: "Roboto";
}

.drop-event-container .event-banner {
  width: 100%;
  position: relative;
  font-family: "Roboto";
}

.drop-event-container .event-banner > img {
  width: 100%;
}

.drop-event-container .event-banner > h1 {
  width: 100%;
  font-size: 65px;
  font-weight: 700;
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%);
  width: 90%;
}

.drop-event-container .event-banner > h2 {
  width: 100%;
  font-size: 22px;
  position: absolute;
  left: 50%;
  bottom: 7%;
  transform: translate(-50%);
}

.drop-event-container .event-info-container {
  width: 100%;
  height: auto;
}

#rocket-units-long {
  display: none;
}

.presentation-video-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 5rem;
}

.presentation-video-container > div {
  width: 996px;
  margin-top: 3rem;
}

.presentation-video-container > h2 {
  margin: 3rem 10rem;
  max-width: 40rem;
}

.presentation-video-container .event-sub-title {
  display: block;
  width: 80%;
  font-size: 30px;
  margin-bottom: 3rem;
  font-weight: normal;
}

.presentation-video-container > div > div {
  width: 100%;
}

#scott-robertson p {
  font-size: 20px;
  padding: 0 2rem;
}

.event-info-container > .down-arrow {
  display: none;
}

.event-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-info-container > h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.introducing {
  font-size: 21px;
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.introducing span {
  font-size: 26px;
}

.introducing img {
  max-width: 90%;
}

.italics p {
  font-style: italic;
}

.introducing p {
  max-width: 68rem;
  margin-top: 1rem;
}

.opaque {
  background-color: #7f655a !important;
  border: none !important;
}

hr.separator {
  width: 70px;
  background-color: #b18f4f;
}

.models-units {
  height: 11rem;
  padding: 0 2rem;
}

.models-units img {
  margin-top: -5rem;
}

.drop-event-content {
  width: 100%;
}

.drop-event-content .outer-box {
  width: 1200px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: auto;
  background-position-x: center;
  padding-top: 5rem;
}

.drop-event-content .outer-box .inner-box {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outer-box .inner-box .content-info {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.outer-box .inner-box .content-info-batches {
  margin-bottom: 2rem;
}

.content-image .total-table {
  background-color: #b576b9;
  border-radius: 17px;
  /* padding: 1.5rem 1rem; */
}

.content-image .total-table .table-inner {
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.content-image .total-table .table-inner > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 1rem; */
  flex: 1;
  height: 150px;
  min-width: 140px;
}

.content-image
  .total-table
  .table-inner
  > div:nth-child(1)
  > span:nth-child(2) {
  font-size: 60px;
  font-weight: lighter;
  margin-top: 0;
}

.content-image .total-table .table-inner > div:nth-child(3) > span:nth-child(2),
.content-image
  .total-table
  .table-inner
  > div:nth-child(2)
  > span:nth-child(2) {
  font-size: 14px;
  font-weight: normal;
}

/* .content-image .total-table .table-inner > div > span:nth-child(2) {
  margin-top: 1rem;
} */

.content-image .total-table .table-inner > div:not(:nth-child(3)) {
  border-right: 1px solid #000000;
}

.content-image .total-table .table-inner > div > span:nth-child(1) {
  font-weight: bold;
}

.content-info > .content-text {
  width: 550px;
}

.content-info > .content-image {
  height: 380px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-info > .content-image > img {
  width: 450px;
  height: 100%;
}

.content-info > .content-text {
  padding: 2rem;
  text-align: center;
}

.content-info > .content-text > span {
  font-weight: bold;
  font-size: 19px;
}

.content-info > .content-text > p {
  font-size: 19px;
  line-height: 35px;
  font-weight: 300;
  margin-top: 1rem;
}

.launch {
  margin-top: -4rem;
}

.batches-section {
  width: 376px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.batches-image,
.batches-image > img {
  width: 624px !important;
  height: initial !important;
}

.batches-section > span:nth-child(8),
.batches-section > span:nth-child(5) {
  margin-top: 1rem !important;
}

.batches-section > span:nth-child(1) {
  font-weight: bold;
  font-size: 19px;
  margin-bottom: 1rem;
}

.batches-section > span:not(:nth-child(1)) {
  font-weight: 500;
  font-size: 16px;
}

.batches-section > p {
  font-weight: 300;
  font-size: 15px !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal !important;
}

.drop-event-container .asterisk {
  width: 100%;
  margin-bottom: 5rem;
}

.drop-event-container .asterisk > span {
  margin: 0 auto;
  color: #b18f4f;
  font-size: 18px;
  font-weight: 500;
}

.drop-event-container .will-be-winner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.drop-event-container .will-be-winner > span:nth-child(1) {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 3rem;
}

.drop-event-container .will-be-winner > span:nth-child(2) {
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 3rem;
}

.drop-event-container .will-be-winner > p {
  font-size: 20px;
  width: 90%;
}

.content-info .design-link {
  text-transform: none !important;
  font-size: 19px;
}

.drop-event-container .buy-now-bottom {
  margin-bottom: 7rem;
}

.drop-event-container .logos-contianer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drop-event-container .logos-container > div:nth-child(1) {
  width: 70px;
  height: 2px;
  margin: 0 auto;
  border-top: 1px solid #b18f4f;
}

.drop-event-container .logos-container > div:nth-child(2) {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logos-container > div:nth-child(2) > div:not(:nth-child(1)) {
  margin-left: 2rem;
}

@media screen and (max-width: 1200px) {
  .drop-event-container .event-banner > h1 {
    font-size: 50px;
    bottom: 20%;
  }

  .drop-event-container .event-banner > h2 {
    width: 90%;
    font-size: 22px;
    position: absolute;
    left: 50%;
    bottom: 7%;
    transform: translate(-50%);
  }

  .drop-event-content .outer-box {
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 98%;
    background-position-x: center;
    padding-top: 2rem;
  }

  .drop-event-content .outer-box .inner-box {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .introducing p {
    max-width: 90%;
    margin: 1.5rem 2rem;
  }
}

@media screen and (max-width: 990px) {
  .drop-event-container .event-banner > img {
    width: 100%;
  }

  .with-margin {
    margin-top: 0rem;
  }

  #design-legends-log {
    width: 90%;
    margin-top: 19rem;
    font-size: 35px;
  }

  .drop-event-container .event-banner > h1 {
    margin-bottom: auto;
    bottom: 4%;
    max-width: 100%;
  }

  .drop-event-container .event-banner > h2 {
    display: none;
  }

  .introducing p {
    max-width: 80%;
  }

  #rocket-units-long {
    display: block;
  }

  #rocket-units {
    display: none;
  }

  .presentation-video-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 10rem;
  }

  .presentation-video-container > div {
    width: 100%;
  }

  .presentation-video-container > div > div > .video {
    width: 100%;
  }

  .event-info-container > .separator {
    display: none;
  }

  .event-info-container > .down-arrow {
    display: block;
    margin-top: 3rem;
  }

  .event-info-container > .down-arrow > img {
    width: 50px;
  }

  .drop-event-content .outer-box {
    width: 100%;
    margin: 0 auto;
    background-image: none;
    padding-top: 5rem;
  }

  .outer-box .inner-box .content-info {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .buy-now-link a {
    margin-top: 8rem;
  }

  .content-info > .content-text {
    padding: 2rem;
    text-align: center;
  }

  .content-info > .content-text > span {
    font-weight: bold;
    font-size: 19px;
  }

  .content-info > .content-text > p {
    font-size: 19px;
    line-height: 35px;
    font-weight: 300;
    margin-top: 1rem;
  }

  .drop-event-content > div > div > div:nth-child(1) {
    margin-bottom: 1rem !important;
  }

  .drop-event-content > div > div > div:nth-child(2) {
    flex-direction: column-reverse !important;
    margin-bottom: 1rem !important;
  }

  .batches-section > span:not(:nth-child(1)) {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
  }

  .batches-section > p {
    font-weight: 300;
    font-size: 15px !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important;
    color: rgba(255, 255, 255, 0.5);
  }

  .drop-event-content > div > div > div:nth-child(3) {
    margin-bottom: 1rem !important;
  }

  .drop-event-container .asterisk {
    width: 100%;
    margin-bottom: 2rem;
  }

  .drop-event-container .will-be-winner > span:nth-child(2) {
    color: rgba(255, 255, 255, 0.5);
  }

  .models-units img {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .drop-edition {
    padding: 0 2rem;
  }

  .drop-event-container .event-banner > h1 {
    font-size: 30px;
  }

  .content-info > .content-text {
    width: 90% !important;
  }

  #about-the-drop {
    margin-bottom: 3rem;
  }

  .content-info > .content-image {
    width: 90% !important;
    height: 100%;
  }

  .content-info > .content-image > img {
    width: 90% !important;
    height: 100%;
  }

  .model-units img {
    margin-top: -50rem !important;
  }

  .content-image .total-table {
    width: 90%;
    background-color: #b576b9;
    border-radius: 17px;
    padding: 1.5rem 0;
  }

  .drop-event-content .outer-box .inner-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .outer-box .inner-box .content-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-image .total-table .table-inner > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    flex: 1 1;
    height: 150px;
  }

  .drop-event-container .will-be-winner > span:nth-child(1) {
    width: 90%;
    font-size: 30px;
  }

  .drop-event-container .will-be-winner > span:nth-child(2) {
    width: 90%;
    font-size: 22px;
  }
  .will-be-winner {
    margin-bottom: 15rem;
  }
}

@media screen and (max-width: 425px) {
  .visit-car-page {
    padding: 1.4rem 2rem !important;
  }

  .drop-event-container .event-banner > h1 {
    font-size: 25px;
  }

  .content-info > .content-text {
    width: 100% !important;
    height: 100%;
  }

  .content-info > .content-image {
    width: 100% !important;
    height: 100%;
  }

  .content-info > .content-image > img {
    width: 100% !important;
    height: 100%;
  }

  .content-image .total-table {
    width: 98%;
    background-color: #b576b9;
    border-radius: 17px;
    padding: 1.5rem 0;
  }

  .drop-event-content .outer-box .inner-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .outer-box .inner-box .content-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-image .total-table .table-inner > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    flex: 1 1;
    height: 150px;
  }

  .drop-event-container .will-be-winner > span:nth-child(1) {
    width: 95%;
    font-size: 30px;
  }

  .drop-event-container .will-be-winner > span:nth-child(2) {
    width: 95%;
    font-size: 22px;
  }

  .content-image
    .total-table
    .table-inner
    > div:nth-child(3)
    > span:nth-child(2),
  .content-image
    .total-table
    .table-inner
    > div:nth-child(2)
    > span:nth-child(2) {
    font-size: 12px;
    font-weight: normal;
  }

  .content-image
    .total-table
    .table-inner
    > div:nth-child(1)
    > span:nth-child(2) {
    font-size: 50px;
  }

  .content-image .total-table .table-inner > div {
    min-width: auto;
  }
}
