.about {
    color: #000000;
}

.about .leandro {
    background-color: #000000;
    color: #FFFFFF;
    padding: 5rem 0;
}

.about .whitepaper {
    padding: 5rem 0;
}

.about .leandro hr,
.about .whitepaper hr {
    width: 2rem;
    margin: 0;
    border: none;
    height: 1px;
    background-color: #B18F4F;
}

.about .leandro .image ,
.about .whitepaper .image {
    margin: auto;
}

.about .whitepaper .image {
    margin-right: 4rem;
}

.about .whitepaper .image img {
    -webkit-box-shadow: 0 55px 40px -50px #b9b9b9;
    -moz-box-shadow: 0 55px 40px -50px #b9b9b9;
    box-shadow: 0 55px 40px -50px #a9a9a9;
}
.about .leandro > div,
.about .whitepaper > div {
    margin: 0 auto;
    max-width: 60rem;
    display: flex;
}

.about .leandro > div > div,
.about .whitepaper > div > div {
    flex: 1 0 50%;
    margin: auto;
}

.about .whitepaper > div > div {
    text-align: left;
}

.about .whitepaper a {
    color: #000000;
}

.about .leandro h1 {
    font-size: 1rem;
    font-weight: lighter;
}

.about .leandro p {
    text-align: left;
    font-size: 1.625rem;
    line-height: 2.5rem;
}

.about .whitepaper h1 {
    font-size: 1.625rem;
    font-weight: normal;
}

.about .whitepaper p {
    text-align: left;
    font-size: 1rem;
    font-weight: lighter;
    line-height: 1.5rem;
}

.about div.top-section,
.about div.team-title {
    max-width: 35rem;
    margin: 0 auto;
}

.about div.top-section p {
    text-align: left;
    line-height: 1.5rem;
}

.about h1 {
    font-size: 2.25rem;
    text-align: left;
}

/* OUR TEAM */
.about .our-team {
    background-color: #F0EFEF;
    color: #000000;
}

.about .our-team h2 {
    margin: 0;
    font-weight: normal;
    font-size: 2.25rem;
    padding: 4rem 0 0 0;
}

.about .our-team .members {
    max-width: 35rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-bottom: 4rem;
}

.about .our-team .members .member {
    flex: 1 0 33.33%;
    padding-bottom: 2.5rem;
}

.about .our-team .members .member .title {
    padding: .6rem;
}

.about .our-team .members .member img {
    width: 11rem;
}

/* CONTACT */
.about .contact {
    color: #000000;
    padding: 8rem 0;
}

.about .contact h2 {
    margin: 0;
    font-weight: normal;
    font-size: 4.5rem;
}

.about .contact hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #B18F4F;
    width: 3rem;
    color: #B18F4F;
    padding-bottom: 2rem;
}

.about .contact textarea {
    margin: 1.5rem 0 2.37rem 0;
}

@media screen and (max-width: 786px) {
    .about .contact {
        padding: 4rem 1rem;
    }

    .about .our-team .members {
        padding-bottom: 4rem;
    }

    .about .our-team .members .member {
        flex: 1 0 50%;
    }

    .about .our-team h2 {
        padding: 4rem 0;
    }
}

@media screen and (max-width: 1035px) {
    .about .leandro,
    .about .whitepaper {
        padding: 4rem 0 2rem 0;
    }

    .about .leandro > div {
        flex-direction: column-reverse;
        max-width: 35rem;
        padding: 0 1rem;
    }

    .about .whitepaper > div {
        flex-direction: column;
        max-width: 35rem;
        padding: 0 1rem;
    }
    .about .leandro > div h1,
    .about .whitepaper > div h1 {
        padding-top: 1.5rem;
        font-weight: bold;
        font-size: 1rem;
    }

    .about .leandro > div p,
    .about .whitepaper > div p {
        font-size: 1rem;
        font-weight: lighter;
        line-height: 1.5rem;
    }

    .about .whitepaper .image {
        margin-right: initial;
    }
}

@media screen and (max-width: 450px) {
    .about .our-team .members .member {
        flex: 1 0 100%;
        padding-bottom: initial;
    }

    .about .our-team h2 {
        padding: 2rem 0 0 0;
        font-weight: bold;
    }

    .about .our-team .members .member img {
        width: 13rem;
    }

    .about .contact h2 {
        text-align: left;
        font-weight: bold;
        font-size: 2rem;
    }

    .about .contact hr {
        width: 1.3rem;
        display: table-caption;
        padding-bottom: .7rem;
        margin-top: 0.2rem;
    }

    .about .contact textarea {
        margin: 1.5rem 0 .7rem 0
    }

    .about .contact .send {
        text-align: left;
    }
}
