.settings {
    color: #333333;
    background-color: #FFFFFF;
    text-align: left;
    padding: 4.3rem 0;
}

.settings .center {
    max-width: 35rem;
}

.settings h1 {
    color: #000000;
    font-size: 1.62rem;
    font-weight: normal;
}

.settings .eu-resident {
    margin: 1.3rem 0 .5rem 0;
}

.settings input[type="radio"] {
    vertical-align: baseline;
    width: 1rem;
    height: 1rem;
}

.settings .username-email {
    margin: 1.3rem 0;
}

.settings .three-section {
    display: flex;
    margin: .7rem 0;
}

.settings .three-section div {
    flex: 1;
}

.settings .three-section .address {
    flex: 0 0 30%;
}

.settings .three-section .bold {
    flex: 0 1 55%;
}

.settings .three-section div.edit {
    flex: 0 1 15%;
    text-align: right;
}

.settings .saving,
.settings-modal .saving {
    margin: 1rem 0;
    font-size: .8rem;
}

.settings-modal h1 {
    color: #000000;
    font-size: 1.62rem;
    font-weight: normal;
}

.settings-modal button.secondary {
    color: #000000;
}

.settings-modal .buttons {
    text-align: right;
}

.settings-modal .buttons button {
    margin-top: .5rem;
    margin-left: .5rem;
}

@media screen and (max-width: 786px) {
    .settings {
        padding: 2rem 0;
    }

    .settings h1,
    .settings .eu-resident-option,
    .settings .logout-link,
    .settings .marketing-updates,
    .settings .username-email,
    .settings .saving,
    .settings .wallets {
        padding: 0 1rem;
    }
}
