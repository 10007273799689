html {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  height: 100%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

img {
  max-width: 100%;
}

main {
  background-color: #ffffff;
  flex: 1;
  color: #ffffff;
  text-align: center;
}

a {
  color: #ffffff;
  text-decoration: none;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: 0.85rem;
}

a.active,
a:hover {
  color: #b18f4f;
}

a.secondary {
  color: #b18f4f;
}

a.secondary.active,
a.secondary:hover {
  color: #b18f4f;
}

a.tertiary {
  color: #b18f4f;
  text-transform: none;
  letter-spacing: normal;
  font-size: 1rem;
}

.center {
  margin: 0 auto;
  max-width: 90rem;
}

button,
a.button {
  white-space: nowrap;
  display: inline-block;
  outline: none;
  letter-spacing: 0.1rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  border: 0.05rem solid #b18f4f;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.8rem;
}

button.primary,
a.button.primary {
  background-color: #b18f4f;
  padding: 0.7rem 1.6rem;
}

button.primary.big,
a.button.primary.big {
  background-color: #b18f4f;
  padding: 1.4rem 4.1rem;
  font-size: 1.1rem;
}

button.secondary,
a.button.secondary {
  background-color: transparent;
  padding: 0.7rem 1.6rem;
}

button.secondary.big,
a.button.secondary.big {
  background-color: transparent;
  padding: 1.4rem 4.1rem;
  font-size: 1.1rem;
}

button.text-black,
a.button.text-black {
  color: #000000;
}

button.facebook-btn,
a.button.facebook-btn {
  background-color: #3b5998;
  border: 0.05rem solid #3b5998;
}

button.link {
  color: #b18f4f;
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.padding-vertical-small {
  padding: 2rem 0;
}

.padding-vertical-large {
  padding: 11rem 0;
}

video {
  width: 100%;
}

.error-message {
  color: #b71c1c;
}

.radio---input-radio---2yM_b:checked
  + .radio---control-indicator---dcwXW::before {
  border: 1px solid #b18f4f;
  background-color: #b18f4f;
}

.radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover
  > .radio---control-indicator---dcwXW::before {
  border: 1px solid #b18f4f;
}
.radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover
  > .radio---input-radio---2yM_b:checked
  + .radio---control-indicator---dcwXW::before {
  border: 1px solid #b18f4f;
  background-color: #b18f4f;
}

.standalone-loading {
  color: #000000;
  font-size: 3rem;
  margin: 5rem;
}

.standalone-loading-white {
  color: #ffffff;
  font-size: 3rem;
  margin: 5rem;
}

.Checkbox {
  outline: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
textarea {
  outline: none;
  max-width: 35rem;
  width: 100%;
  padding: 1.3rem 0.5rem;
  border-radius: 0.4rem;
  border: 1px solid #dddddd;
  font-size: 1rem;
  margin: 0.4rem 0;
  font-family: "Roboto", sans-serif;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="url"]:focus,
textarea:focus {
  border: 1px solid #b18f4f;
}

hr.gray {
  margin: 1rem 0;
  border: none;
  height: 1px;
  background-color: #dddddd;
}

hr.double {
  height: 2px;
}

ol.ol-alpha {
  counter-reset: list;
}
ol.ol-alpha > li {
  list-style: none;
}
ol.ol-alpha > li:before {
  content: counter(list, lower-alpha) ") ";
  counter-increment: list;
}

.shadow {
  box-shadow: 0 55px 40px -50px #a9a9a9;
}

.text-left {
  text-align: left;
}

@media screen and (max-width: 786px) {
  .padding-vertical-large {
    padding: 8rem 0;
  }

  .hide-mobile {
    display: none;
  }
}
