.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -1.87rem; /* gutter size offset */
    width: auto;
    max-width: 85rem;
}

.my-masonry-grid_column {
    padding-left: 2.5rem; /* gutter size */
    background-clip: padding-box;
}

.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: transparent;
    margin-bottom: 1.87rem;
    display: inline-block;
}

.design-challenge-card-images {
    font-size: 0;
    position: relative;
}

.modal-design-challenge-vote {
    display: flex;
    flex-direction: row-reverse;
}

.modal-design-challenge-vote img {
    display: block;;
}

.modal-design-challenge-vote .user-info {
    flex: 1;
    padding: 0 1rem;
}

.modal-design-challenge-vote .images {
    flex: 0 0 75%;
    min-width: 34rem
}

.modal-design-challenge-vote .user-info h1 {
    font-size: 1.6rem;
    font-weight: initial;
    margin-bottom: .5rem;
}

.modal-design-challenge-vote .user-info a,
.vote-h3 a {
    color: #000000;
    text-transform: initial;
    letter-spacing: initial;
    outline: 0;
}

.vote-h3 a {
    font-size: 1rem;
}

.modal-design-challenge-vote .user-info > div {
    margin: .2rem 0;
}

.modal-design-challenge-vote .user-info > hr {
    margin: 1.5rem 0;
    border: none;
    height: 1px;
    background-color: #CDCDCD;
}

.modal-design-challenge-vote .user-info a:hover,
.vote-h3 a {
    color: #B18F4F;
}

.modal-design-challenge-vote .fas.fa-heart {
    color: #D0D0D0;
}

.design-challenge-card-images .ribbon {
    position: absolute;
    right: 0;
    width: 45%;
    top: 2rem;
}

@media screen and (max-width: 786px) {
    .modal-design-challenge-vote {
        flex-direction: column;
    }

    .modal-design-challenge-vote .user-info {
        padding: 2rem;
    }

    .modal-design-challenge-vote .images {
        min-width: 20rem;
        min-height: 10rem;
    }
}
