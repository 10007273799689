.market-menu {
    background-color: #FFFFFF;
    color: #000000;
    width: 100%;
}

.market-menu ul {
    display: flex;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    justify-content: center;
}

.market-menu ul li {
    padding: 1.5rem 1rem;
}

.market-menu ul li a {
    color: #000000;
}

.market-menu ul li a.active, .market-menu ul li a:hover {
    color: #B18F4F;
}
