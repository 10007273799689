.confirm-email {
    color: #000000;
}

.confirm-email .fa-check-circle {
    font-size: 6rem;
    margin: 4rem 0 2rem 0;
}

.confirm-email p {
    margin-top: 2rem;
}

.confirm-email a {
    margin-top: 2rem;
}
