.success-message {
    text-align: center;
    color: #000000;  
    margin-top: 5rem;  
}

.success-message .fa-check-circle {
    font-size: 6rem;
    margin-bottom: 2rem;
}

.success-message p {
    margin-top: 2rem;
}

.success-message a {
    margin: 2rem 0;
}
