.home .split.white.inverted img {
    box-shadow: 0 55px 40px -50px #a9a9a9;
}

.home .partners {
    padding: 4rem 0 10rem 0;
    margin: 0 auto;
    max-width: 70rem;
}

.home .partners h2 {
    color: #000000;
    font-weight: normal;
    font-size: 2.6rem;
    margin-bottom: 0;
}

.home .partners hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
    margin-bottom: 2rem;
}

.home .partners .logos {
    display: flex;
    flex-wrap: wrap;
}

.home .partners .logos > div {
    flex: 0 0 33%;
    margin: auto;
    padding: 1rem 0;
}

.home .partners .logos .gravity-sketch {
    text-align: left;
    margin-left: 1.5rem;
}

.home .partners .logos .neoworld {
    text-align: right;
    margin-right: 1.5rem;
}

/* Mobile */

.home .partners-mobile {
    max-width: 22rem;
    margin: 0 auto;
    display: none;
    padding: 2rem 0;
}

.home .partners-mobile h2 {
    color: #000000;
    font-weight: normal;
    font-size: 2.6rem;
    margin-bottom: 0;
}

.home .partners-mobile hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
    margin-bottom: 2rem;
}

.home .partners-mobile .logos {
    display: flex;
    flex-wrap: wrap;
}

.home .partners-mobile .logos > div {
    flex: 0 0 50%;
    margin: auto;
    padding: 1rem 0;
}

@media screen and (max-width: 1200px) {
    .home .partners {
        display: none;
    }

    .home .partners-mobile {
        display: block;
    }
}

@media screen and (max-width: 786px) {
    .home div.car-section {
        text-align: left;
        padding-bottom: 3rem;
    }
}
