.design-challenge-register-success {
  margin: 5rem auto;
  max-width: 35rem;
  padding: 0 2rem;
}

.design-challenge-register-success h1 {
  font-weight: initial;
}
.design-challenge-register-success .success-message p.text-left {
  line-height: 1.7rem;
}
.design-challenge-register-success .success-message p.first-p {
  margin: 0 auto;
  max-width: 21rem;
}


.design-challenge-register-success a.button.primary {
  padding: 1rem 1.6rem
}
