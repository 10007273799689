nav.mobile-menu {
    display: none;
}

nav.mobile-menu .header {
    display: flex;
}

nav.mobile-menu .header > div {
    flex: 1;
    padding: 1rem;
    margin: auto;
}

nav.mobile-menu .header > div.bars {
    text-align: right;
    font-size: 2.5rem;
    margin: auto 0;
    padding-right: 1.5rem;
}

nav.mobile-menu .header > div.bars .fa-bars {
    cursor: pointer;
}

nav.mobile-menu .logo img {
    width: 3.3rem;
}

nav.mobile-menu .bars {
    color: #FFFFFF;
}

nav.mobile-menu .menu-dropdown {
    background-color: white;
}

nav.mobile-menu .menu-dropdown.open {    
    max-height: auto;
    transition: max-height 0.45s ease-in;
}

nav.mobile-menu .menu-dropdown.closed {
    max-height: 0;
    transition: max-height 0.45s ease-out;
}

nav.mobile-menu ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    border-bottom: 1px solid #FFFFFF;
}

nav.mobile-menu ul li {
    background-color: #1A1A1C;
    list-style-position:inside;
    border-top: 1px solid #FFFFFF;
    padding: .8rem;
}

@media screen and (max-width: 1080px) {
    nav.mobile-menu {
        display: initial;
    }   
}
