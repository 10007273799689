main div.split div.description-block {
    max-width: 33rem;
    margin: 0 auto;
}

main div.split p {
    margin: 0;
}

main div.split p.title {
    padding-bottom: .5rem;
    font-size: 1.6rem;
}

main div.split p.description {
    font-weight: 300;
    line-height: 1.5rem;
    padding-bottom: 1.2rem;
}

main div.split hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
}

main div.split {
    background-color: #1C1C1C;
}

main div.split > div {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
}

main div.split div.description-block {
    margin: 0;
    max-width: 27rem;
}

main div.split div.image-container {
    flex: 0 0 40%;
    text-align: center;
}

main div.split.left div.description-block {
    max-width: 22rem;
}

main div.split hr {
    margin: .5rem 0;
}

main div.split.white {
    color: #000000;
    background-color: #FFFFFF;
}

main div.split.light-gray {
    color: #000000;
    background-color: #F0EFEF;
}

main div.split.light-gray img {
    margin-top: 4rem;
}

main div.split.dark-gray {
    background-color: #161719;
}

main div.split.light-gray .title {
    font-size: 1rem;
    font-weight: 100;
}

main div.split.light-gray .description {
    font-size: 1rem;
    font-weight: 400;
}

main div.split.light-gray a.button.secondary {
    border: none;
    padding: 0;
    color: #B18F4F;
}

main div.split.light-gray a.button.secondary:hover {
    color: #FFFFFF;
}

main div .description {
    color: #CCCCCC;
}

main div.split:not(.inverted):not(.light-gray) .description {
    margin-right: 5rem;
}

main div.split.inverted > div {
    flex-direction: row-reverse;
}

main div.split.inverted .image-container {
    margin-right: 8rem
}

main div.split.inverted div.description-block {
    max-width: 22rem;
}

main div.split.white p.description,
main div.split.light-gray p.description {
    color: #000000;
}

main .market div .description {
    color: #000000;
}

@media screen and (max-width: 786px) {
    main div.split {
        padding: 3rem 0;
    }

    main div.split > div {
        flex-direction: column-reverse;
        text-align: left;
    }

    main div.split p.title {
        text-align: left;
        font-weight: bold;
        padding-bottom: 0;
    }

    main div.split:not(.inverted):not(.light-gray) .description {
        margin-right: initial;
    }

    main div.split div.image-container {
        margin: 3rem 0 5rem 0;
        padding: 0 1.5rem;
    }

    main div.split.light-gray div.image-container {
        margin: 1rem 0 0 0;
        padding: 0 1.5rem;
    }

    main div.split.white div.image-container {
        padding: 0;
    }

    main div.light-gray div.description-block {
        margin-top: initial;
    }

    main div.split.inverted > div {
        flex-direction: column-reverse;
    }

    main div.split.inverted .image-container {
        margin-right: initial;
        margin-top: 1rem;
        margin-bottom: 1.8rem;
    }

    main div.split div.description-block,
    main div.split.inverted div.description-block {
        max-width: initial;
        padding: 0 3rem;
    }

    main p.description {
        text-align: left;
        padding:  1.2rem 0;
    }

    main div.split.light-gray img {
        margin-top: initial;
    }

    main div.split.white img {
        padding: 0 2rem;
    }
}
