.log-in {
    color: #333333;
    background-color: #FFFFFF;
    text-align: left;
    padding: 4.3rem 0;
}

.log-in .center {
    max-width: 35rem;
    padding: 0 1rem;
}

.log-in h1 {
    color: #000000;
    font-size: 1.62rem;
    font-weight: normal;
}

.log-in hr {
    margin: 1rem 0;
    border: none;
    height: 1px;
    background-color: #DDDDDD;
}

.log-in .log-in-div {
    text-align: right;
    padding-top: 1.93rem;
}

.log-in .log-in-div button {
    width: 7rem;
    margin-left: .5rem;
}

.log-in .create-an-account {
    padding: 1rem 0;
}

@media screen and (max-width: 786px) {
    .log-in {
        padding: 2rem 0;
    }
}
