@media (min-width: 799px) {
    .notify{
        margin-top: 35px;
        width: 100%;
        height: 70px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #B18F4F;
        font-family: 'Roboto', sans-serif;
    }
    
    div.notify span{
        width: 700px;
        font-size: 1rem;
        font-weight: bold;
        color: black;
        text-align: start;
    }

    .notify-text{
        margin-left: 5px;
    }
    
    .close-notify{
        font-size: 1.4rem;
        color: black;
        cursor: pointer;
    }

}

@media (min-width: 580px) and (max-width: 799px) {
    .notify{
        margin-top: 35px;
        width: 100%;
        height: 70px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #B18F4F;
        font-family: 'Roboto', sans-serif;
    }
    
    div.notify span{
        width: 480px;
        font-size: 0.7rem;
        font-weight: bold;
        color: black;
        text-align: start;
    }

    .notify-text{
        margin-left: 5px;
    }
    
    .close-notify{
        font-size: 1.4rem;
        color: black;
        cursor: pointer;
    }

}

@media (min-width: 393px) and (max-width: 579px) {
    .notify{
        margin-top: 35px;
        width: 100%;
        height: 70px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #B18F4F;
        z-index: 99;
        font-family: 'Roboto', sans-serif;
    }
    
    div.notify span{
        width: 300px;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 0.6rem;
        font-weight: bold;
        color: black;
    }

    .notify-text{
        width: 100%;
        text-align: center;
    }
    
    .close-notify{
        font-size: 1.4rem;
        color: black;
        cursor: pointer;
    }

}

@media screen and (max-width: 392px) {
    .notify{
        margin-top: 35px;
        width: 100%;
        height: 70px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #B18F4F;
        z-index: 99;
        font-family: 'Roboto', sans-serif;
    }
    
    div.notify span{
        width: 200px;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 0.6rem;
        font-weight: bold;
        color: black;
    }

    .notify-text{
        width: 100%;
        text-align: center;
    }
    
    .close-notify{
        font-size: 1.4rem;
        color: black;
        cursor: pointer;
    }
}