div.car-section {
    background-color: #1C1C1C;
    padding-bottom: 10rem;
}

div.car-section div.description-block {
    max-width: 33rem;
    margin: 0 auto;
}

div.car-section p {
    margin: 0;
}

div.car-section p.title {
    font-size: 2.25rem;
    padding-bottom: .5rem;
}

div.car-section p.description {
    font-weight: 300;
    line-height: 1.5rem;
    padding-bottom: 1.2rem;
}

div.car-section hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
}

div.car-section img {
    width: 58rem;
    margin: 11rem 0 3rem 0;
}

@media screen and (max-width: 786px) {
    div.car-section {
        text-align: left;
        padding-bottom: 0;
    }

    div.car-section img {
        margin: 3rem 0;
        padding: 0 1.5rem;
    }

    div.car-section div.description-block {
        padding: 0 3rem;
        max-width: initial;
    }

    div.car-section p.title {
        font-size: 1.6rem;
        font-weight: bold;
        padding-bottom: 0;
    }

    div.car-section p.description {
        padding: 1.2rem 0;
        color: #CCCCCC;
    }

    div.car-section hr {
        display: inline-block;
    }
}
