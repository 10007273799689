.terms-of-use {
    color: #000000;
    max-width: 35rem;
    text-align: left;
    padding: 3rem 0;
}

.terms-of-use h1 {
    font-size: 2.25rem;
}

.terms-of-use a {
    letter-spacing: initial;
    text-transform: initial;
    font-size: initial;
}

.terms-of-use ol {
    padding-left: 1rem;
}

.terms-of-use li {
    padding: .7rem 0;
}

@media screen and (max-width: 786px) {
    .terms-of-use {
        padding: 1rem;
    }
}
