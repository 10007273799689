/* MENU */
header {
    background-color: #1A1A1C;
}

footer {
    background-color: #000000;
    padding-top: 3.5rem;
    padding-bottom: 10rem;
    color: #FFFFFF;
}
