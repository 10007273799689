#videoDiv {
  width: 100%;
  position: relative;
  max-height: 40rem;
  overflow: hidden;
}

#videoBlock {
  background-color: #1a1a1c;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#videoBlock div {
  width: 100%;
}

#videoMessage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#videoMessage h1 {
  padding-bottom: 0;
}

#videoMessage h3 {
  padding-bottom: 5rem;
}

.video video {
  background-color: #0a0908;
  opacity: 0.5;
}

.video a {
  margin-bottom: 12rem;
}

.video h1 {
  padding-top: 3%;
  padding-bottom: 2.5rem;
  font-size: 7.5rem;
  font-weight: 700;
  margin: 0;
}

.video i {
  padding-bottom: 2rem;
}

.join-the-drop-button {
  height: 10rem;
}

@media screen and (max-width: 1920px) {
  #videoDiv {
    width: 100%;
    position: relative;
    max-height: 40rem;
    overflow: hidden;
  }

  #videoBlock {
    background-color: #1a1a1c;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #videoBlock div {
    width: 100%;
  }

  #videoMessage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  #videoTitle {
    font-size: 100px;
  }

  .video video {
    background-color: #0a0908;
    opacity: 0.5;
  }

  .video a {
    margin-bottom: 12rem;
  }

  .video h1 {
    padding-top: 10%;
    padding-bottom: 2.5rem;
    font-size: 7.5rem;
    font-weight: 700;
    margin: 0;
  }

  .video i {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 1440px) {
  .video a {
    margin-bottom: 10rem;
  }

  .video h1 {
    padding-top: 14%;
    padding-bottom: 5rem;
    font-size: 6rem;
    font-weight: 700;
    margin: 0;
  }
}

@media screen and (max-width: 1200px) {
  .video a {
    margin-bottom: 9rem;
  }

  .video h1 {
    padding-top: 14%;
    padding-bottom: 5rem;
    font-size: 5rem;
    font-weight: 700;
    margin: 0;
  }
}

@media screen and (max-width: 1004px) {
  .video a {
    margin-bottom: 9rem;
  }

  h1 > .event-aby {
    word-spacing: 0;
  }

  .video h1 {
    padding-top: 10%;
    padding-bottom: 5rem;
    font-size: 5rem;
    font-weight: 700;
    margin: 0;
  }
}

@media screen and (max-width: 900px) {
  #videoMessage .desktop {
    display: none;
  }

  #videoMessage .mobile h1 {
    font-size: 2rem;
    letter-spacing: 0.5rem;
  }

  #videoMessage .mobile h2 {
    margin-top: 2rem;
    font-size: 2rem;
    letter-spacing: 0.4rem;
  }

  #videoMessage .mobile h3 {
    margin-top: 3rem;
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
  }
}

@media screen and (max-width: 786px) {
  .headline h1 {
    font-size: 3.5rem;
  }

  #videoDiv {
    width: 100%;
    position: relative;
    height: auto;
    overflow: hidden;
  }

  .video h1 {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
  }

  .video a {
    margin-bottom: 8rem;
  }

  .video a.button.primary.big {
    padding: 1rem 2.3rem;
    font-weight: normal;
  }

  h1 > .separator {
    display: none;
  }

  h1 > .event-date {
    word-spacing: 0;
  }

  .video {
    background-color: #000000;
  }
}

@media screen and (max-width: 662px) {
  .video h1 {
    padding-top: 7.2rem;
    font-size: 4rem;
    text-transform: capitalize;
    padding-bottom: 5rem;
  }

  .video h1 > span:nth-child(2) {
    display: none;
  }

  #videoDiv {
    height: 47rem;
  }

  #videoBlock {
    background-color: #000000;
    background-image: url("/images/drop-events/drop_event_img5.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video video {
    display: none;
  }

  #videoMessage .mobile h3 {
    margin-bottom: 6rem;
  }
}

@media screen and (max-width: 450px) {
  .video h1 {
    padding-top: 4rem;
    font-size: 3.68rem;
    padding-bottom: 5rem; /*padding-bottom: 12.5rem;*/
  }

  #videoDiv {
    height: 37rem;
  }

  .video a {
    margin-bottom: 8rem;
  }
}
