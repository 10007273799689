.car-card div.description-block {
    max-width: 33rem;
    margin: 0 auto;
    color: #000000;
}

.car-card p {
    margin: 0;
}

.car-card p.title {
    font-size: 2.25rem;
    font-weight: bold;
    padding-bottom: .5rem;
}

.car-card p.description {
    font-weight: 300;
    line-height: 1.5rem;
    padding-bottom: 1.2rem;
}

.car-card hr {
    border: none;
    height: 1px;
    width: 2.5rem;
    background-color: #B18F4F;
}

.car-card {
    background-color: #1C1C1C;
}

.car-card > div {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
}

.car-card div.description-block {
    margin: 0;
    flex: 0 0 50%;
    padding: 0 5.9rem;
}

.car-card div.image-container {
    flex: 0 0 50%;
    text-align: center;
}

.car-card hr {
    margin: .5rem 0;
}

.car-card.gray {
    background-color: #F0EFEF;
}

.car-card.white {
    background-color: #FFFFFF;
}

.car-card.inverted > div {
    flex-direction: row-reverse;
}

.car-card a.button.secondary {
    padding: .7rem 2.57rem;
    margin-left: 1.25rem;
}

@media screen and (max-width: 786px) {
    .car-card.inverted > div,
    .car-card > div {
        flex-direction: column-reverse;
    }

    .car-card div.description-block {
        padding: 0 1rem;
    }

    .car-card p.description {
        padding: 1rem 0;
    }

    .car-card.white,
    .car-card.gray {
        padding: 3rem 0 5rem 0
    }
}
