.market-car {
  background-color: #ffffff;
}

.market-car-menu {
  background-color: #f0efef;
}

.market-car-menu a {
  color: #202124;
}

.market-car-menu a.active,
.market-car-menu a:hover {
  color: #b18f4f;
}

.market-car-menu ul {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  justify-content: center;
}

.market-car-menu ul li {
  padding: 3.5rem 4rem 1rem 4rem;
}

.market-car-menu hr {
  margin: 0;
  border: none;
  height: 1px;
  background-color: #ffffff;
}

.market-car-main-image {
  background: linear-gradient(
    to bottom,
    #f0efef 0%,
    #f0efef 75%,
    #f0efef 75%,
    white 75%,
    white 100%
  );
}

.market-car-main-image img {
  max-width: 63rem;
}

.market-car-header {
  color: #000000;
  background: #ffffff;
  padding-bottom: 7rem;
}

.market-car-header h1 {
  font-size: 11rem;
  padding: 0;
  margin: 0;
}

.sportcoupe .market-car-header h1 {
  font-size: 12rem;
}

.market-car-header h2 {
  font-size: 2.25rem;
  padding: 0;
  margin: 0;
  font-weight: 400;
}

.market-car-header hr {
  border: none;
  height: 1px;
  width: 2.5rem;
  background-color: #999999;
  margin: 1rem auto 1.7rem auto;
}

main .market-car div.split.white {
  padding: 1.9rem 0;
}

.statistics {
  color: #000000;
  background-color: #ffffff;
  padding-bottom: 11rem;
}

.statistics h3 {
  margin: 0;
  padding: 0;
  font-size: 4.5rem;
  font-weight: 400;
}

.statistics > div > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  text-align: left;
  -webkit-justify-content: center;
  justify-content: center;
}

.statistics > div p.title {
  padding-bottom: 0;
}

.statistics > div p.title .nominator {
  font-size: 9rem;
}

.statistics > div p.title sup {
  font-size: 6rem;
  vertical-align: 2rem;
}

.statistics > div p.title span.stat {
  position: relative;
  right: 5.6rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.statistics > div div.image-container {
  -webkit-flex: 0 0 40%;
  flex: 0 0 40%;
  text-align: center;
}

.statistics > div .margin-right {
  margin-right: 8rem;
}

.statistics > div hr {
  margin: 0.5rem 0;
  border: none;
  height: 1px;
  width: 2.5rem;
  background-color: #b18f4f;
}

.statistics > div p.description {
  font-weight: 300;
  line-height: 1.5rem;
  padding-bottom: 1.2rem;
  margin: 0;
  color: #000000;
}

.statistics .description-block {
  max-width: 22rem;
  margin-left: 5rem;
}

.market-car > .gallery {
  background-color: #363636;
}

.carousel .slide img {
  width: auto;
  max-width: 100%;
}

.market-car .stats {
  display: flex;
  text-align: center;
}

.market-car .stats .stats-image {
  color: #dddddd;
}

.market-car .stats hr {
  background-color: #acacac;
  border: none;
  height: 2px;
}

.market-car .stats .stats-image hr {
  background-color: #dddddd;
}

.market-car .stats .stats-chart hr {
  margin: 0.8rem auto 3rem auto;
}

.market-car .stats .title {
  text-transform: uppercase;
  font-size: 0.6rem;
  letter-spacing: 0.12rem;
  font-weight: 500;
}

.market-car .stats > div {
  padding: 0 1rem;
}

.market-car .stats hr {
  margin: 0.8rem auto;
  width: 15rem;
}

.market-car .stats .stats-chart .title {
  color: #acacac;
}

.market-car .stats img {
  margin-top: 1.5rem;
}

.market-car .stats .stats-chart .stat-component {
  margin-bottom: 2rem;
}

@media screen and (max-width: 980px) {
  .market-car .statistics .description-block {
    display: none;
  }

  .market-car-main-image img {
    max-width: 100%;
  }
}

@media screen and (max-width: 786px) {
  .market-car-menu ul {
    background-color: white;
    justify-content: space-evenly;
    align-items: center;
  }

  .market-car-menu ul li {
    padding: 0.75rem 1rem;
  }

  .market-car-menu ul li a {
    font-size: 10px;
  }

  .market-car-main-image {
    background: linear-gradient(
      to bottom,
      #f0efef 0%,
      #f0efef 72%,
      #f0efef 72%,
      white 72%,
      white 100%
    );
  }

  .market-car-header {
    padding-bottom: 3rem;
  }
  .sportcoupe .market-car-header h1,
  .market-car-header h1 {
    font-size: 3.2rem;
  }

  .market-car-header h2 {
    font-size: 1.3rem;
  }

  .market-car-header hr {
    width: 2rem;
    margin: 0.5rem auto 1.7rem auto;
  }

  .market-car-header a.button.primary.big {
    font-weight: normal;
    padding: 0.9rem 1rem;
    letter-spacing: 0.17rem;
    font-size: 15px;
  }

  .market-car > .gallery {
    margin-top: 4rem;
  }

  /* SPLIT */
  main .market-car div.split.white {
    padding: 0;
  }

  main .market-car div.split div.image-container {
    margin: 3rem 0 2rem 0;
  }

  main .market-car div.split p.title {
    font-size: 1.4rem;
  }

  main .market-car div.split div.description-block,
  main div.split.inverted div.description-block {
    padding: 0 2.2rem;
  }

  main .market-car div.split hr {
    width: 1.7rem;
  }

  main .market-car .statistics h3 {
    margin-top: 2rem;
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 568px) {
  .market-car .stats {
    flex-direction: column;
  }

  .market-car .stats {
    flex: 1 0 100%;
  }

  .market-car .stats hr {
    width: 70%;
  }

  .market-car .stats > div.stats-chart {
    margin-top: 2rem;
  }

  .market-car .stats .stats-chart .stat-component {
    padding: 0 2rem;
  }

  .market-car .statistics {
    padding-bottom: 3rem;
  }
}
