.headline {
    background-color: #242427;
    background-image: url('/images/headline/background.png');
    background-repeat:   no-repeat;
    background-position: center center;
    background-size: auto 80%;
}

.headline a {
    margin-bottom: 12rem;
}

.headline h1 {
    padding-top: 9.2rem;
    padding-bottom: 2.5rem;
    font-size: 6rem;
    font-weight: 700;
    margin: 0;
}

.headline i {
    padding-bottom: 2rem;
}

@media screen and (max-width: 1920px) {
    .headline {
        background-image: url('/images/headline/background-1920.png');
    }
}

@media screen and (max-width: 1180px) {
    .headline {
        background-size: 80% auto;
    }
}

@media screen and (max-width: 988px) {
    .headline h1 {
        font-size: 5rem;
    }
}

@media screen and (max-width: 826px) {
    .headline h1 {
        font-size: 4.5rem;
    }
}

@media screen and (max-width: 786px) {
    .headline {
        background-image: url('/images/headline/background-786.png');
        padding: 5rem 0;
    }

    .headline h1 {
        padding-top: 4rem;
    }

    .headline a {
        margin-bottom: 4rem;
    }
}

@media screen and (max-width: 583px) {
    .headline {
        padding: initial;
    }
}
